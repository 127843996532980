import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Prime Imports
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';

// NgRx
import { StoreModule } from '@ngrx/store';

// Ngx
import { WebcamModule } from 'ngx-webcam';

// EZ-packages
import { EzSharedDirectivesModule, EzSharedPipesModule, EzComponentsModule } from '@ez-packages';
import { EzLocalizationModule } from '@ezuiaws/ez-packages/ez-localization';
import { EzLayoutModule } from '@ezuiaws/ez-packages/ez-layout';
import { EzButtonsModule } from '@ezuiaws/ez-packages/ez-buttons';

// Module Imports
import { MembershipPlanSelectComponent } from './membership-plan-select/membership-plan-select.component';
import { MembershipTypeMultiSelectComponent } from './membership-type-multi-select/membership-type-multi-select.component';
import { MembershipModeSelectComponent } from './membership-mode-select/membership-mode-select.component';
import { MembershipStatusSelectComponent } from './membership-status-select/membership-status-select.component';
import { MembershipActionSelectComponent } from './membership-action-select/membership-action-select.component';
import { LocationSelectComponent } from './location-select/location-select.component';
import { ResourceSelectComponent } from './resource-select/resource-select.component';
import { SMSProviderSelectComponent } from './sms-provider-select/sms-provider-select.component';
import { CheckInStationSelectComponent } from './checkin-station-select/checkin-station-select.component';
import { OpenInvoiceSelectComponent } from './open-invoice-select/open-invoice-select.component';
import { ReservationTypeSelectComponent } from './reservation-type-select/reservation-type-select.component';
import { InvoiceToIncludeSelectComponent } from './invoice-to-include-select/invoice-to-include-select.component';
import { IncludeClientFilterComponent } from './include-client-filter/include-client-filter.component';
import { LocationJobTypesSelectComponent } from './location-job-types-select/location-job-types-select.component';
import { CustomFieldSelectComponent } from './custom-field-select/custom-field-select.component';
import { ActivityTypeSelectComponent } from './activity-type-select/activity-type-select.component';
import { ActivityStatusSelectComponent } from './activity-status-select/activity-status-select.component';
import { CashRegisterSelectComponent } from './cash-register-select/cash-register-select.component';
import { ClientAttendanceStatusSelectComponent } from './client-attendance-status-select/client-attendance-status-select.component';
import { ClientTShirtSizeSelectComponent } from './client-t-shirt-size-select/client-t-shirt-size-select.component';
import { PaymentDueOnSelectComponent } from './payment-due-on-select/payment-due-on-select.component';
import { openInvoiceSelectReducer } from './open-invoice-select/state/open-invoice-select.reducer';
import { AttendanceStatusStateModule } from '@app-shared/state/attendance-status-state/attendance-status-state.module';
import { EzDirectivesModule } from '@ezuiaws/ez-packages/ez-directives';
import { ActivityPrioritySelectComponent } from './activity-priority-select/activity-priority-select.component';

@NgModule({
  declarations: [
    MembershipPlanSelectComponent,
    MembershipTypeMultiSelectComponent,
    MembershipModeSelectComponent,
    MembershipModeSelectComponent,
    MembershipStatusSelectComponent,
    MembershipActionSelectComponent,
    ClientAttendanceStatusSelectComponent,
    ClientTShirtSizeSelectComponent,
    LocationSelectComponent,
    ResourceSelectComponent,
    SMSProviderSelectComponent,
    CheckInStationSelectComponent,
    OpenInvoiceSelectComponent,
    ReservationTypeSelectComponent,
    InvoiceToIncludeSelectComponent,
    IncludeClientFilterComponent,
    LocationJobTypesSelectComponent,
    CustomFieldSelectComponent,
    ActivityTypeSelectComponent,
    ActivityStatusSelectComponent,
    CashRegisterSelectComponent,
    PaymentDueOnSelectComponent,
    ActivityPrioritySelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    OverlayPanelModule,
    DropdownModule,
    StoreModule.forFeature('openInvoiceSelect', openInvoiceSelectReducer),
    ReactiveFormsModule,
    EzComponentsModule,
    EzButtonsModule,
    WebcamModule,
    EzSharedDirectivesModule,
    EzDirectivesModule,
    EzSharedPipesModule,
    MultiSelectModule,
    TableModule,
    AttendanceStatusStateModule,
    EzLocalizationModule,
    EzLayoutModule
  ],
  exports: [
    MembershipTypeMultiSelectComponent,
    MembershipModeSelectComponent,
    MembershipModeSelectComponent,
    MembershipStatusSelectComponent,
    MembershipActionSelectComponent,
    MembershipPlanSelectComponent,
    ClientAttendanceStatusSelectComponent,
    LocationSelectComponent,
    ReservationTypeSelectComponent,
    ResourceSelectComponent,
    SMSProviderSelectComponent,
    CheckInStationSelectComponent,
    OpenInvoiceSelectComponent,
    InvoiceToIncludeSelectComponent,
    IncludeClientFilterComponent,
    LocationJobTypesSelectComponent,
    CustomFieldSelectComponent,
    ActivityTypeSelectComponent,
    ActivityStatusSelectComponent,
    CashRegisterSelectComponent,
    ClientTShirtSizeSelectComponent,
    PaymentDueOnSelectComponent,
    ActivityPrioritySelectComponent
  ]
})
export class SelectorsModule { }
