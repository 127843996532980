import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EzFormControl } from '../../ez-form-controls';
import { LoginRequest } from '../../../autogen/ez.common/contracts/request/login-request';

import { ResourcesProvider } from '@ezuiaws/ez-packages/ez-localization';

@Component({
  selector: 'ez-user-login',
  templateUrl: './ez-user-login.component.html',
  styleUrls: ['./ez-user-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EzUserLoginComponent implements OnInit {

  @Input() showLabel: boolean = true;

  @Input() usernameLabel: string = ResourcesProvider.rks.Username;
  @Input() passwordLabel: string = ResourcesProvider.rks.Password;
  @Input() loginButtonText: string = ResourcesProvider.rks.Login;

  @Output() userLoginEvent: EventEmitter<LoginRequest> = new EventEmitter();

  loginForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.createLoginForm();
  }

  private createLoginForm() {
    this.loginForm = this.fb.group({
      username: new EzFormControl(null, [Validators.required], null, 'username', this.usernameLabel),
      password: new EzFormControl(null, [Validators.required, Validators.maxLength(50)], null, 'password', this.passwordLabel),
    });
  }

  loginFormEvent() {
    this.loginForm.markAllAsTouched();

    if (this.loginForm.valid) {
      const loginModel: LoginRequest = {
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value,
        ipAddress: null,
        accessCode: '',
        userPoolId: 1
      };
      this.userLoginEvent.emit(loginModel);
    }
  }
}
