import { ClientActionResponse } from '@app-autogen-socketmodels/client-action-response';
import { MessagePacket } from '@app-core/socket/message-packet';
import * as moment from 'moment';

export class MessagePacketHelper {

  static messageList: MessagePacket[] = [];

  static handlePackets(kafkaMessage: ClientActionResponse): ClientActionResponse | null {

    if (kafkaMessage.packetCount === 1) {
      return kafkaMessage;
    }

    // If we have multiple packets lets process them.
    let returnMessage: ClientActionResponse = null;

    if (kafkaMessage.packetCount > 1) {

      // Add the message packet to a list to process later.
      MessagePacketHelper.addMessage(kafkaMessage);

      // Check the message list and if it is complete then return the list of messages in the packet number order.
      const entireList: ClientActionResponse[] = MessagePacketHelper.getCompleteSortedList(kafkaMessage);

      // If the list is null then we don't have all the packets yet.
      if (entireList != null) {

        // We have the full list of packets so lets re-assemble them.
        returnMessage = MessagePacketHelper.reAssembleMessage(entireList);

        // Now that we rebuilt it to one message we can remove the packets from our list
        MessagePacketHelper.removeMessagePackets(kafkaMessage);

        // Automatic cleanup just in case packets get left behind.
        MessagePacketHelper.removeOldMessagePackets();
      }
    }

    return returnMessage;
  }

  static addMessage(message: ClientActionResponse): void {
    const messagePacket: MessagePacket = {
      createDateTime: new Date(),
      message: message
    };
    MessagePacketHelper.messageList.push(messagePacket);
  }

  static getCompleteSortedList(message: ClientActionResponse): ClientActionResponse[] {
    // Get all message part by for current messageId
    const localList: MessagePacket[] = MessagePacketHelper.messageList.filter(packet => packet.message.messageId === message.messageId);
    if (localList.length === message.packetCount) {
      return localList.map((item: MessagePacket) => item.message)
        .sort((a, b) => a.packetNum - b.packetNum);
    }

    return null;
  }

  static reAssembleMessage(newMessageList: ClientActionResponse[]): ClientActionResponse {
    // Join all parts of actionPayload in one
    const retPayload: string = newMessageList.map((item: ClientActionResponse) => item.payLoad.actionPayload).join('');

    const returnMessage: ClientActionResponse = newMessageList.shift();
    returnMessage.payLoad.actionPayload = retPayload;
    return returnMessage;
  }

  static removeMessagePackets(message: ClientActionResponse): void {
    MessagePacketHelper.messageList = MessagePacketHelper.messageList.filter(item => item.message.messageId !== message.messageId);
  }

  static removeOldMessagePackets(): void {
    MessagePacketHelper.messageList = MessagePacketHelper.messageList.filter(item => {
      return moment(item.createDateTime).add(10, 'minutes').toDate() > new Date();
    });
  }
}
