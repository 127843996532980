import { Injectable } from '@angular/core';

import * as EmailTemplateReducer from './email-template-state.reducer';
import * as EmailTemplateSelectors from './email-template-state.selectors';
import * as EmailTemplateActions from './email-template-state.actions';

import { Observable } from 'rxjs';

import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';
import {
  GetEmailTemplateSubCategoriesRequest
} from "@app-autogen/ez.email/contracts/request/get-email-template-sub-categories-request";
import { LabelValueViewModel } from "@app-autogen/ez.common/contracts/response/label-value-view-model";
import {
  GetEmailTemplateSubCategoryTemplatesRequest
} from "@app-autogen/ez.email/contracts/request/get-email-template-sub-category-templates-request";
import {
  GetLocationEmailTemplateRequest
} from "@app-autogen/ez.email/contracts/request/get-location-email-template-request";
import {
  GetEmailTemplateHtmlSourceRequest
} from "@app-autogen/ez.email/contracts/request/get-email-template-html-source-request";
import { LocationEmailTemplate } from "@app-autogen/ez.email/contracts/response/location-email-template";
import {
  CreateLocationEmailTemplateRequest
} from "@app-autogen/ez.email/contracts/request/create-location-email-template-request";
import {
  DeleteLocationEmailTemplateRequest
} from "@app-autogen/ez.email/contracts/request/delete-location-email-template-request";
import { SendFeedbackEmailRequest } from "@app-autogen/ez.email/contracts/request/send-feedback-email-request";
import {
  GetLocationEmailTemplatesRequest
} from "@app-autogen/ez.email/contracts/request/get-location-email-templates-request";
import { UpdateLocationEmailTemplateRequest } from '@app-autogen/ez.email/contracts/request/update-location-email-template-request';

@Injectable()
export class EmailTemplateStateFacade {

  templateCategories$: Observable<LabelValueViewModel[]> = this.storeHelper.stateStreamSelector(EmailTemplateSelectors.getEmailTemplateCategories);
  templateSubCategories$: Observable<LabelValueViewModel[]> = this.storeHelper.stateStreamSelector(EmailTemplateSelectors.getEmailTemplateSubCategories);
  templateSubCategoryTemplates$: Observable<LabelValueViewModel[]> = this.storeHelper.stateStreamSelector(EmailTemplateSelectors.getEmailTemplateSubCategoryTemplates);
  locationEmailTemplates$: Observable<LocationEmailTemplate[]> = this.storeHelper.stateStreamSelector(EmailTemplateSelectors.getLocationEmailTemplates);
  locationEmailTemplateData$: Observable<LocationEmailTemplate> = this.storeHelper.stateStreamSelector(EmailTemplateSelectors.getLocationEmailTemplateData);
  emailTemplateHtmlSource$: Observable<string> = this.storeHelper.stateStreamSelector(EmailTemplateSelectors.getEmailTemplateHtmlSource);
  createdEmailTemplateId$: Observable<number> = this.storeHelper.stateStreamSelector(EmailTemplateSelectors.createdEmailTemplateId);
  isEmailTemplateNameInputVisible$: Observable<boolean> = this.storeHelper.stateStreamSelector(EmailTemplateSelectors.isEmailTemplateNameInputVisible);

  constructor(
    private storeHelper: EzStoreService<EmailTemplateReducer.EmailTemplateState>
  ) {
  }

  getEmailTemplateCategoriesOnLoad() {
    this.storeHelper.dispatchAction(EmailTemplateActions.getEmailTemplateCategoriesOnLoad());
  }

  getEmailTemplateSubCategories(selectedTemplateCategory: string) {
    const request: GetEmailTemplateSubCategoriesRequest = {
      emailTemplateCategoryDirectory: selectedTemplateCategory
    };
    this.storeHelper.dispatchAction(EmailTemplateActions.getEmailTemplateSubCategories(request));
  }

  getEmailTemplateSubCategoryTemplates(selectedTemplateSubCategory: string) {
    const request: GetEmailTemplateSubCategoryTemplatesRequest = {
      emailTemplateSubCategoryDirectory: selectedTemplateSubCategory
    };
    this.storeHelper.dispatchAction(EmailTemplateActions.getEmailTemplateSubCategoryTemplates(request));
  }

  getLocationEmailTemplate(templateId: number) {
    const request: GetLocationEmailTemplateRequest = {
      emailTemplateID: templateId
    };
    this.storeHelper.dispatchAction(EmailTemplateActions.getLocationEmailTemplate(request));
  }

  getEmailTemplateHtmlSource(fileName: string) {
    const request: GetEmailTemplateHtmlSourceRequest = {
      fileName: fileName
    };
    this.storeHelper.dispatchAction(EmailTemplateActions.getEmailTemplateHtmlSource(request));
  }

  getLocationEmailTemplates(getHTMLBody: boolean) {
    const request: GetLocationEmailTemplatesRequest = {
      getHTMLBody: getHTMLBody
    };
    this.storeHelper.dispatchAction(EmailTemplateActions.getLocationEmailTemplates(request));
  }

  addLocationEmailTemplate(request: CreateLocationEmailTemplateRequest) {
    this.storeHelper.dispatchAction(EmailTemplateActions.addLocationEmailTemplate(request));
  }

  updateLocationEmailTemplate(request: UpdateLocationEmailTemplateRequest) {
    this.storeHelper.dispatchAction(EmailTemplateActions.updateLocationEmailTemplate(request));
  }

  clearCreatedEmailTemplateId() {
    this.storeHelper.dispatchAction(EmailTemplateActions.clearCreatedEmailTemplateId());
  }

  deleteLocationEmailTemplate(templateId: number) {
    const request: DeleteLocationEmailTemplateRequest = {
      emailTemplateID: templateId
    };
    this.storeHelper.dispatchAction(EmailTemplateActions.deleteLocationEmailTemplate(request));
  }

  clearState() {
    this.storeHelper.dispatchAction(EmailTemplateActions.clearState());
  }

  sendFeedbackEmail(request: SendFeedbackEmailRequest) {
    this.storeHelper.dispatchAction(EmailTemplateActions.sendFeedbackEmail(request));
  }

  showEmailTemplateNameInput() {
    this.storeHelper.dispatchAction(EmailTemplateActions.toggleEmailTemplateNameInput(true));
  }

  hideEmailTemplateNameInput() {
    this.storeHelper.dispatchAction(EmailTemplateActions.toggleEmailTemplateNameInput(false));
  }
}
