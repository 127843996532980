import { Calendar } from 'primeng/calendar';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormatWidth, getLocaleDateFormat, getLocaleTimeFormat } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import { EzLocaleHelper } from '@ezuiaws/ez-packages/ez-localization';

import { getCypressFieldName, getDateTimeInUserTimeZone, getDateTimeInISOFormat } from '../../helpers';

export enum EzCalendarFormat {
  shortDate = 'shortDate',
  monthAndYear = 'monthAndYear'
}

export enum EzCalendarView {
  date = 'date',
  month = 'month'
}

@Component({
  selector: 'ez-date-picker',
  templateUrl: './ez-date-picker.component.html',
  styleUrls: ['./ez-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzDatePickerComponent),
      multi: true
    }
  ]
})
export class EzDatePickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('calendar') calendar: Calendar;

  @Input() control: AbstractControl;
  @Input() placeholder: string = 'MM/DD/YYYY';

  @Input() readonlyInput: boolean = true;
  @Input() showInputTextbox: boolean = true;
  @Input() timeOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() maxDate: Date = new Date('01/01/2030');
  @Input() minDate: Date = new Date('01/01/1900');
  @Input() stepMinute: number = 1;
  @Input() stepHour: number = 1;
  @Input() stepSecond: number = 1;
  @Input() appendTo: string = 'body';
  @Input() allowFutureDates: boolean = true;
  @Input() hourFormat: string;

  @Input() showIcon: boolean = false;
  @Input() showIconOnly: boolean = false;

  @Input() showSeconds: boolean = false;
  @Input() showTime: boolean = false;
  @Input() showButtonBar: boolean = true;

  /**
   * Return JS Date object if set false
   */
  @Input() returnAsString: boolean = true;

  clearCssClass = 'p-button-text';
  @Input() set showClear(value: boolean) {
    if (value) {
      this.clearCssClass = 'p-button-text';
    } else {
      this.clearCssClass = 'd-none';
    }
  }

  @Input() set selectedDate(date: Date | string) {
    date = typeof date === 'string' ? new Date(date) : date;
    this.onValueSelect(date);
  }

  // if allowNulls set to false, the component will load the current location datetime
  @Input() allowNulls: boolean = true;

  @Input() viewFormat: EzCalendarView = EzCalendarView.date;

  @Input() dateFormat: EzCalendarFormat = EzCalendarFormat.shortDate;
  public get _dateFormat(): string {
    switch (this.dateFormat) {
      case EzCalendarFormat.shortDate:
        let dateFormat: string = getLocaleDateFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short);

        // all p-calendar formatting we want is lower case
        dateFormat = dateFormat.toLowerCase();

        // change 4 yyyy to 2 yy since p-calendar considers 2 yy as 4 digits
        dateFormat = dateFormat.replace('yyyy', 'yy');

        // change 4 yyyy to 2 yy since p-calendar considers 2 yy as 4 digits
        dateFormat = dateFormat.replace('yyyy', 'yy');

        return dateFormat;

      case EzCalendarFormat.monthAndYear:
        return 'MM, yy';
    }
  }

  @Input() todayButtonOnlyDateChange:boolean = false;

  @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter();

  EzCalendarFormat = EzCalendarFormat;

  _selectedDateSubject: BehaviorSubject<Date> = new BehaviorSubject(null);
  cypressName: string = '';

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    // const hourFormat: string = getLocaleTimeFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short);
    this.hourFormat = this.isHour12 ? '12' : '24';
  }

  ngAfterViewInit(): void {
    this.cypressName = getCypressFieldName(this.control);

    // The AfterViewInit interface is the only way we can set the value to todays localized date if passed in null.
    // There may be a better way to handle this.

    if (!this.allowFutureDates) {
      this.maxDate = new Date(getDateTimeInUserTimeZone());
    }

    if (!this.allowNulls && !this._selectedDateSubject.value) {
      this.setTodayDateTime();
    }
  }

  private isHour12(): boolean {
    const options = new Intl.DateTimeFormat(EzLocaleHelper.locale, {
      hour: 'numeric',
    }).resolvedOptions();

    return !!options.hour12;
  }

  showOverlay() {
    this.calendar.toggle();
  }

  onClose() {
    /**
     * onClose event works as valueChange event
     */
    const selectedValue: Date = this._selectedDateSubject.value;
    this.onValueChange(selectedValue);
  }

  onShow() {
    /**
     * Show correct locale datetime when calendar open if selected value was null
     */
    if (!this._selectedDateSubject.value) {
      const date: Date = new Date(getDateTimeInUserTimeZone());
      this.onValueSelect(date);
    }
  }

  onClearClick() {
    this.onValueChange(null);
  }

  setTodayDateTime() {
    const currentDateTime: Date = new Date(getDateTimeInUserTimeZone());

    if (this.todayButtonOnlyDateChange) {
      const selectedDateTime: Date = this._selectedDateSubject.getValue();

      const currentDateWithSelectedTime: Date = new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate(),
        selectedDateTime.getHours(),
        selectedDateTime.getMinutes()
      );
      this.onValueChange(currentDateWithSelectedTime);
    } else {
      this.onValueChange(currentDateTime);
    }
  }

  onValueInput() {
    /**
     * This event handles only UI manual input changes
     */
    this._selectedDateSubject.next(this.calendar.value);
  }

  onValueSelect(date: Date) {
    /**
     * This event handles only UI select event
     */
    this._selectedDateSubject.next(date);
  }

  onValueChange(date: Date) {
    /**
     * Update form value and emit output event
     */
    this.onTouched();
    this.selectedDateChange.emit(date);
    this.writeValue(date);
  }

  writeValue(value: string | number | Date): void {
    const date: Date = value ? new Date(value) : null;

    this.onValueSelect(date);

    !!(this.returnAsString && date) ? this.onChange(getDateTimeInISOFormat(date)) : this.onChange(date);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }
}
