import {
  animation, trigger, transition, animate, style, state
} from '@angular/animations';



// Docs: https://angular.io/guide/transition-and-triggers

// Generic implementation for height transition
export const transAnimation = animation([
  style({
    height: '{{ height }}',
    opacity: '{{ opacity }}',
    backgroundColor: '{{ backgroundColor }}'
  }),
  animate('{{ time }}')
]);


// Possible Implementation for child components

// export const slideInChild =
//     trigger('slideInChild', [
//         transition(':enter', [
//             animate('250ms ease-in', style({
//                 overflow: 'hidden',
//                 width: '*',
//             }))
//         ]),
//         transition(':leave', [
//             animate('250ms ease-in', style({
//                 overflow: 'hidden',
//                 width: '0px',
//             }))
//         ])
//     ]);

// export const slideIn =
//     trigger('slideIn', [
//         state('*', style({
//             overflow: 'hidden',
//             width: '*',
//         })),
//         state('out', style({
//             overflow: 'hidden',
//             width: '*',
//         })),
//         state('in', style({
//             overflow: 'hidden',
//             width: '0px',
//         })),
//         transition('* => in', [group([
//             query('@slideInChild', animateChild(), { optional: true }),
//             animate('600ms ease-out')
//         ])]),
//         transition('in => out', [group([
//             query('@slideInChild', animateChild(), { optional: true }),
//             animate('600ms ease-in')
//         ])])
//     ]);

export const overlayAnimation =
  trigger('overlayAnimation', [
    state('void', style({
      transform: 'translateY(5%)',
      opacity: 0
    })),
    state('visible', style({
      transform: 'translateY(0)',
      opacity: 1
    })),
    transition('void => visible', animate('{{showTransitionParams}}')),
    transition('visible => void', animate('{{hideTransitionParams}}'))
  ]);

// side modal - need to figure out how to apply to content
export const sideModalTransition = trigger('sideModalTransition', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('200ms ease-out', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('200ms ease-out', style({ opacity: 0 })),
  ])]);

// show more link
export const showMoreTransition = trigger('showMoreTransition', [
  state('active', style({ 'overflow-x': 'hidden' })),
  state('inactive', style({ 'overflow-x': 'hidden' })),
  transition('active => *', [
    style({ width: '*' }),
    animate(200, style({ width: '0' }))
  ]),
  transition('inactive => *', [
    style({ width: '0' }),
    animate(200, style({ width: '*' }))
  ]),

]);
