import { NgModule } from '@angular/core';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { CopyClipboardDirective } from './copy-clipboard.directive';
import { SwitchCasesDirective } from './switch-cases.directive';
import { InputWidthByPlaceholderDirective } from './input-width-by-placeholder.directive';
import { HighlightSearchDirective } from './highlight-search.directive';
import { RepositionFloatingElementDirective } from './reposition-floating-element.directive';

@NgModule({
  declarations: [
    CopyClipboardDirective,
    SwitchCasesDirective,
    AutocompleteOffDirective,
    InputWidthByPlaceholderDirective,
    HighlightSearchDirective,
    RepositionFloatingElementDirective
  ],
  imports: [],
  exports: [
    CopyClipboardDirective,
    SwitchCasesDirective,
    AutocompleteOffDirective,
    InputWidthByPlaceholderDirective,
    HighlightSearchDirective,
    RepositionFloatingElementDirective
  ]
})
export class EzSharedDirectivesModule { }
