import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from "@ngrx/effects";
import { emailTemplateReducer, EMAIL_TEMPLATES_FEATURE_KEY } from './email-template-state.reducer';
import { EmailTemplateStateFacade } from "@app-shared/state/email-templates-state/email-template-state.facade";
import { EmailTemplateStateEffects } from "@app-shared/state/email-templates-state/email-template-state.effects";

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(EMAIL_TEMPLATES_FEATURE_KEY, emailTemplateReducer),
    EffectsModule.forFeature([EmailTemplateStateEffects])
  ],
  providers: [EmailTemplateStateFacade]
})
export class EmailTemplateStateModule { }
