import { Injectable } from '@angular/core';
import { getDateTimeInUserTimeZone } from '@ez-packages';
import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';

import * as fromAttendanceStatus from './attendance-status.reducer';
import * as AttendanceStatusActions from './attendance-status.actions';
import * as AttendanceStatusSelectors from './attendance-status.selectors';

import { AttendanceStatuses } from '@app-autogen/ez.common/contracts/response/attendance-statuses';
import { UpdateAttendanceStatusesRequest } from '@app-autogen/ez.common/contracts/request/update-attendance-statuses-request';
import { UserSettingsService } from '@app-core/auth/user-settings.service';
import { AttendanceStatusInfo } from '@app-autogen/ez.common/contracts/response/attendance-status-info';
import { Observable } from 'rxjs';


@Injectable()
export class AttendanceStatusFacade {

  attendanceStatuses$: Observable<AttendanceStatusInfo[]> = this.storeHelper.stateStreamSelector(AttendanceStatusSelectors.getAttendanceStatuses);

  constructor(
    private storeHelper: EzStoreService<fromAttendanceStatus.AttendanceStatusState>,
    private userSettingsService: UserSettingsService,
  ) {
  }

  fetchAttendanceStatuses() {
    this.storeHelper.dispatchAction(AttendanceStatusActions.getAttendanceStatusesOnLoad());
  }

  updateClientsAttendanceStatusBySessionClassId(attendanceStatus: AttendanceStatuses, sessionClassIds: number[]) {
    const request: UpdateAttendanceStatusesRequest = {
      attendanceStatus: attendanceStatus,
      sessionClassIds: sessionClassIds,
      localDateTime: getDateTimeInUserTimeZone(),
      userHasPermissionEditOldReservations: this.userSettingsService.userHasPermission('EditOldReservations'),
      userHasPermissionMarkAllAttendance: this.userSettingsService.userHasPermission('MarkAllAttendance'),
      userResourceCalendarID: this.userSettingsService.userSession.userResourceCalendarId
    };
    this.storeHelper.dispatchAction(AttendanceStatusActions.updateAttendanceStatusOnClick(request));
  }
}
