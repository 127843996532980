import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '@src/app/state/app.state';
import { ThemeState } from './theme-state';

export interface State extends fromRoot.State {
  theme: ThemeState;
}

// Selector functions
const getThemeFeatureState = createFeatureSelector<ThemeState>('appTheme');

export const getClientNavigation = createSelector(
  getThemeFeatureState,
  state => state.clientNavigation
);

export const getAccessToCsiBatchSettlementReport = createSelector(
  getThemeFeatureState,
  state => state.accessToCsiBatchSettlementReport
);

export const getAccessToPyxisDisbursementsReport = createSelector(
  getThemeFeatureState,
  state => state.accessToPyxisDisbursementsReport
);

export const getAccessToVitalTsysBatchSettlementReport = createSelector(
  getThemeFeatureState,
  state => state.accessToVitalTsysBatchSettlementReport
);

export const getAccessToDirectDebitFailuresReport = createSelector(
  getThemeFeatureState,
  state => state.accessToDirectDebitFailuresReport
);

export const getDirectDebitMerchantIsStreaming = createSelector(
  getThemeFeatureState,
  state => state.hasStreamingDirectDebitMerchant
);

export const getCloverMerchantIsSetUp = createSelector(
  getThemeFeatureState,
  state => state.cloverMerchant
);

export const getNewAdminApprovalIsSetup = createSelector(
  getThemeFeatureState,
  state => state.approvalAdministrator
);
