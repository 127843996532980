import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import {
  SAVE_AND_CLOSE_BUTTON,
  SAVE_AND_NEXT_BUTTON,
  SAVE_BUTTON,
  SAVE_AND_ADD_NEW_BUTTON,
  NEXT_BUTTON,
  CUSTOM_BUTTON_1,
  CUSTOM_BUTTON_3,
  CUSTOM_BUTTON_2
} from '../models/ez-tabs-navigation-button-types';
import { EzTabsNavigationButton } from '../models/ez-tabs-navigation-button';
import { ResourcesProvider } from '@ezuiaws/ez-packages/ez-localization';

@Component({
  selector: 'ez-tabs-navigation-buttons',
  templateUrl: './ez-tabs-navigation-buttons.component.html',
  styleUrls: ['./ez-tabs-navigation-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EzTabsNavigationButtonsComponent implements OnInit {

  @Input() buttonList: EzTabsNavigationButton[] = null;
  @Input() buttonCssClass: string = 'ez-button-primary';
  @Input() cancelButtonCssClass: string = 'ez-button-secondary';
  @Input() showCancelButton: boolean = true;
  @Input() showCopyCheckbox: boolean = false;
  @Input() copyCheckboxValue: boolean = false;

  @Input() copyCheckboxLabel: string = ResourcesProvider.rks.Copy;
  @Input() cancelButtonLabel: string = ResourcesProvider.rks.Cancel;
  @Input() nextButtonLabel: string = ResourcesProvider.rks.Next;
  @Input() saveButtonLabel: string = ResourcesProvider.rks.Save;
  @Input() saveAndCloseButtonLabel: string = ResourcesProvider.rks.SaveAndClose;
  @Input() saveAndNextButtonLabel: string = ResourcesProvider.rks.SaveAndNext;
  @Input() saveAndAddNewButtonLabel: string = ResourcesProvider.rks.SaveAndAddNew;
  @Input() custom1ButtonLabel: string = '';
  @Input() custom2ButtonLabel: string = '';
  @Input() custom3ButtonLabel: string = '';

  @Output() closeClicked = new EventEmitter<any>();
  @Output() copyClicked = new EventEmitter<boolean>();
  @Output() nextClicked = new EventEmitter<any>();
  @Output() saveClicked = new EventEmitter<any>();
  @Output() saveAndCloseClicked = new EventEmitter<any>();
  @Output() saveAndNextClicked = new EventEmitter<any>();
  @Output() saveAndAddNewClicked = new EventEmitter<any>();
  @Output() custom1Clicked = new EventEmitter<any>();
  @Output() custom2Clicked = new EventEmitter<any>();
  @Output() custom3Clicked = new EventEmitter<any>();

  buttonLabels = {};

  constructor() { }

  ngOnInit() {
    this.buttonLabels[NEXT_BUTTON] = this.nextButtonLabel;
    this.buttonLabels[SAVE_AND_CLOSE_BUTTON] = this.saveAndCloseButtonLabel;
    this.buttonLabels[SAVE_AND_NEXT_BUTTON] = this.saveAndNextButtonLabel;
    this.buttonLabels[SAVE_AND_ADD_NEW_BUTTON] = this.saveAndAddNewButtonLabel;
    this.buttonLabels[SAVE_BUTTON] = this.saveButtonLabel;
    this.buttonLabels[CUSTOM_BUTTON_1] = this.custom1ButtonLabel;
    this.buttonLabels[CUSTOM_BUTTON_2] = this.custom2ButtonLabel;
    this.buttonLabels[CUSTOM_BUTTON_3] = this.custom3ButtonLabel;
  }

  close() {
    this.closeClicked.emit();
  }

  onCopyCheckboxClick() {
    this.copyClicked.emit(this.copyCheckboxValue);
  }

  onButtonClicked(
    buttonType: typeof NEXT_BUTTON |
      typeof SAVE_AND_CLOSE_BUTTON |
      typeof SAVE_AND_NEXT_BUTTON |
      typeof SAVE_AND_ADD_NEW_BUTTON |
      typeof SAVE_BUTTON |
      typeof CUSTOM_BUTTON_1 |
      typeof CUSTOM_BUTTON_2 |
      typeof CUSTOM_BUTTON_3
  ): void {
    switch (buttonType) {
      case NEXT_BUTTON:
        this.nextClicked.emit();
        break;
      case SAVE_AND_CLOSE_BUTTON:
        this.saveAndCloseClicked.emit();
        break;
      case SAVE_AND_NEXT_BUTTON:
        this.saveAndNextClicked.emit();
        break;
      case SAVE_AND_ADD_NEW_BUTTON:
        this.saveAndAddNewClicked.emit();
        break;
      case SAVE_BUTTON:
        this.saveClicked.emit();
        break;
      case CUSTOM_BUTTON_1:
        this.custom1Clicked.emit();
        break;
      case CUSTOM_BUTTON_2:
        this.custom2Clicked.emit();
        break;
      case CUSTOM_BUTTON_3:
        this.custom3Clicked.emit();
        break;
    }
  }

  indexButtons(index) {
    return index;
  }
}
