<div class='input-check_group' [ngClass]="{'input-check_group-inline d-flex align-items-center': horizontal}">
  <ng-container *ngIf='_sourceList.length > 0; else emptyListMessage'>
    <div *ngFor="let item of _sourceList">
      <ez-checkbox [attr.data-cy]="'checkbox-' + cypressName(item.label)"
        [cypressName]='cypressName(item.label)'
        [label]="item.label"
        [disabled]='disabled'
        [selected]='_selectedItems.includes(item.value)'
        (valueChanged)='onChange($event, item.value)'>
      </ez-checkbox>
    </div>
  </ng-container>
  <ng-template #emptyListMessage>
    <span>{{ emptyListMessageText }}</span>
  </ng-template>
</div>
