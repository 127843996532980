import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'ez-tree-component',
  templateUrl: './ez-tree-component.component.html',
  styleUrls: ['./ez-tree-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EzTreeComponentComponent implements OnInit {

  _items: TreeNode[] = [];
  selectedItem: TreeNode = {};
  lastSelected: TreeNode = {};

  @Input() selectionMode: boolean = false;
  @Input() set items(items: TreeNode[]) {
    this._items = items;
    this.updateSelected();
  }
  _selected: string;
  @Input() set selected(selected: string) {
    this._selected = selected;
    this.updateSelected();
  }

  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  nodeSelect(event) {
    if (event.node !== this.lastSelected) {
      this.lastSelected = event.node;
      this.selectedChange.emit(event.node);
    }
  }

  updateSelected() {
    let foundTreeNode: TreeNode = {};
    if (this._selected && this._items) {
      foundTreeNode = this._items.find(treeNode => treeNode.data === this._selected);
    }
    this.selectedItem = foundTreeNode;
    this.lastSelected = foundTreeNode;
  }

}
