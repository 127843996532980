import { AfterViewInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TmsMessage } from '@app-autogen/ez.common/contracts/reference-objects/tms-message';
import { TmsMessageType } from '@app-autogen/ez.common/contracts/reference-objects/tms-message-type';
import { AuthService } from '@app-core/auth/auth.service';
import { TmsMessageService } from '@app-shared/services/tms-message.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { EzNotificationService } from '@ezuiaws/ez-packages/ez-notification';
import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';
import { NotificationModel } from '@ezuiaws/ez.ui.models';

import * as appRouter from '@app-root/state/router-state';
import { filter, map, Observable } from 'rxjs';

export enum IframeHeightSetting {
  none,
  hasHeader,
  default,
  hasGroupsHeader
}

@UntilDestroy()
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'tms-iframe',
  templateUrl: './tms-iframe.component.html',
  styleUrls: ['./tms-iframe.component.scss']
})
export class TmsIframeComponent implements OnInit, AfterViewInit {

  @ViewChild('tmsIframeDiv') tmsIframeDiv: ElementRef;

  _redirectPage = '';
  @Input() set redirectPage(redirectPage: string) {
    this._redirectPage = redirectPage;
    if (this.viewLoaded) {
      this.createIframe();
    }
  }
  @Input() submitAfterViewInit: boolean = true;
  @Input() adjustHeight: number = 0;
  @Output() tmsIframeIdSet: EventEmitter<string> = new EventEmitter();

  banners$: Observable<NotificationModel[]> = this.notificationService.banners$;
  bannersCount = 0;

  heightSetting: IframeHeightSetting = IframeHeightSetting.default;

  jwtToken: string;
  tmsForm;
  iFrame: HTMLIFrameElement;
  viewLoaded = false;
  tmsIframeName = '';
  setIframeHeight = false;

  constructor(
    private authService: AuthService,
    private tmsMessageService: TmsMessageService,
    private notificationService: EzNotificationService,
    private storeHelper: EzStoreService<appRouter.State>
  ) { }

  ngOnInit(): void {
    this.banners$
      .pipe(
        filter((banners: NotificationModel[]) => !!banners.length),
        map((banners: NotificationModel[]) => {
          return banners.filter(banner => banner.Enabled && !!banner.NotificationText.trim());
        }),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        if (data && this.tmsIframeDiv) {
          this.bannersCount = data.length;
        }
      });

    this.storeHelper.stateStreamSelector(appRouter.getRouterFeatureState).pipe(
      untilDestroyed(this)
    ).subscribe(route => {
      // when adjustHeight is in the route the framed-height class is added to the full-layout page-wrapper
      // noPadding is currently just used on the Schedule page
      // noPadding is used to get the Iframe for schedule to use as much space that is available
      if (route.state.queryParams?.adjustHeight || route.state.queryParams?.noPadding) {
        this.heightSetting = IframeHeightSetting.none;
      }
    });

    this.tmsMessageService.tmsMessages$.pipe(
      untilDestroyed(this)
    ).subscribe((msg: TmsMessage) => {
      if (msg.MessageType === TmsMessageType.IframeResize && this.tmsIframeDiv) {
        // keeping the commented out code for future use if necessary
        // will remove after a sprint or two
        // const header = 65;
        // const footer = 40;
        // const banner = 34;
        // const subHeader = 50;
        // const groupsMenubar = 50;
        switch (true) {
          // case IframeHeightSetting.hasHeader:
          //   this.tmsIframeDiv.nativeElement.children[this.tmsIframeName].style = 'height:' + (window.innerHeight - (header + subHeader + footer + banner * this.bannersCount)) + 'px';
          //   break;
          // case IframeHeightSetting.hasGroupsHeader:
          //   this.tmsIframeDiv.nativeElement.children[this.tmsIframeName].style = 'height:' + (window.innerHeight - (header + subHeader + groupsMenubar + footer + banner * this.bannersCount)) + 'px';
          //   break;
          case this.adjustHeight > 0:
            this.tmsIframeDiv.nativeElement.children[this.tmsIframeName].style = 'height:' + this.adjustHeight + 'px';
            break;
          case this.heightSetting === IframeHeightSetting.none:
            break;
          default:
            this.tmsIframeDiv.nativeElement.children[this.tmsIframeName].style = 'height:' + msg.Message.toString() + 'px';
        }
      }
    });
  }

  ngAfterViewInit() {
    this.viewLoaded = true;
    this.createIframe();
  }

  createIframe() {

    this.tmsIframeDiv.nativeElement.innerHTML = '';

    // creating the form and iFrame dynamically is necessary to allow 2 or more tms-iframe component instances to work on the same page
    this.tmsIframeName = 'tmsIframe_' + Math.floor(Math.random() * Math.floor(10000000));
    this.tmsIframeIdSet.emit(this.tmsIframeName);
    this.tmsForm = document.createElement('form');
    this.tmsForm.target = this.tmsIframeName;
    this.tmsForm.method = 'POST';
    this.tmsForm.action = `${this.authService.getTmsBaseUrl()}/login.aspx`;
    // this.tmsForm.action = 'https://local.tms.ezfacility.com/login.aspx';

    const jwtTokenInput = document.createElement('input');
    jwtTokenInput.type = 'hidden';
    jwtTokenInput.name = 'jwtToken';
    jwtTokenInput.value = this.authService.jwt;
    this.tmsForm.appendChild(jwtTokenInput);

    const redirectPageInput = document.createElement('input');
    redirectPageInput.type = 'hidden';
    redirectPageInput.name = 'RedirectPage';
    redirectPageInput.value = this._redirectPage;
    this.tmsForm.appendChild(redirectPageInput);

    this.tmsIframeDiv.nativeElement.appendChild(this.tmsForm);

    this.iFrame = document.createElement('iframe');
    this.iFrame.name = this.tmsIframeName;
    this.iFrame.id = this.tmsIframeName;
    this.iFrame.classList.add(this.submitAfterViewInit ? 'tms-iframe' : 'tms-iframe-hide');
    this.iFrame.allowFullscreen = true;

    this.tmsIframeDiv.nativeElement.appendChild(this.iFrame);

    if (this.submitAfterViewInit) {
      // When switching from one page with a tms iframe to another, the 2nd iframe only shows
      // of we add this slight delay. Without the delay, the iframe only shows if navigating
      // from a previous page that does not also have a tms iframe.
      setTimeout(function (tmsForm) {
        tmsForm.submit();
      }, 10, this.tmsForm);
    }
  }

  addPostValue(name: string, value: string) {
    if (this.tmsForm) {
      if (this.tmsForm.children[name]) {
        this.tmsForm.children[name].value = value;
      } else {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;
        this.tmsForm.appendChild(input);
      }
    }
  }

  submitForm(showIframe: boolean) {
    this.tmsForm.submit();
    if (showIframe) {
      this.iFrame.classList.remove('tms-iframe-hide');
      this.iFrame.classList.add('tms-iframe');
    }
  }

}
