import { AbstractControl, ControlValueAccessor, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
export class EzControlValueAccessor<T extends AbstractControl> implements ControlValueAccessor {

  form: T;

  onChange: any = () => { };
  onTouched: any = () => { };

  public writeValue(value: any): void {
    if (value === null || typeof value === 'undefined') {
      this.form.reset();
    } else {
      this.form.patchValue(value, { emitEvent: false });
    }
  }

  public registerOnChange(fn: (x: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

    this.form.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe((values: T) => {
      /**
       * Mark as 'touched' only after manual changes one of FormGroup control
       * Send form.getRawValue() to keep 'disabled' fields, only if FormGroup
       */
      if (this.form instanceof UntypedFormGroup) {
        for (const control in this.form.controls) {
          if (this.form.controls[control].touched) {
            this.onTouched();
            break;
          }
        }
        this.onChange(this.form.getRawValue());
      } else {
        this.onTouched();
        this.onChange(values);
      }
    });
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable()
      : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subform: { valid: false } };
  }

  public forceTouched() {
    this.onTouched();
  }

  public forceChange() {
    if (this.form instanceof UntypedFormGroup) {
      this.onChange(this.form.getRawValue());
    }
  }
}
