import { Pipe, PipeTransform } from '@angular/core';
import { SessionSupportStatus } from '../../autogen/ez.core/models/enums/session-support-status';

@Pipe({
  name: 'sessionSupportStatus'
})
export class SessionSupportStatusPipe implements PipeTransform {

  transform(value: SessionSupportStatus): string {
    return SessionSupportStatus[value];
  }

}
