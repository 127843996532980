import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EzControlValueAccessor } from '../../ez-form-controls/ez-control-value-accessor';
import { EzFormControl } from '../../ez-form-controls/ez-form-control';

import { EzNotificationService } from '@ezuiaws/ez-packages/ez-notification';
import { NotificationType } from '@ezuiaws/ez.ui.models';
import { ResourcesProvider } from '@ezuiaws/ez-packages/ez-localization';

import { Editor } from 'primeng/editor';
import * as Quill from 'quill';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'ez-form-control-editor',
  templateUrl: './ez-form-control-editor.component.html',
  styleUrls: ['./ez-form-control-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzFormControlEditorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EzFormControlEditorComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EzFormControlEditorComponent extends EzControlValueAccessor<EzFormControl> implements OnInit, AfterViewInit {

  @ViewChild('editor') editor: Editor;

  @Input() placeholder: string = 'Enter message text';
  @Input() height: number = 150;
  @Input() textValue: string = '';
  @Input() submitOnKeyEnter: boolean = false;
  @Input() control: EzFormControl;
  @Input() readonly: boolean = false;
  @Input() characterLimit: number = 800000;
  @Input() imageFileSizeLimit: number = 350000;

  @Output() changeTextEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() keyEnterEvent: EventEmitter<string> = new EventEmitter<string>();

  cypressName: string = '';

  characterCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private notificationService: EzNotificationService) {
    super();
  }

  ngAfterViewInit(): void {
    this.characterCount$.next(this.form.value ? this.form.value.length : 0);
  }

  ngOnInit(): void {
    this.form = new EzFormControl(null, null, null, 'notes', 'Notes');
  }

  onEditorCreated(editorInstance: any) {
    // Access the Quill instance
    const quill = editorInstance;

    // the following code is taken from the PrimeNG editor source code and modified to fit our needs
    // Register a custom handler for the "Insert Image" button
    const toolbar = quill.editor.getModule('toolbar');
    toolbar.addHandler('image', () => {
      let fileInput = this.editor.el.nativeElement.querySelector('input[type=file].ql-image')
      if (fileInput == null) {
        fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
        fileInput.classList.add('ql-image');
        fileInput.classList.add('d-none');
        fileInput.addEventListener('change', () => {

          // Check if any file is selected
          if (fileInput.files != null && fileInput.files[0] != null) {

            // check if the selected file is larger than 1MB
            // if so, show a warning message and clear the file input and exit
            if (fileInput.files[0].size > this.imageFileSizeLimit) {
              this.notificationService.sendSuccessNotification({
                message: ResourcesProvider.rks.SizeLimitExceededMessage,
                title: ResourcesProvider.rks.SizeLimitExceeded,
                type: NotificationType.warning
              });
              fileInput.value = '';
              return;
            }

            // execute the default handler code
            let reader = new FileReader();
            reader.onload = (e) => {
              let range = toolbar.quill.getSelection(true);
              toolbar.quill.insertEmbed(range.index, 'image', e.target.result, Quill.sources.USER);
              toolbar.quill.setSelection(range.index + 1, Quill.sources.SILENT);
              fileInput.value = '';
            }
            reader.readAsDataURL(fileInput.files[0]);
          }
        });
        // add the file input to the editor
        this.editor.el.nativeElement.appendChild(fileInput);
      }
      // trigger the file input
      this.editor.el.nativeElement.querySelector('input[type=file].ql-image').click();
    });
  }


  onTextChange(quillEvent) {
    this.characterCount$.next(quillEvent.htmlValue ? quillEvent.htmlValue.length : 0);
    if (this.characterCount$.value > this.characterLimit) {
      this.notificationService.sendSuccessNotification({
        message: ResourcesProvider.rks.ContentLimitExceededMessage,
        title: ResourcesProvider.rks.ContentLimitExceeded,
        type: NotificationType.warning
      });
    }
  }

  onKeyEnter() {
    if (this.submitOnKeyEnter) {
      this.submitMessage();
    }
  }

  submitMessage() {
    const submitMessageText = this.prepareMessageText();
    this.keyEnterEvent.emit(submitMessageText);

    this.clearTextEditor();
  }

  prepareMessageText(): string {
    const message: string = this.form.value?.replace(/<[^/>][^>]*><\/[^>]+>/gim, '')
    return message;
  }

  clearTextEditor() {
    this.form.reset();
    this.characterCount$.next(0);
  }
}
