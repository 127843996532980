import { OpenInvoiceSelectState } from './open-invoice-select-state';
import * as OpenInvoiceSelectActions from './open-invoice-select.actions';
import { ActionWithPayload } from '@ezuiaws/ez-packages/ez-socket';
import { createReducer, on } from '@ngrx/store';
import { ClientInvoiceResponse } from '@app-autogen/ez.client/contracts/response/client-invoice-response';

const initialState: OpenInvoiceSelectState = {
  openInvoices: []
};

const reducer = createReducer<OpenInvoiceSelectState>(
  initialState,
  on(OpenInvoiceSelectActions.getOpenInvoicesSuccess, (state, { payload }) => {
    return {
      ...state,
      openInvoices: payload
    };
  }),

  on(OpenInvoiceSelectActions.getOpenInvoicesFail, (state) => {
    return {
      ...state,
      openInvoices: []
    };
  })
);

export function openInvoiceSelectReducer(state: OpenInvoiceSelectState | undefined, action: ActionWithPayload) {
  return reducer(state, action);
}
