import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[autocompleteOff]',
})
export class AutocompleteOffDirective implements AfterViewInit {

  environment: string = '';

  constructor(
    private el: ElementRef
  ) { }

  ngAfterViewInit(): void {
    this.environment = localStorage.getItem('environment');
    this.el.nativeElement.setAttribute('autocomplete', this.autocompleteValueDependOnEnvironment);
  }

  get autocompleteValueDependOnEnvironment(): string {
    if (this.environment.toLocaleLowerCase() === 'production'
      || this.environment.toLocaleLowerCase() === 'ua') {
      return 'new-password';
    }
    return 'on';
  }

}
