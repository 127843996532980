import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

export function dirtyCheck<U>(source: Observable<U>) {
  // tslint:disable-next-line:only-arrow-functions
  return function<T>(valueChanges: Observable<T>): Observable<boolean> {
    return combineLatest([source, valueChanges]).pipe(
      debounceTime(300),
      map(([a, b]) => !equal(a, b)),
      startWith(false)
    );
  };
}

export function equal(a: any, b: any): boolean {
  // Convert '' to null
  a = a === '' ? null : a;
  b = b === '' ? null : b;

  // Handle number comparison
  if (typeof a === 'number' && typeof b === 'number') {
    return a === b;
  }

  // Handle date comparison if both a and b are valid date strings
  if (typeof a === 'string' && typeof b === 'string') {
    const aDate = Date.parse(a);
    const bDate = Date.parse(b);

    if (!isNaN(aDate) && !isNaN(bDate)) {
      return aDate === bDate;
    }
  }

  // Handle objects and arrays
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) {
      return false;
    }

    let length, i, keys;

    if (Array.isArray(a)) {
      length = a.length;
      if (length !== b.length) {
        return false;
      }
      for (i = length; i-- !== 0; ) {
        if (!equal(a[i], b[i])) {
          return false;
        }
      }
      return true;
    }

    if (a.constructor === RegExp) {
      return a.source === b.source && a.flags === b.flags;
    }

    if (a.valueOf !== Object.prototype.valueOf) {
      return a.valueOf() === b.valueOf();
    }

    if (a.toString !== Object.prototype.toString) {
      return a.toString() === b.toString();
    }

    keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) {
      return false;
    }

    for (i = length; i-- !== 0; ) {
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) {
        return false;
      }
    }

    for (i = length; i-- !== 0; ) {
      const key = keys[i];
      if (!equal(a[key], b[key])) {
        return false;
      }
    }

    return true;
  }

  return a === b || (a !== a && b !== b); // Handle NaN comparison
}
