import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, defaultIfEmpty, filter, map } from 'rxjs';
import * as appRouter from '@app-root/state/router-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';

import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';
import { EZLayoutService } from '@ezuiaws/ez-packages/ez-layout';
import { EzNotificationService } from '@ezuiaws/ez-packages/ez-notification';
import { NotificationModel, BannerNotificationModel } from '@ezuiaws/ez.ui.models';
import { EzStatisticsService } from '@ezuiaws/ez-packages/ez-statistics';
import { UserSettingsService } from '@app-core/auth/user-settings.service';

@UntilDestroy()
@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
  // View Encapsulation is set to none so that the html in coming from the innerHtml can be styled.
  encapsulation: ViewEncapsulation.None,
})
export class FullLayoutComponent implements OnInit {

  showSideModal$: Observable<boolean> = this.layoutService.showSideModal$;
  showSideBar$: Observable<boolean> = this.layoutService.showSidebar$;
  isDesktopDevice$: Observable<boolean> = this.ezStatisticsService.isDesktopDevice$;
  isTabletDevice$: Observable<boolean> = this.ezStatisticsService.isTabletDevice$;
  isMobileDevice$: Observable<boolean> = this.ezStatisticsService.isMobileDevice$;
  isIphone$: Observable<boolean> = this.ezStatisticsService.isIphone$;
  isSafariBrowser$: Observable<boolean> = this.ezStatisticsService.isSafariBrowser$;

  noRouterOutletPadding: boolean = false;
  adjustHeight: boolean = false;

  banners$: Observable<NotificationModel[]> = this.ezNotificationService.banners$.pipe(
    filter((banners: NotificationModel[]) => !!banners.length),
    map((banners: NotificationModel[]) => {
      const isSurveyIncluded = !!banners.find(banner => banner.NotificationType === BannerNotificationModel.Survey)
        && this.userSettingsService.getUserPreferenceBooleanValue('ShowGlobalNotification');

      if (!this.userSettingsService.getUserPreferenceBooleanValue('ShowGlobalNotification')
        && !!banners.find(banner => banner.NotificationType === BannerNotificationModel.Survey)) {
        this.ezNotificationService.removeSurveyBanner();
      }
      this.isSurveyBannerIncluded$.next(isSurveyIncluded);
      return banners.filter(banner => banner.Enabled && !!banner.NotificationText.trim());
    }));

  isSurveyBannerIncluded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  conditionalBanner$: Observable<NotificationModel[]> = this.ezNotificationService.conditionalBanners$;


  constructor(
    private layoutService: EZLayoutService,
    private storeHelper: EzStoreService<appRouter.State>,
    private renderer: Renderer2,
    private ezNotificationService: EzNotificationService,
    private ezStatisticsService: EzStatisticsService,
    private userSettingsService: UserSettingsService
  ) { }

  ngOnInit() {
    this.configureSidebar();
    this.watchSideBarValueChanges();
    this.configureHeightAndPadding();
    this.subscribeOnBannerLength();
  }

  //Set default view of sidebar to be collapsed if device is not desktop
  private configureSidebar() {
    combineLatest([this.isDesktopDevice$, this.isTabletDevice$, this.isMobileDevice$, this.isIphone$, this.isSafariBrowser$]).pipe(
      untilDestroyed(this),
    ).subscribe(([isDesktopDevice, isTabletDevice, isMobileDevice, isIphone, isSafariBrowser]) => {

      this.renderer.removeClass(document.body, 'tablet-view');
      this.renderer.removeClass(document.body, 'mobile-view');

      if (!isDesktopDevice) {
        this.layoutService.toggleSidebar();
      }
      if (isTabletDevice) {
        this.renderer.addClass(document.body, 'tablet-view');
      }
      if (isMobileDevice) {
        this.renderer.addClass(document.body, 'mobile-view');
      }
      if (isMobileDevice && isIphone) {
        this.renderer.addClass(document.body, 'iphone');
      }
      if (isSafariBrowser) {
        this.renderer.addClass(document.body, 'safari');
      }
    });
  }

  private watchSideBarValueChanges() {
    this.showSideBar$
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this)
      ).subscribe((showSideBar: boolean) => {
        if (showSideBar) {
          this.renderer.removeClass(document.body, 'mini-navbar');
        } else {
          this.renderer.addClass(document.body, 'mini-navbar');
        }
      });
  }

  private configureHeightAndPadding() {
    this.storeHelper.stateStreamSelector(appRouter.getRouterFeatureState)
      .pipe(untilDestroyed(this))
      .subscribe((route) => {
        this.noRouterOutletPadding = !!route.state.queryParams?.noPadding;
        this.adjustHeight = !!route.state.queryParams?.adjustHeight;
      });
  }

  private subscribeOnBannerLength() {
    combineLatest([
      this.banners$.pipe(defaultIfEmpty([])),
      this.conditionalBanner$.pipe(defaultIfEmpty([]))
    ]).pipe(untilDestroyed(this))
      .subscribe(([banners, conditionalBanner]) => {
        const count = (banners?.length || 0) + (conditionalBanner?.length || 0);
        document.documentElement.style.setProperty("--banner-count", count.toString());
      });
  }
}