<!-- new feature modal -->
<ez-confirmation-modal [showButtons]="false"
  *ngIf="showImportantMessage"
  (confirmationClosed)="confirmationClosed()">
  <div class="important-message-modal">
    <div class="important-message-body text-center">
      <h1 class="text-heading-1 heading-gray mt-0 mb-0 modal-heading">{{ 'ImportantMessageModalTitle' | ezLocale }}</h1>
      <div class="text-body heading-black mt-0 mb-0" [innerHtml]="'ImportantMessageModalBody' | ezLocale | keepHtml"></div>
    </div>
    <div footer
      class="important-message-footer">
        <ez-buttons [saveButtonLabel]="'TakeTheSurvey' | ezLocale"
          [cancelButtonCssClass]="'button-large'"
          [cancelButtonLabel]="'NoThanks' | ezLocale"
          [addLeftRightPadding]= "false"
          [addTopBottomPadding]= "false"
          (cancelClicked)="closeModalDialog()"
          (saveClicked)="onTakeClick()">
        </ez-buttons>
    </div>
  </div>
</ez-confirmation-modal>