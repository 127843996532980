import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AttendanceStatusFacade } from '@app-shared/state/attendance-status-state/attendance-status.facade';
import { clientAttendanceStatusReducer, ATTENDANCE_STATUSES_FEATURE_KEY } from '@app-shared/state/attendance-status-state/attendance-status.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AttendanceStatusEffects } from '@app-shared/state/attendance-status-state/attendance-status.effects';

@NgModule({
  declarations: [],
  imports: [
    EffectsModule.forFeature([AttendanceStatusEffects]),
    StoreModule.forFeature(ATTENDANCE_STATUSES_FEATURE_KEY, clientAttendanceStatusReducer)
  ],
  providers: [
    AttendanceStatusFacade
  ]
})
export class AttendanceStatusStateModule {
}
