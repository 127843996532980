import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DayOfWeek } from '../../../autogen/ez.schedule/contracts/response/day-of-week';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LabelValueViewModel } from '@ezuiaws/ez.ui.models';
import { EzControlValueAccessor, EzFormControl } from '../../ez-form-controls';

import { ResourcesProvider } from '@ezuiaws/ez-packages/ez-localization';

import { getCypressFieldName } from '../../helpers';

@UntilDestroy()
@Component({
  selector: 'ez-days-of-week-checkbox',
  templateUrl: './ez-days-of-week-checkbox.component.html',
  styleUrls: ['./ez-days-of-week-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzDaysOfWeekCheckboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EzDaysOfWeekCheckboxComponent),
      multi: true
    }
  ]
})
export class EzDaysOfWeekCheckboxComponent extends EzControlValueAccessor<UntypedFormGroup> implements OnInit, OnChanges, AfterViewInit {

  @Input() control: EzFormControl;
  @Input() horizontal = true;
  @Input() disabled = false;
  @Input() isRequired = false;
  @Input() label: string = ResourcesProvider.rks.On;
  @Input() selectedDaysOfWeek: DayOfWeek[];

  @Output() daysOfWeekSelectedChangedEvent: EventEmitter<DayOfWeek[]> = new EventEmitter<DayOfWeek[]>();

  form: UntypedFormGroup;
  cypressName: string = '';

  daysList: LabelValueViewModel[] = [
    { label: ResourcesProvider.rks.Mon, value: DayOfWeek.Monday },
    { label: ResourcesProvider.rks.Tue, value: DayOfWeek.Tuesday },
    { label: ResourcesProvider.rks.Wed, value: DayOfWeek.Wednesday },
    { label: ResourcesProvider.rks.Thu, value: DayOfWeek.Thursday },
    { label: ResourcesProvider.rks.Fri, value: DayOfWeek.Friday },
    { label: ResourcesProvider.rks.Sat, value: DayOfWeek.Saturday },
    { label: ResourcesProvider.rks.Sun, value: DayOfWeek.Sunday },
  ];

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngAfterViewInit(): void {
    // used forceChange to initialize parent form using EzControlValueAccessor
    this.forceChange();
  }

  ngOnInit(): void {
    this.cypressName = getCypressFieldName(this.control);
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.form && changes) {
      if (changes.selectedDaysOfWeek && changes.selectedDaysOfWeek.currentValue) {
        this.form.get('daysOfWeek').setValue(changes.selectedDaysOfWeek.currentValue);
      }
    }
  }

  private initForm() {
    this.form = this.fb.group({
      daysOfWeek: new EzFormControl(this.selectedDaysOfWeek, null, null, 'daysOfWeek', this.label),
    });

    if (this.isRequired) {
      this.form.controls['daysOfWeek'].setValidators([
        Validators.required
      ]);
    }
    this.form.updateValueAndValidity();
  }

  onDaysOfWeekSelectedChanged(daysOfWeek: DayOfWeek[]) {
    this.form.controls['daysOfWeek'].setValue(daysOfWeek);
    this.form.controls['daysOfWeek'].markAsTouched();
    this.daysOfWeekSelectedChangedEvent.emit(daysOfWeek);
  }
}
