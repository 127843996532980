import { Inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { EZ_CRYPTO_PASSWORD } from '../ez-consts';

@Injectable({
  providedIn: 'root'
})
export class EzCryptoService {

  readonly keySize = 8;
  readonly ivSize = 16;
  readonly iterations = 100;

  constructor(@Inject(EZ_CRYPTO_PASSWORD) private password: string) {}

  public encrypt(message: string): string {
    const salt = CryptoJS.lib.WordArray.random(this.ivSize);
    const iv = CryptoJS.lib.WordArray.random(this.ivSize);
    const key = this.getPBKDF2Code(this.password, salt);

    const encrypted = CryptoJS.AES.encrypt(message, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    // salt, iv will be hex 32 in length
    // append them to the ciphertext for use  in decryption
    return salt.toString() + iv.toString() + encrypted.toString();
  }

  public decrypt(transitMessage: string): string {
    const salt = CryptoJS.enc.Hex.parse(this.getSaltFromMessage(transitMessage));
    const iv = CryptoJS.enc.Hex.parse(this.getIVFromMessage(transitMessage));
    const key = this.getPBKDF2Code(this.password, salt);

    const encrypted = transitMessage.substring(64);
    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encHexParse(salt) {
    return CryptoJS.enc.Hex.parse(salt);
  }

  getPBKDF2Code(message: string, salt): CryptoJS.lib.WordArray {
    return CryptoJS.PBKDF2(message, salt, {
      keySize: this.keySize,
      iterations: this.iterations
    });
  }

  getSaltFromMessage(transitMessage: string): string {
    return transitMessage.substr(0, 32);
  }

  getIVFromMessage(transitMessage: string): string {
    return transitMessage.substr(32, 32);
  }

  getHashFromMessage(transitMessage: string): string {
    return transitMessage.substring(32);
  }
}
