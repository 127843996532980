<div [formGroup]="form">
  <div class='d-flex flex-wrap between-radios'>
    <div class='d-flex flex-wrap'>
      <ez-form-control-template [control]="form.get('endingOnOrAfter')">
        <div class="radio_group radio-group-inline mt-0">
          <div class='radio-group-item'>
            <p-radioButton formControlName="endingOnOrAfter"
              [value]="endingOption.On"
              labelStyleClass='mr-2'
              [attr.data-cy]="cypressName + 'endingOnOrAfter_on'"
              [label]="('On' | ezLocale)">
            </p-radioButton>
          </div>
        </div>
      </ez-form-control-template>

      <div class="form-group-no-label">
        <ez-form-control-template [control]='form.controls["endingOnDate"]'
          [showLabel]='false'>
          <ez-date-picker formControlName="endingOnDate"
            [control]='form.controls["endingOnDate"]'
            [viewFormat]="viewFormat"
            [dateFormat]="dateFormat"
            [showButtonBar]='true'
            [minDate]="minDate">
          </ez-date-picker>
        </ez-form-control-template>
      </div>
    </div>
    <div class='d-flex flex-wrap'>
      <ez-form-control-template [control]="form.get('endingOnOrAfter')">
        <div class="radio_group radio-group-inline mt-0">
          <div class='radio-group-item'>
            <p-radioButton formControlName="endingOnOrAfter"
              [value]="endingOption.After"
              labelStyleClass='mr-2'
              [attr.data-cy]="cypressName + 'endingOnOrAfter_after'"
              [label]="('After' | ezLocale) + ':' ">
            </p-radioButton>
          </div>
        </div>
      </ez-form-control-template>
      <div class="form-group-no-label">
        <ez-form-control-input [control]='form.controls["endingAfterOccurrences"]'
          [isNumeric]='true'
          [showLabel]="false"
          [showPostAddon]='true'
          formControlName='endingAfterOccurrences'>
          <div postAddon
            class="d-flex align-items-center ml-2">
            <div>{{ 'Occurrences' | ezLocale }}</div>
          </div>
        </ez-form-control-input>
      </div>
    </div>
  </div>
</div>
