
import { createActionHelper } from '@ezuiaws/ez-packages/ez-store';
import { OpenInvoiceSelectActionTypes } from '@app-autogen/ez.common/contracts/action-types/open-invoice-select-action-types';
import { ClientInvoiceResponse } from '@app-autogen/ez.client/contracts/response/client-invoice-response';
import { ClientInvoiceRequest } from '@app-autogen/ez.client/contracts/request/client-invoice-request';

export const getOpenInvoices = createActionHelper<ClientInvoiceRequest>(OpenInvoiceSelectActionTypes.GetOpenInvoices);

export const getOpenInvoicesSuccess = createActionHelper<ClientInvoiceResponse[]>(OpenInvoiceSelectActionTypes.GetOpenInvoicesSuccess);

export const getOpenInvoicesFail = createActionHelper<string>(OpenInvoiceSelectActionTypes.GetOpenInvoicesFail);
