<div class="ez-tabs-navigation-buttons d-flex justify-content-between">
  <div class="align-items-center">
    <ez-secondary-button *ngIf='showCancelButton'
      [label]="cancelButtonLabel"
      [cssClass]='cancelButtonCssClass'
      (click)="close()">
    </ez-secondary-button>

    <ng-content select="[custom-cancel]"></ng-content>
  </div>
  <div class="align-items-center">

    <ng-content></ng-content>

    <div class="d-flex align-items-center flex-wrap">
      <ng-container *ngIf="showCopyCheckbox">
        <div class='mr-2'>
          <ez-checkbox [label]="copyCheckboxLabel"
            (click)="onCopyCheckboxClick()"
            [(ngModel)]="copyCheckboxValue"
            binary="true">
          </ez-checkbox>
        </div>
      </ng-container>

      <ez-button *ngFor="let item of buttonList; trackBy:indexButtons"
        [cssClass]='buttonCssClass + " button-medium ml-2"'
        [label]="buttonLabels[item.type]"
        [disabled]="(item.isDisabled)"
        (click)="onButtonClicked(item.type)">
      </ez-button>
    </div>

  </div>
</div>
