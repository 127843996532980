import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { LocationResponse } from '@app-autogen/ez.common/contracts/response/location-response';
import { BehaviorSubject, Observable } from 'rxjs';
import { getCypressFieldName } from '@ez-packages';
import { UserSettingsService } from '@app-core/auth/user-settings.service';
import { SharedStateFacade } from '@src/app/state/shared-state/shared-state.facade';
import { LocalizationService } from '@app-core/localization/localization.service';
import { EZLayoutService, ConfirmationModalSettings, ConfirmationConfirmed } from '@ezuiaws/ez-packages/ez-layout';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-location-select',
  templateUrl: './location-select.component.html',
  styleUrls: ['./location-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationSelectComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationSelectComponent implements OnInit, ControlValueAccessor, AfterViewInit {

  private readonly allLocationsId: number = 0;

  @Input() control: AbstractControl;
  @Input() allowSelectAll: boolean = false;
  @Input() showFilter: boolean = false;
  @Input() locationPlaceholder: string = `${LocalizationService.rks.SelectLocation}:`;

  locationIdToSelect$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  prevLocationId$: BehaviorSubject<number> = new BehaviorSubject<number>(this.allLocationsId);

  currentSelectedLocationId: number = this.allLocationsId;
  isLocationIdSet: boolean = false;
  areOptionLoaded: boolean = false;

  @Input() set selectedLocationId(value: number) {
    this.isLocationIdSet = true;
    this.currentSelectedLocationId = value;
  }

  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set isDisabled(value: boolean) {
    this.setDisabledState(value);
  }

  confirmLocationChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() set confirmLocationChange(value: boolean) {
    this.confirmLocationChange$.next(value);
  }

  @Input() returnObject: boolean = false;

  // if handling the canSwitchLocation output event, setting requireShareClientsAcrossLocations to true
  // will also require the company to share clients across locations for the canSwitchLocation output event to emit true
  @Input() requireShareClientsAcrossLocations: boolean = false;

  @Output() changed: EventEmitter<LocationResponse> = new EventEmitter();
  @Output() allSelected: EventEmitter<any> = new EventEmitter();
  // if the parent component needs to hide this component based on number of locations or the 'SwitchLocation' user permissions,
  // then handle the canSwitchLocation output event.
  // if the company also needs to require sharing clients across locations, then set requireShareClientsAcrossLocations to true.
  @Output() canSwitchLocation: EventEmitter<boolean> = new EventEmitter();

  userHasSwitchLocationPermission: boolean = this.userSettingsService.userHasPermission('SwitchLocation');

  shareClientsAcrossLocations: boolean = this.userSettingsService.userSession.shareClientsAcrossLocations;

  dropDownOptions$: BehaviorSubject<LocationResponse[]> = new BehaviorSubject([]);

  locations$: Observable<LocationResponse[]> = this.sharedStateFacade.locations$;
  locationsResponse: LocationResponse[];

  confirmationSettings: ConfirmationModalSettings = {
    confirmationId: 'location-change-confirmation',
    title: LocalizationService.rks.ConfirmCancelingTitle,
    message: LocalizationService.rks.ConfirmCancelingFormMessage,
    showCancel: true,
    btnCloseText: LocalizationService.rks.Close
  };

  cypressName: string = '';

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private sharedStateFacade: SharedStateFacade,
    private userSettingsService: UserSettingsService,
    private layoutService: EZLayoutService
  ) {
  }

  ngAfterViewInit(): void {
    this.cypressName = this.control ? 'select-location-' + getCypressFieldName(this.control) : 'select-location';

    if (this.control) {
      this.setDisabledState(this.control.disabled);
    }
  }

  ngOnInit() {
    this.locations$.pipe(
      filter(locations => Array.isArray(locations) && locations.length > 0),
      take(1)
    ).subscribe(locations => {
      this.locationsResponse = locations;
      this.getOptions(locations);
      this.areOptionLoaded = true;
    });

    this.layoutService.confirmationConfirmedSettings$.pipe(
      filter((confirmed: ConfirmationConfirmed) => !!confirmed.confirmationId),
      untilDestroyed(this))
      .subscribe((confirmed: ConfirmationConfirmed) => {

        if (confirmed.confirmationId === this.confirmationSettings.confirmationId) {
          if (confirmed.confirmed) {
            this.layoutService.showConfirmationOnLocationChange(false);

            this.writeValue(this.locationIdToSelect$.value);
            this.emitOptionChangeEvent(this.locationIdToSelect$.value);
          } else {
            // need here to fix timing issue
            setTimeout(() => {
              this.writeValue(this.prevLocationId$.value);
            }, 1);
          }
        }
      });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled$.next(isDisabled);
  }

  onLocationChanged(locationId: number) {
    this.locationIdToSelect$.next(locationId);
    this.prevLocationId$.next(this.currentSelectedLocationId);

    this.onTouched();
    this.writeValue(locationId);

    if (this.confirmLocationChange$.value) {
      this.layoutService.showConfirmationWithSettings(this.confirmationSettings);
    } else {
      this.emitOptionChangeEvent(locationId);
    }
  }

  writeValue(locationId: number): void {
    this.selectedLocationId = locationId;
    const options = this.dropDownOptions$.value;
    if (Array.isArray(options) && options.length > 0) {
      const selectedEl: LocationResponse = options.find(el => el.locationId === locationId);
      if (selectedEl) {
        if (this.returnObject) {
          this.onChange(selectedEl);
        } else {
          this.onChange(locationId);
        }
      }
    }

    this.cdr.markForCheck();
  }

  private setupCanSwitchLocation() {
    // setup the location drop if we have already gotten all the location data
    // only evaluate if the user can switch locations if we have already gotten the list of locations
    const options = this.dropDownOptions$.value;
    if (options.length) {

      let canSwitchLocation: boolean = false;

      // only show to switch the location if there is more than 1 location, the user has the permission to do so,
      // and the company shares clients (or its not required to share clients)
      if (options.length > 1
        && this.userHasSwitchLocationPermission
        && (!this.requireShareClientsAcrossLocations || this.shareClientsAcrossLocations)) {
        canSwitchLocation = true;
      }

      this.canSwitchLocation.emit(canSwitchLocation);
    }
  }

  private getOptions(locations: LocationResponse[]) {
    const options: LocationResponse[] = [...locations];

    if (this.allowSelectAll && !options.some(x => x.locationId === this.allLocationsId)) {
      options.unshift({
        locationId: this.allLocationsId,
        locationName: LocalizationService.rks.DropdownAllOption(),
        timeZone: null,
        cultureCode: null
      });
    }

    this.dropDownOptions$.next(options);

    this.setupCanSwitchLocation();
  }

  private emitOptionChangeEvent(locationId: number) {
    if (Array.isArray(this.locationsResponse) && this.locationsResponse.length > 0) {
      const selectedEl: LocationResponse = this.locationsResponse.find(el => el.locationId === locationId);
      if (selectedEl) {
        this.changed.emit(selectedEl);
        this.allSelected.emit(locationId === 0 ? this.locationsResponse : null);
      }
    }
  }
}
