<div class="row"
  [ngClass]="{'pt-3': hasContentProjection()}">
  <div class="col d-flex align-self-center justify-content-between align-items-center">
    <div #wrapperLeft
      class="sub-header-text-container text-left">
      <h2 class="my-0 text-heading-1 heading-gray">
        <ng-content select="[contentContainer]"></ng-content>
      </h2>
    </div>
    <div #wrapperRight
     class="text-right">
      <ng-content select="[actionContainer]"></ng-content>
    </div>
  </div>
</div>

<ng-container *ngIf='showBreadCrumbs'>
  <h2>{{viewName}}</h2>
  <p-breadcrumb [model]="crumbs"
    [home]="home"></p-breadcrumb>
</ng-container>
