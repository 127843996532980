import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { EzFormControl } from '../../ez-form-controls';
import { getCypressFieldName, getDateTimeInUserTimeZone } from '../../helpers';
import { EzDatePipe, EzDatePipeFormat } from '../../pipes';
import { EzCalendarFormat, EzCalendarView } from '../ez-date-picker/ez-date-picker.component';
import { CarousalDatePickerIntervalType } from './ez-carousal-date-picker-interval-type-enum';

@Component({
  selector: 'ez-carousal-date-picker',
  templateUrl: './ez-carousal-date-picker.component.html',
  styleUrls: ['./ez-carousal-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzCarousalDatePickerComponent),
      multi: true
    }
  ]
})
export class EzCarousalDatePickerComponent implements OnInit {

  //for now, hard-code earliest min date as 2020-01-01
  readonly minDate = new Date(2020, 0, 1);

  cypressName: string = '';
  datePipe = new EzDatePipe();
  viewFormat: EzCalendarView = EzCalendarView.date;
  dateFormat: EzCalendarFormat = EzCalendarFormat.shortDate;
  displayedDateText: string = '';

  @Input() control: EzFormControl;
  @Input() intervalType: CarousalDatePickerIntervalType = CarousalDatePickerIntervalType.day;

  _selectedDate: BehaviorSubject<Date> = new BehaviorSubject(null);
  @Input() set selectedDate(date: Date) {
    this._selectedDate.next(date);
    this.setDisplayedDateText();
    this.selectedDateChange.emit(date);
  }
  get selectedDate(): Date {
    return this._selectedDate.value;
  }

  @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();

  ngOnInit() {
    this.cypressName = getCypressFieldName(this.control);

    //set the initial selected date and date picker formatting based on the interval type
    const todayDate = new Date(getDateTimeInUserTimeZone());
    switch (this.intervalType) {
      case CarousalDatePickerIntervalType.day:
        this._selectedDate.next(todayDate);
        this.viewFormat = EzCalendarView.date;
        this.dateFormat = EzCalendarFormat.shortDate;
        break;
      case CarousalDatePickerIntervalType.week:
        //TODO
        break;
      case CarousalDatePickerIntervalType.month:
        this._selectedDate.next(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
        this.viewFormat = EzCalendarView.month;
        this.dateFormat = EzCalendarFormat.monthAndYear;
        break;
    }

    this.setDisplayedDateText();
  }

  setDisplayedDateText() {
    switch (this.intervalType) {
      case CarousalDatePickerIntervalType.day:
        this.displayedDateText = this.datePipe.transform(this._selectedDate.value, EzDatePipeFormat.fullDate);
        break;
      case CarousalDatePickerIntervalType.week:
        //TODO
        this.displayedDateText = '';
        break;
      case CarousalDatePickerIntervalType.month:
        this.displayedDateText = this.datePipe.transform(this._selectedDate.value, EzDatePipeFormat.wideMonthFullYear);
        break;
    }
  }

  onDatePickerChange(date: Date) {
    this.selectedDate = date;
  }

  leftClicked() {
    const newDate = new Date(this.selectedDate);
    switch (this.intervalType) {
      case CarousalDatePickerIntervalType.day:
        newDate.setUTCDate(newDate.getUTCDate() - 1);
        break;
      case CarousalDatePickerIntervalType.week:
        //TODO - need design
        break;
      case CarousalDatePickerIntervalType.month:
        newDate.setMonth(newDate.getMonth() - 1);
        break;
    }

    //do not let the date go below the min date
    if (newDate.getTime() >= this.minDate.getTime()) {
      this.selectedDate = newDate;
    }
  }

  rightClicked() {
    switch (this.intervalType) {
      case CarousalDatePickerIntervalType.day:
        this.selectedDate = new Date(this.selectedDate.setUTCDate(this.selectedDate.getUTCDate() + 1));
        break;
      case CarousalDatePickerIntervalType.week:
        //TODO
        break;
      case CarousalDatePickerIntervalType.month:
        this.selectedDate = new Date(this.selectedDate.setMonth(this.selectedDate.getMonth() + 1));
        break;
    }
  }
}
