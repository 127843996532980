import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';

import * as appRouter from '@src/app/state/router-state';

import { DynamicMenuItem } from '../models/dynamic-menu-item';
import { EzMenuItemSeparator } from '../models/ez-menu-item-separator';
import { EzRouterLinkMenuItem } from '../models/ez-router-link-item';
import { UserSettingsService } from '@app-core/auth/user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicMenuItemService {

  dynamicItems$: BehaviorSubject<DynamicMenuItem[]> = new BehaviorSubject<DynamicMenuItem[]>([]);

  private _selectedDynamicItem$: BehaviorSubject<DynamicMenuItem> = new BehaviorSubject(null);
  selectedDynamicItem$: Observable<DynamicMenuItem> = this._selectedDynamicItem$.asObservable();

  constructor(
    private storeHelper: EzStoreService<appRouter.State>,
    private userSettingsService: UserSettingsService
  ) {
    this.storeHelper.stateStreamSelector(appRouter.getRouterFeatureState)
      .pipe(map(route => route.state.url))
      .subscribe((url: string) => {
        const items: DynamicMenuItem[] = this.getPageDropItems(url);
        this.setMenuItems(items);
      });
  }

  addMenuItems(menuItems: DynamicMenuItem[]) {
    const dynamicItems: DynamicMenuItem[] = this.dynamicItems$.getValue();

    this.dynamicItems$.next([...dynamicItems, ...menuItems]);
    this.dynamicItems$.getValue().sort(function (a, b) {
      return a.level - b.level;
    });
  }

  setMenuItems(menuItems: DynamicMenuItem[]) {
    this.dynamicItems$.next(menuItems);
  }

  removeMenuItem(data: DynamicMenuItem) {
    const dynamicItems: DynamicMenuItem[] = this.dynamicItems$.getValue();

    dynamicItems.forEach((item, index) => {
      if (item.label == data.label) {
        dynamicItems.splice(index, 1);
      }
    });

    this.dynamicItems$.next(dynamicItems);
  }

  setDefaultMenuItems() {
    this.dynamicItems$.next(this.getDefaultItems());
  }

  private getPageDropItems(url: string): DynamicMenuItem[] {
    // get page specific items for global dropdown.
    let items: DynamicMenuItem[] = this.getDefaultItems();
    let pageItems: DynamicMenuItem[] = [];
    switch (url) {
      case '/clients':
        pageItems = this.getItemsForClientsPage();
        break;
      case '/attendance':
        pageItems = this.getItemsForAttendancePage();
        break;
      case '/locker-rooms':
        pageItems = this.getItemsForLockerRoomsPage();
        break;
      default:
        break;
    }
    // items = [...items, new EzMenuItemSeparator(), ...pageItems, new EzMenuItemSeparator(), ...this.getSubSectionItems()];
    items = [...items, new EzMenuItemSeparator(), ...pageItems];
    return items;
  }

  // all code to handle showing side modals works, should just need to un comment
  // to add a new item, implement dynamic menu item component model on the component you need to add and set the side modal id


  private getItemsForClientsPage() {
    const items = [
      // new EzSideModalMenuItem('Create Group', (event) => this.loadDynamicSideModalItem(NewGroupSideModal, event.item, 'new-group-modal-id')),
      // new EzSideModalMenuItem('Create Lockers', (event) => this.loadDynamicSideModalItem(AddLockerRoomComponent, event.item, 'add-locker-room-modal-id')),
      // new EzSideModalMenuItem('Create Membership Type', (event) => this.loadDynamicSideModalItem(MembershipTypeSideModal, event.item, 'create-membership-type-side-modal')),
      // new EzSideModalMenuItem('Create Membership', (event) => this.loadDynamicSideModalItem(MembershipPlanSideModalComponent, event.item, 'membership-plan-side-modal')),
      // new EzSideModalMenuItem('Sell Membership', (event) => this.loadDynamicSideModalItem(SellMembershipSideModal, event.item, 'sell-membership-side-modal')),
      new EzRouterLinkMenuItem('Locker Rooms', '/locker-rooms')
    ];
    return items;
  }

  private getItemsForAttendancePage() {
    const items = [
      // new EzSideModalMenuItem('Create Client', (event) => this.loadDynamicSideModalItem(CreateClientSideModalComponent, event.item, 'create-client-side-modal')),
      // new EzSideModalMenuItem('Create Membership Type', (event) => this.loadDynamicSideModalItem(MembershipTypeSideModal, event.item, 'create-membership-type-side-modal')),
      // new EzSideModalMenuItem('Create Membership', (event) => this.loadDynamicSideModalItem(MembershipPlanSideModalComponent, event.item, 'membership-plan-side-modal')),
      // new EzSideModalMenuItem('Sell Membership', (event) => this.loadDynamicSideModalItem(SellMembershipSideModal, event.item, 'sell-membership-side-modal')),
      // new EzRouterLinkMenuItem('Locker Rooms', '/locker-rooms')
    ];
    return items;
  }

  private getItemsForLockerRoomsPage() {
    const items = [
      // new EzSideModalMenuItem('Create Client', (event) => this.loadDynamicSideModalItem(CreateClientComponent, event.item)),
      // new EzSideModalMenuItem('Create Client', (event) => this.loadDynamicSideModalItem(CreateClientSideModalComponent, event.item, 'create-client-side-modal')),
      // new EzCommandMenuItem('Test Command item', () => alert('Test command dynamic menu item!')),
      // new EzSideModalMenuItem('Create Group', (event) => this.loadDynamicSideModalItem(NewGroupSideModal, event.item, 'new-group-modal-id')),
      // new EzRouterLinkMenuItem('Groups', '/groups/groups-list', { 'adjustHeight': true }),
      // new EzSideModalMenuItem('Sell Membership', (event) => this.loadDynamicSideModalItem(SellMembershipSideModal, event.item, 'sell-membership-side-modal')),
      // new EzSideModalMenuItem('Create Membership', (event) => this.loadDynamicSideModalItem(MembershipPlanSideModalComponent, event.item, 'membership-plan-side-modal'))
    ];
    return items;
  }

  getDefaultItems() {
    const items = [];
    if (this.userSettingsService.userHasPermission('EditClients')) {
      items.push(...[
        // new EzRouterLinkMenuItem('Tasks', '/reports/client-activity-items-report'),
        new EzRouterLinkMenuItem('All Un-Invoiced Items', '/admin/billing/un-invoiced-items', { 'preset': 'AllCart', 'adjustHeight': true }),
        new EzRouterLinkMenuItem('My Un-Invoiced Items', '/admin/billing/un-invoiced-items', { 'preset': 'MyCart', 'adjustHeight': true })
      ]);
    }

    return items;
  }

  getSubSectionItems() {
    const items = [
      // new EzCommandMenuItem('Test SubSection Item', () => alert('Test command dynamic menu item!'), 'dynamic-menu-item-secondary'),
    ];
    return items;
  }

  loadDynamicSideModalItem(component: Type<any>, item: DynamicMenuItem, sideModalId?: string) {
    item.component = component;
    item.data = { modalId: sideModalId };
    this._selectedDynamicItem$.next(item);
  }
}
