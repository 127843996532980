import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, EMAIL_TEMPLATES_FEATURE_KEY } from './email-template-state.reducer';

// Selector functions
const getState = createFeatureSelector<State>(EMAIL_TEMPLATES_FEATURE_KEY);

export const getEmailTemplateCategories = createSelector(
  getState,
  state => state.emailTemplateCategories
);

export const getEmailTemplateSubCategories = createSelector(
  getState,
  state => state.emailTemplateSubCategories
);

export const getEmailTemplateSubCategoryTemplates = createSelector(
  getState,
  state => state.emailTemplateSubCategoryTemplates
);

export const getLocationEmailTemplates = createSelector(
  getState,
  state => state.templates
);

export const getLocationEmailTemplateData = createSelector(
  getState,
  state => state.locationEmailTemplate
);

export const getEmailTemplateHtmlSource = createSelector(
  getState,
  state => state.emailTemplateHtmlSource
);

export const createdEmailTemplateId = createSelector(
  getState,
  state => state.createdEmailTemplateId
);

export const isEmailTemplateNameInputVisible = createSelector(
  getState,
  state => state.isEmailTemplateNameInputVisible
);
