<div [ngClass]="{'p-dropdown p-widget p-state-default p-corner-all w-100':true}"
  (click)='!disabled ? opPopup.toggle($event) : false'>
  <input
    name='drpDwnInput'
    type="text"
    class="p-dropdown-label p-inputtext p-corner-all form-control"
    [style]='{ "background-color": "transparent" }'
    [attr.placeholder]="placeholder"
    [attr.value]='showedValue'
    [attr.readonly]='true'>
  <div class="p-dropdown-trigger p-state-default p-corner-right">
    <span class="p-dropdown-trigger-icon p-clickable"
      [ngClass]="'pi pi-chevron-down'"></span>
  </div>
</div>

<p-overlayPanel #opPopup
  appendTo='body'
  [style]="{'overflow-x': 'auto', 'max-height': maxHeightPopup + 'px', 'margin-left': '15px'}"
  [showTransitionOptions]="'200ms'"
  [hideTransitionOptions]="'200ms'"
  [showCloseIcon]="false">
  <ng-content></ng-content>
</p-overlayPanel>

<div #popupTarget></div>
