<div class="row">
  <div class="col">
    <h3 class="highlight-info mb-0 full-width-with-padding text-heading-3 heading-gray"
      [ngClass]="{'no-edit-padding': !showEdit}">
      {{title}}
      <span class="text-heading-3 link"
        [ngClass]="{'disabled': isEditMode || editDisabled, 'd-none': !showEdit}"
        [attr.data-cy]="'link-edit-panel' + (title ? cypressName(title) : '') "
        (click)="showEditFormOnEditClick ? showEditForm() : onEditLinkClick()">
        {{'Edit' | ezLocale}} {{title}}
      </span>
    </h3>

    <div *ngIf="panelNotification.message"
      class="toast-top-center toast-container">
      <div class="ng-trigger ng-trigger-flyInOut ngx-toastr toast-{{NotificationType[panelNotification.type]}}">
        <div class="toast-message">
          {{panelNotification.message}}
        </div>
      </div>
    </div>

    <div [hidden]='isEditMode'>
      <ng-content select="[display]"></ng-content>
    </div>
    <div [hidden]='!isEditMode'>
      <ng-content select="[edit]"></ng-content>
    </div>
  </div>
</div>
