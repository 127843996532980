<!-- Wrapper-->
<div id="wrapper"
  [ngClass]="{ 'side-modal-open': showSideModal$ | async, 'side-bar-opened': showSideBar$ | async}">
  <app-sidebar [isDesktopDevice]="isDesktopDevice$ | async"
    [isTabletDevice]="isTabletDevice$ | async"
    [isMobileDevice]="isMobileDevice$ | async">
  </app-sidebar>

  <!-- Top navigation -->
  <app-header [isMobileDevice]="isMobileDevice$ | async"></app-header>
  <!-- Main page wrapper -->
  <div id="page-wrapper"
    class="gray-bg"
    [ngClass]="{
      'no-wrapper-padding': noRouterOutletPadding,
      'framed-height': adjustHeight,
      'banners-with-survey': (isSurveyBannerIncluded$ | async) }"
      >
    <!-- Main view/routes wrapper-->
    <router-outlet></router-outlet>
  </div>
  <!-- End page wrapper-->
  <!-- Footer -->
  <app-footer [isMobileDevice]="isMobileDevice$ | async"
    [sideBarIsOpen]="showSideBar$ | async">
  </app-footer>

</div>
<!-- End wrapper-->
<ez-confirmation-modal></ez-confirmation-modal>
<app-important-message></app-important-message>
