import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EzNotificationService } from '@ezuiaws/ez-packages/ez-notification';
import { NotificationType } from '@app-autogen/ez.common/enums/notification-type';
import { LocalizationService } from '@app-core/localization/localization.service';
import { EmailTemplateStateFacade } from "@app-shared/state/email-templates-state/email-template-state.facade";

import * as EmailTemplateActions from './email-template-state.actions';

@Injectable()
export class EmailTemplateStateEffects {

  constructor(
    private actions$: Actions,
    private notificationService: EzNotificationService,
    private emailTemplateStateFacade: EmailTemplateStateFacade,
  ) {
  }

  addLocationEmailTemplateSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.addLocationEmailTemplateSuccess),
      tap(() => {
        this.emailTemplateStateFacade.hideEmailTemplateNameInput();
        this.emailTemplateStateFacade.getLocationEmailTemplates(false);
      }),
    ), { dispatch: false }
  );

  updateLocationEmailTemplateSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.updateLocationEmailTemplateSuccess),
      tap(() => {
        this.emailTemplateStateFacade.hideEmailTemplateNameInput();
        this.emailTemplateStateFacade.getLocationEmailTemplates(false);
      }),
    ), { dispatch: false }
  );

  updateLocationEmailTemplateFail = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.updateLocationEmailTemplateFail),
      tap(() => {
        this.notificationService.sendFailNotification({
          type: NotificationType.error,
          title: LocalizationService.rks.Failed,
          message: LocalizationService.rks.Failed
        });
      }),
    ), { dispatch: false }
  );

  deleteLocationEmailTemplateSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.deleteLocationEmailTemplateSuccess),
      tap(() => {
        this.notificationService.sendSuccessNotification({
          type: NotificationType.success,
          title: LocalizationService.rks.Success,
          message: LocalizationService.rks.TemplateDeleted
        });
        this.emailTemplateStateFacade.getLocationEmailTemplates(false);
      }),
    ), { dispatch: false }
  );
}
