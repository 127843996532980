import * as ClientAccessibleToUserActions from './client-accessible-to-user.actions';
import { ActionWithPayload } from '@ezuiaws/ez-packages/ez-socket';
import { createReducer, on } from '@ngrx/store';
import { GetClientIsAccessibleForUserResponse } from '@app-autogen/ez.client/contracts/response/get-client-is-accessible-for-user-response';

export const CLIENT_ACCESS_FEATURE_KEY = 'clientAccessibleToUser';

export interface State {
  clientIsAccessibleForUser: GetClientIsAccessibleForUserResponse
}
export interface ClientAccessibleToUserState {
  readonly [CLIENT_ACCESS_FEATURE_KEY]: State;
}

const initialState: State = {
  clientIsAccessibleForUser: undefined
};

const reducer = createReducer<State>(
  initialState,
  on(ClientAccessibleToUserActions.getClientIsAccessibleForUserSuccess, (state, { payload }) => {
    return {
      ...state,
      clientIsAccessibleForUser: payload
    };
  }),
  on(ClientAccessibleToUserActions.clearClientIsAccessibleForUser, (state) => {
    return {
      ...state,
      clientIsAccessibleForUser: initialState.clientIsAccessibleForUser
    };
  }
  ),
);

export function ClientAccessibleToUserReducer(state: State | undefined, action: ActionWithPayload) {
  return reducer(state, action);
}

