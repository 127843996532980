import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import * as appRouter from '@src/app/state/router-state';
import * as fromTheme from '@app-shared/layout/theme-switcher/state';
import * as themeActions from '@app-shared/layout/theme-switcher/state/theme.actions';

import { UserSettingsService } from '@app-core/auth/user-settings.service';
import { LocalizationService } from '@app-core/localization/localization.service';
import { LocationSettingsService } from '@app-core/auth/location-settings.service';
import { AuthService } from '@app-core/auth/auth.service';

import { EzMenuResponseModel } from '@app-root/admin-module/models/ez-menu-response-model';
import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';
import { LocationModulesEnum } from '@app-core/auth/location-modules-enum';
import { GetCloverMerchantIsSetUpResponse } from '@app-autogen/ez.common/contracts/response/get-clover-merchant-is-set-up-response';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  // Implemented BehaviorSubject to receive an observable which we are able to use once will be fetching the results from the server asynchronously
  // So the code in the components that are using this service won't have to change radically.
  private adminSubject: BehaviorSubject<EzMenuResponseModel[]> = new BehaviorSubject<EzMenuResponseModel[]>([]);
  private environment: string = this.authService.getEnvironment();

  administrationItems$: Observable<EzMenuResponseModel[]> = this.adminSubject.asObservable()
    .pipe(
      filter(adminItems => adminItems && adminItems.length > 0),
    );

  routerUrl: string = '';
  hasCloverMerchantSetUp$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasCloverMerchantSetUp: boolean = undefined;

  constructor(
    private userSettingsService: UserSettingsService,
    private locationSettingsService: LocationSettingsService,
    private storeHelper: EzStoreService<appRouter.State>,
    private themeStoreHelper: EzStoreService<fromTheme.State>,
    private authService: AuthService
  ) {

    this.showCloverAuthentication();
    this.storeHelper.stateStreamSelector(appRouter.getRouterFeatureState).pipe(
      map(route => route.state.url),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((url: string) => {
      this.routerUrl = url;
    });
  }

  showCloverAuthentication() {
    this.themeStoreHelper.dispatchAction(themeActions.getCloverMerchantIsSetUp());
    this.themeStoreHelper.stateStreamSelector(fromTheme.getCloverMerchantIsSetUp)
      .pipe(
        filter(result => result != undefined),
        untilDestroyed(this)
      )
      .subscribe((result: GetCloverMerchantIsSetUpResponse) => {
        this.hasCloverMerchantSetUp = result.hasCloverMerchant;
        this.hasCloverMerchantSetUp$.next(result.hasCloverMerchant);
        this.getAdministrationItems();
      });
  }

  public getAdministrationItems(): EzMenuResponseModel[] {
    const membershipsSubItems: EzMenuResponseModel[] = this.getMembershipsSubItems();
    const packagesSubItems: EzMenuResponseModel[] = this.getPackagesSubItems();
    const administrationItems: EzMenuResponseModel[] = [];

    if (membershipsSubItems.length) {
      administrationItems.push(...[
        {
          label: LocalizationService.rks.Memberships,
          styleClass: 'is-subitem',
          items: membershipsSubItems,
          expanded: this.routerUrl.indexOf('/admin/membership') >= 0,
        }
      ]);
    }

    if (packagesSubItems.length) {
      administrationItems.push(...[
        {
          label: LocalizationService.rks.Packages,
          styleClass: 'is-subitem',
          items: packagesSubItems,
          expanded: this.routerUrl.indexOf('/admin/packages') >= 0,
        }
      ]);
    }

    if (this.userSettingsService.userHasPermission('EmailBlast') && !this.locationSettingsService.locationHasModule(LocationModulesEnum.HasNewEmailCampaign)) {
      administrationItems.push(...[
        { label: LocalizationService.rks.EmailCampaign, description: LocalizationService.rks.AdminEmailCampaignDescription, styleClass: 'is-subitem', routerLink: ['/admin/emails/campaign-history'] }
      ]);

      administrationItems.push(...[
        { label: LocalizationService.rks.ImageLibrary, description: LocalizationService.rks.AdminImageLibraryDescription, styleClass: 'is-subitem', routerLink: ['/admin/image-library'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasOnlineRegistrations) && this.userSettingsService.userHasPermission('AccountAdministration')) {
      administrationItems.push(...[
        { label: LocalizationService.rks.OnlineRegistration, description: LocalizationService.rks.AdminOnlineRegistrationDescription, styleClass: 'is-subitem', routerLink: ['/admin/online-registration'] }
      ]);
    }

    if (this.locationSettingsService.getLocationPreferenceBooleanValue('EnableDocuments')
      && (this.userSettingsService.userHasPermission('Create/EditDocumentTemplates')
        || this.userSettingsService.userHasPermission('GenerateDocument')
        || this.userSettingsService.userHasPermission('ViewDocumentHistory'))) {

      const documentSubItems: EzMenuResponseModel[] = [];
      if (this.userSettingsService.userHasPermission('ViewDocumentHistory')
        || this.userSettingsService.userHasPermission('GenerateDocument')) {

        documentSubItems.push(...[
          { label: LocalizationService.rks.DocumentHistory, description: LocalizationService.rks.AdminDocumentHistoryDescription, styleClass: 'is-subitem', routerLink: ['/admin/documents/document-history'], queryParams: { 'adjustHeight': true } }
        ]);
      }

      if (this.userSettingsService.userHasPermission('Create/EditDocumentTemplates')
        || this.userSettingsService.userHasPermission('GenerateDocument')) {

        documentSubItems.push(...[
          { label: LocalizationService.rks.DocumentTemplates, description: LocalizationService.rks.AdminDocumentTemplatesDescription, styleClass: 'is-subitem', routerLink: ['/admin/documents/document-templates'] }
        ]);
      }
      administrationItems.push(...[
        {
          label: LocalizationService.rks.Documents,
          styleClass: 'is-subitem',
          items: documentSubItems,
          expanded: this.routerUrl.indexOf('/admin/documents') >= 0,
        }
      ]);
    }

    const billingSubItems: EzMenuResponseModel[] = this.getBillingSubItems();
    if (billingSubItems.length) {
      administrationItems.push(...[
        {
          label: LocalizationService.rks.Billing,
          styleClass: 'is-subitem',
          items: billingSubItems,
          expanded: this.routerUrl.indexOf('/admin/billing') >= 0,
        }
      ]);
    }

    if (this.userSettingsService.userHasPermission('AccountAdministration')
      && this.locationSettingsService.locationHasModule(LocationModulesEnum.HasTimeClock)) {
      administrationItems.push(...[
        {
          label: LocalizationService.rks.TimeClock,
          styleClass: 'is-subitem',
          items: [
            { label: LocalizationService.rks.HourlyRate, description: LocalizationService.rks.AdminHourlyRateDescription, styleClass: 'is-subitem', routerLink: ['/admin/time-clock/hourly-rate'], queryParams: { 'adjustHeight': true } },
            { label: LocalizationService.rks.IpRestriction, description: LocalizationService.rks.AdminIpRestrictionDescription, styleClass: 'is-subitem', routerLink: ['/admin/time-clock/ip-restriction'], queryParams: { 'adjustHeight': true } },
            { label: LocalizationService.rks.JobClassifications, description: LocalizationService.rks.AdminJobClassificationsDescription, styleClass: 'is-subitem', routerLink: ['/admin/time-clock/job-classification'], queryParams: { 'adjustHeight': true } },
          ],
          expanded: this.routerUrl.indexOf('/admin/time-clock') >= 0,
        }
      ]);
    }

    if (this.userSettingsService.userHasPermission('AccountAdministration')
      && this.locationSettingsService.getLocationPreferenceBooleanValue('MasterEnableSelfService')) {
      administrationItems.push(...[
        {
          label: LocalizationService.rks.AdminSelfService,
          styleClass: 'is-subitem',
          items: [
            { label: LocalizationService.rks.Preferences, description: LocalizationService.rks.AdminPreferencesDescription, styleClass: 'is-subitem', routerLink: ['/admin/self-service/self-service-preferences'], queryParams: { 'adjustHeight': true } },
            { label: LocalizationService.rks.Design, description: LocalizationService.rks.AdminDesignDescription, styleClass: 'is-subitem', routerLink: ['/admin/self-service/self-service-design'], queryParams: { 'adjustHeight': true } },
            { label: LocalizationService.rks.VideoLibrary, description: LocalizationService.rks.AdminVideoLibraryDescription, styleClass: 'is-subitem', routerLink: ['/admin/self-service/video-library'], queryParams: { 'adjustHeight': true } },
          ],
          expanded: this.routerUrl.indexOf('/admin/self-service') >= 0,
        }
      ]);
    }

    const clientSubItems: EzMenuResponseModel[] = this.getClientSubItems();
    if (clientSubItems && clientSubItems.length) {
      administrationItems.push(...[
        {
          label: LocalizationService.rks.ClientSettings,
          styleClass: 'is-subitem',
          items: clientSubItems,
          expanded: this.routerUrl.indexOf('/admin/client-settings') >= 0,
        }
      ]);
    }

    if ((this.locationSettingsService.locationHasModule(LocationModulesEnum.HasPOS) || this.locationSettingsService.locationHasModule(LocationModulesEnum.HasPOSv2))
      && this.userSettingsService.userHasPermission('POSAdministration')) {
      administrationItems.push(...[
        {
          label: LocalizationService.rks.POSSettings,
          styleClass: 'is-subitem',
          items: [
            { label: LocalizationService.rks.CashRegisters, description: LocalizationService.rks.AdminCashRegistersDescription, styleClass: 'is-subitem', routerLink: ['/admin/pos-settings/cash-registers'], queryParams: { 'adjustHeight': true } },
            { label: LocalizationService.rks.AdminCashRegisterFavorites, description: LocalizationService.rks.AdminCashRegisterFavoritesDescription, styleClass: 'is-subitem', routerLink: ['/admin/pos-settings/cash-register-favorites'], queryParams: { 'adjustHeight': true } },
            { label: LocalizationService.rks.Brands, description: LocalizationService.rks.AdminBrandsDescription, styleClass: 'is-subitem', routerLink: ['/admin/pos-settings/brands'], queryParams: { 'adjustHeight': true } },
            { label: LocalizationService.rks.ProductCategories, description: LocalizationService.rks.ProductCategories, styleClass: 'is-subitem', routerLink: ['/admin/pos-settings/product-categories'], queryParams: { 'adjustHeight': true } },
            { label: LocalizationService.rks.ProductSuppliers, description: LocalizationService.rks.AdminProductSuppliersDescription, styleClass: 'is-subitem', routerLink: ['/admin/pos-settings/product-suppliers'], queryParams: { 'adjustHeight': true } },
          ],
          expanded: this.routerUrl.indexOf('/admin/pos-settings') >= 0,
        }
      ]);
    }

    const locationSettingsItems: EzMenuResponseModel[] = this.getLocationSettingsItems();

    if (locationSettingsItems.length) {
      administrationItems.push(...[
        {
          label: LocalizationService.rks.LocationSettings,
          styleClass: 'is-subitem',
          items: locationSettingsItems,
          expanded: this.routerUrl.indexOf('/admin/location-settings') >= 0,
        }
      ]);
    }
    this.adminSubject.next(administrationItems);
    return administrationItems;
  }

  private getMembershipsSubItems(): EzMenuResponseModel[] {
    const membershipsSubItems: EzMenuResponseModel[] = [];

    //will we need membership agreements page later?
    // let membershipsSubItems = [{ label: 'Agreement', styleClass: 'is-subitem', routerLink: ['/admin/memberships/plans'] }];

    if (this.userSettingsService.userHasPermission('MembershipAdministration')) {
      if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasAdvancedMemberships)) {
        membershipsSubItems.push(...[
          { label: LocalizationService.rks.Plans, description: LocalizationService.rks.AdminMembershipsPlansDescription, styleClass: 'is-subitem', routerLink: ['/admin/memberships/plans'] }
        ]);
      }

      //will we need membership preferences page later?
      // { label: 'Preferences', styleClass: 'is-subitem', routerLink: ['/admin/memberships/plans'] },

      membershipsSubItems.push(...[
        { label: LocalizationService.rks.MembershipTypes, description: LocalizationService.rks.AdminMembershipsTypesDescription, styleClass: 'is-subitem', routerLink: ['/admin/memberships/types'] }
      ]);

    }
    return membershipsSubItems;
  }

  private getPackagesSubItems(): EzMenuResponseModel[] {
    const packagesSubItems: EzMenuResponseModel[] = [];

    if (this.userSettingsService.userHasPermission('AccountAdministration')) {
      packagesSubItems.push(...[
        { label: LocalizationService.rks.Plans, description: LocalizationService.rks.AdminPackagesPlansDescription, styleClass: 'is-subitem', routerLink: ['/admin/packages/plans'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('FullAccessReservationType') ||
      this.userSettingsService.userHasPermission('LimitedAccessReservationType') ||
      this.userSettingsService.userHasPermission('ViewOnlyAccessReservationType')) {
      // Temporary added production check until feature is ready to be released
      const isProduction: boolean = this.environment !== 'Development' && this.environment !== 'QA';
      packagesSubItems.push(...[
        { label: LocalizationService.rks.ReservationTypes, description: LocalizationService.rks.AdminReservationTypesDescription, styleClass: 'is-subitem', routerLink: ['/admin/packages/reservation-types'], queryParams: isProduction ? { 'adjustHeight': true } : null }
      ]);
    }
    return packagesSubItems;
  }

  private getBillingSubItems(): EzMenuResponseModel[] {
    const billingSubItems: EzMenuResponseModel[] = [];

    if (this.userSettingsService.userHasPermission('AccountAdministration')) {
      billingSubItems.push(...[
        { label: LocalizationService.rks.BillingPreferences, description: LocalizationService.rks.AdminBillingPreferencesDescription, styleClass: 'is-subitem', routerLink: ['/admin/billing/billing-preferences'], queryParams: { 'adjustHeight': true } },
        { label: LocalizationService.rks.RevenueCategories, description: LocalizationService.rks.AdminRevenueCategoriesDescription, styleClass: 'is-subitem', routerLink: ['/admin/billing/revenue-categories'], queryParams: { 'adjustHeight': true } },
        { label: LocalizationService.rks.Taxes, description: LocalizationService.rks.AdminTaxesDescription, styleClass: 'is-subitem', routerLink: ['/admin/billing/taxes'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('ManageCoupons')) {
      billingSubItems.push(...[
        { label: LocalizationService.rks.Coupons, description: LocalizationService.rks.CouponsDescription, styleClass: 'is-subitem', routerLink: ['/admin/billing/coupons'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('EditClients')) {
      billingSubItems.push(...[
        { label: LocalizationService.rks.AdminUnInvoicedItemsUser, description: LocalizationService.rks.AdminUnInvoicedItemsUserDescription, styleClass: 'is-subitem', routerLink: ['/admin/billing/un-invoiced-items'], queryParams: { 'preset': 'MyCart', 'adjustHeight': true } },
        {
          label: LocalizationService.rks.AdminUnInvoicedItemsLocation,
          description: LocalizationService.rks.AdminUnInvoicedItemsLocationDescription,
          styleClass: 'is-subitem',
          routerLink: ['/admin/billing/un-invoiced-items'],
          queryParams: { 'preset': 'AllCart', 'adjustHeight': true }
        },
        { label: LocalizationService.rks.ReviewPostBilling, description: LocalizationService.rks.AdminReviewPostBillingDescription, styleClass: 'is-subitem', routerLink: ['/admin/billing/review-post-billing'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    return billingSubItems;
  }

  private getClientSubItems(): EzMenuResponseModel[] {
    const clientSubItems: EzMenuResponseModel[] = [];

    if (this.userSettingsService.userHasPermission('AccountAdministration')
      || this.userSettingsService.userHasPermission('EditLocation')) {
      clientSubItems.push(...[
        { label: LocalizationService.rks.ContactPreferences, description: LocalizationService.rks.AdminContactPreferencesDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/contact-preferences'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('AccountAdministration')) {
      clientSubItems.push(...[
        { label: LocalizationService.rks.ClientCategories, description: LocalizationService.rks.AdminClientCategoriesDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/client-categories'], queryParams: { 'adjustHeight': true } },
        { label: LocalizationService.rks.AssessmentCategories, description: LocalizationService.rks.AdminAssessmentCategoriesDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/assessment-categories'], queryParams: { 'adjustHeight': true } },
        { label: LocalizationService.rks.AccessControl, description: LocalizationService.rks.AdminAccessControlDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/access-control'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('AccountAdministration')
      && this.locationSettingsService.getLocationPreferenceBooleanValue('EnableEmailNotifications')) {
      clientSubItems.push(...[
        { label: LocalizationService.rks.EmailAlerts, description: LocalizationService.rks.AdminEmailAlertsDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/email-alerts'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('AccountAdministration')
      && this.locationSettingsService.locationHasModule(LocationModulesEnum.HasCheckIn)) {
      clientSubItems.push(...[
        { label: LocalizationService.rks.AdminCheckInStations, description: LocalizationService.rks.AdminCheckInStationsDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/check-in-stations'], queryParams: { 'adjustHeight': true } },
        { label: LocalizationService.rks.StaffCheckIn, description: LocalizationService.rks.AdminStaffCheckInDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/staff-check-in'], queryParams: { 'adjustHeight': true } },
        { label: LocalizationService.rks.SelfCheckIn, description: LocalizationService.rks.AdminSelfCheckInDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/self-check-in'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('ExportClients')) {
      clientSubItems.push(...[
        { label: LocalizationService.rks.ExportClients, description: LocalizationService.rks.AdminExportClientsDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/export-clients'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('AccountAdministration')) {
      clientSubItems.push(...[
        { label: LocalizationService.rks.ReferralCategories, description: LocalizationService.rks.AdminReferralCategoriesDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/referral-categories'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('CreateIDCard')
      && this.locationSettingsService.locationHasModule(LocationModulesEnum.HasPhotoID)) {
      clientSubItems.push(...[
        { label: LocalizationService.rks.ClientIdCard, description: LocalizationService.rks.AdminClientIdCardDescription, styleClass: 'is-subitem', routerLink: ['/admin/client-settings/client-id-card'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    return clientSubItems;
  }

  private getLocationSettingsItems(): EzMenuResponseModel[] {
    const locationSettingsItems: EzMenuResponseModel[] = [];

    if (this.userSettingsService.userHasPermission('AccountAdministration')) {
      locationSettingsItems.push(...[
        { label: LocalizationService.rks.Users, description: LocalizationService.rks.AdminUsersDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/users'], queryParams: { 'adjustHeight': true } },
        { label: LocalizationService.rks.Roles, description: LocalizationService.rks.AdminRolesDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/users/roles'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('EditLocation')) {
      locationSettingsItems.push(...[
        { label: LocalizationService.rks.LocationSetup, description: LocalizationService.rks.AdminLocationSetupDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/location-setup'], queryParams: { 'adjustHeight': true } },
        { label: LocalizationService.rks.ResourceSetup, description: LocalizationService.rks.AdminResourceSetupDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/resource-setup'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('AccountAdministration') && this.userSettingsService.userHasPermission('AccessReservationCategories')) {
      locationSettingsItems.push(...[
        { label: 'Reservation Categories', description: 'Add/modify/disable reservation categories', styleClass: 'is-subitem', routerLink: ['/admin/location-settings/reservation-categories'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('AccountAdministration')) {
      locationSettingsItems.push(...[
        { label: LocalizationService.rks.PreDefinedItems, description: LocalizationService.rks.AdminPredefinedItemsDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/predefined-items'], queryParams: { 'adjustHeight': true } },
        { label: LocalizationService.rks.RentalRates, description: LocalizationService.rks.AdminRentalRatesDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/rental-rates'], queryParams: { 'adjustHeight': true } },
        { label: LocalizationService.rks.RentalTypes, description: LocalizationService.rks.AdminRentalTypesDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/rental-types'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasEquipment) && this.userSettingsService.userHasPermission('AccountAdministration')) {
      locationSettingsItems.push(...[
        { label: LocalizationService.rks.Equipment, description: LocalizationService.rks.AdminEquipmentDescription, styleClass: 'is-subitem', routerLink: ['/admin/equipment'] }
      ]);
    }



    // TODO - in addition to requiring the ManagePaymentTerminal user permission,
    //determine if EMV Terminals menu option should show, EZ-13675
    if (this.userSettingsService.userHasPermission('ManagePaymentTerminal')) {
      locationSettingsItems.push(...[
        { label: LocalizationService.rks.AdminEmvTerminals, description: LocalizationService.rks.AdminEmailAlertsDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/emv-terminals'], queryParams: { 'adjustHeight': true } },
      ]);
    }
    if (this.hasCloverMerchantSetUp && this.userSettingsService.userHasPermission('AccountAdministration')) {
      locationSettingsItems.push(...[
        { label: LocalizationService.rks.AdminCloverAuthentication, description: LocalizationService.rks.AdminCloverAuthenticationDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/clover-authentication'], queryParams: { 'adjustHeight': true } },
      ]);
    }

    if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasSmartwaiver)) {
      locationSettingsItems.push(...[
        { label: LocalizationService.rks.AdminSmartwaiver, description: LocalizationService.rks.AdminSmartwaiverDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/smartwaiver'], queryParams: { 'adjustHeight': true } }

      ]);
    }

    if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasQuickbooksOnline)
      && this.userSettingsService.userHasPermission('QuickBooksOnline')) {
      locationSettingsItems.push(...[
        { label: LocalizationService.rks.QuickBooksOnline, description: LocalizationService.rks.AdminQuickBooksOnlineDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/qbo'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    if (this.userSettingsService.userHasPermission('AccountAdministration')) {

      if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasZoom)) {
        locationSettingsItems.push(...[
          { label: LocalizationService.rks.Zoom, description: LocalizationService.rks.AdminZoomDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/zoom'], queryParams: { 'adjustHeight': true } }
        ]);
      }

      locationSettingsItems.push(...[
        { label: LocalizationService.rks.ProgramFees, description: LocalizationService.rks.AdminProgramFeesDescription, styleClass: 'is-subitem', routerLink: ['/admin/location-settings/program-fees'], queryParams: { 'adjustHeight': true } }
      ]);

      if (this.locationSettingsService.getLocationPreferenceBooleanValue('MasterEnableSelfService')) {
        locationSettingsItems.push(...[
          {
            label: LocalizationService.rks.AdminMemberMe,
            description: LocalizationService.rks.AdminMemberMeDescription,
            styleClass: 'is-subitem',
            url: 'https://admin.migym.com/login', target: '_blank'
          }
        ]);
      }
    }

    return locationSettingsItems;
  }
}
