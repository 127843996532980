import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { appversion } from '@src/environments/appversion';

import { TmsMessageService } from '@app-shared/services/tms-message.service';

import { ConnectionState } from '@ssv/signalr-client';
import { AuthService } from '@app-core/auth/auth.service';
import { LocalizationService } from '@app-core/localization/localization.service';
import { ReportsAuthService } from '@app-core/auth/reports-auth.service';
import { UserSettingsService } from '@app-core/auth/user-settings.service';
import { LocationSettingsService } from '@app-core/auth/location-settings.service';
import { LocationModulesEnum } from '@app-core/auth/location-modules-enum';
import { AdministrationService } from '@app-shared/services/administration.service';
import { SharedStateFacade } from '@app-root/state/shared-state/shared-state.facade';

import { TmsIframeComponent } from '../tms-iframe/tms-iframe.component';

import * as appRouter from '@app-root/state/router-state';
import * as fromTheme from '@app-shared/layout/theme-switcher/state';
import * as themeActions from '@app-shared/layout/theme-switcher/state/theme.actions';

import { EzSocketService } from '@ezuiaws/ez-packages/ez-socket';
import { EZLayoutService } from '@ezuiaws/ez-packages/ez-layout';
import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';

import { EZLeaguesUserResponse } from '@app-autogen/ez.ezleagues/contracts/response/ez-leagues-user-response';
import { TmsMessage } from '@app-autogen/ez.common/contracts/reference-objects/tms-message';
import { TmsMessageType } from '@app-autogen/ez.common/contracts/reference-objects/tms-message-type';
import { SessionResponse } from '@app-autogen/ez.authentication/contracts/response/session-response';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {

  @ViewChild('tmsIframeSelfCheckin') tmsIframeSelfCheckin: TmsIframeComponent;

  @Input() isDesktopDevice: boolean = false;
  @Input() isTabletDevice: boolean = false;
  @Input() isMobileDevice: boolean = false;

  showSideBar$: Observable<boolean> = this.layoutService.showSidebar$;

  environment: string = this.authService.getEnvironment();

  build: string = '';
  connectionState: string = 'red';

  navigationMenu$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);

  sessionUser: SessionResponse = this.userSettingsService.userSession;

  routerUrl: string = '';
  viewDatebook = false;
  hasDirectDebitMerchantStreaming: boolean = undefined;
  ezlUser: EZLeaguesUserResponse = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private tmsMessageService: TmsMessageService,
    private router: Router,
    private layoutService: EZLayoutService,
    private storeHelper: EzStoreService<appRouter.State>,
    private ezSocketService: EzSocketService,
    private reportsAuthService: ReportsAuthService,
    private userSettingsService: UserSettingsService,
    private locationSettingsService: LocationSettingsService,
    private themeStoreHelper: EzStoreService<fromTheme.State>,
    private administrationService: AdministrationService,
    private sharedStateFacade: SharedStateFacade
  ) { }

  ngOnInit() {
    this.administrationService.showCloverAuthentication();
    this.setupNavigationItems();
    this.showDirectDebitBatches();
    this.getEZLeaguesCredentials();

    this.ezSocketService.getConnectionState().pipe(
      untilDestroyed(this),
      map((connectionState: ConnectionState) => {

        if (connectionState.status !== 'connected') {
          this.connectionState = 'red-dot';
        } else {
          this.connectionState = 'green-dot';
        }

        // Force the dot to change if any state change is detected.
        this.cdr.detectChanges();
      })
    ).subscribe();

    this.tmsMessageService.tmsMessages$.pipe(
      untilDestroyed(this),
    ).subscribe((msg: TmsMessage) => {

      let rebuildNavigationItems: boolean = false;

      if (msg.MessageType === TmsMessageType.ScheduleDatebook
        || msg.MessageType === TmsMessageType.ScheduleEzView) {
        this.viewDatebook = msg.MessageType === TmsMessageType.ScheduleDatebook;
        rebuildNavigationItems = true;
      }

      if (msg.MessageType === TmsMessageType.StaffCheckInNavigateToClientProfile || msg.MessageType === TmsMessageType.GotoClientDetails) {
        void this.router.navigateByUrl(`/clients/client/profile/${msg.Message}`);
        rebuildNavigationItems = true;
      }

      if (rebuildNavigationItems) {
        this.setupNavigationItems();
      }
    });

    this.storeHelper.stateStreamSelector(appRouter.getRouterFeatureState).pipe(
      map(route => route.state.url),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((url: string) => {
      this.routerUrl = url;
      this.setupNavigationItems();
    });

    this.administrationService.hasCloverMerchantSetUp$.pipe(
      untilDestroyed(this),
    ).subscribe((result: boolean) => {
      this.setupNavigationItems();
    });
  }

  toggleMenu() {
    this.layoutService.toggleSidebar();
  }

  getEZLeaguesCredentials() {

    this.sharedStateFacade.ezleaguesUser$.pipe(
      untilDestroyed(this)
    ).subscribe((result: EZLeaguesUserResponse) => {
      this.ezlUser = result;
      this.setupNavigationItems();
    });

  }

  showDirectDebitBatches() {
    this.themeStoreHelper.dispatchAction(themeActions.getDirectDebitMerchantIsStreaming());
    this.themeStoreHelper.stateStreamSelector(fromTheme.getDirectDebitMerchantIsStreaming)
      .pipe(
        filter(result => result != undefined),
        untilDestroyed(this)
      )
      .subscribe((result: boolean) => {
        this.hasDirectDebitMerchantStreaming = result;
        this.getBillingHasDirectMerchant();
      });
  }

  private setupNavigationItems() {
    const navigationMenu: MenuItem[] = [
      // { label: 'Dashboard', icon: 'pi pi-chart-bar', routerLink: ['/dashboard'] }
    ];

    if (this.userSettingsService.userHasPermission('ViewSchedule')) {
      const noPaddingQueryParams: boolean = !(this.environment === 'Development' || this.environment === 'QA');
      const expanded: boolean = this.routerUrl.indexOf('/schedule') >= 0
        && this.routerUrl.indexOf('clients/client/schedule') === -1
        && this.routerUrl.indexOf('email-campaigns/campaigns/scheduled') === -1;
      navigationMenu.push(...[
        {
          label: LocalizationService.rks.Schedule,
          icon: 'pi pi-calendar-times',
          styleClass: 'has-submenu',
          routerLink: ['/schedule'],
          queryParams: noPaddingQueryParams ? { 'noPadding': true } : null,
          expanded: expanded,
          items: this.getScheduleSubItems()
        }
      ]);
    }

    if (this.userSettingsService.userHasPermission('ViewClientList')) {
      navigationMenu.push(...this.getClientsSubItems());
    }

    const canSeeNewEmailCampaign: boolean = this.locationSettingsService.locationHasModule(LocationModulesEnum.HasNewEmailCampaign) &&
      (this.userSettingsService.userHasPermission('LimitedAccessEmailCampaign') || this.userSettingsService.userHasPermission('FullAccessEmailCampaign') || this.userSettingsService.userHasPermission('ViewOnlyAccessEmailCampaign'));
    if (canSeeNewEmailCampaign) {
      navigationMenu.push(...this.getEmailCampaignsItems());
    }

    if (this.userSettingsService.userHasPermission('ViewGroups')) {
      navigationMenu.push(...this.getGroupsItems());
    }

    if (this.userSettingsService.userHasPermission('MarkMyAttendance')
      || this.userSettingsService.userHasPermission('MarkNoShow')
      || this.userSettingsService.userHasPermission('MarkEarlyCancel')
      || this.userSettingsService.userHasPermission('MarkLateCancel')
      || this.userSettingsService.userHasPermission('MarkLateCancelPayTrainer')
      || this.userSettingsService.userHasPermission('MarkReserved')
      || this.userSettingsService.userHasPermission('MarkWaitlist')) {
      navigationMenu.push(...[
        { label: LocalizationService.rks.Attendance, icon: 'pi pi-check', routerLink: ['/attendance'] }
      ]);
    }

    if ((this.locationSettingsService.locationHasModule(LocationModulesEnum.HasPOS) || this.locationSettingsService.locationHasModule(LocationModulesEnum.HasPOSv2))
      && this.userSettingsService.userHasPermission('OperatePOS')) {
      navigationMenu.push(...[
        { label: LocalizationService.rks.POS, icon: 'pi pi-money-bill', url: this.authService.uiWebOptions.PointOfSaleURL + '/login', target: '_blank' },
      ]);
    }

    if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasCheckIn)
      && this.userSettingsService.userHasPermission('CheckinMode')) {

      const includeCheckInDashboard: boolean = this.locationSettingsService.getLocationPreferenceBooleanValue('EnableCheckout');
      const checkInNavigationItem = this.getCheckInNavigationItems(includeCheckInDashboard, this.sessionUser.selfCheckinAuthorizationToken);
      navigationMenu.push(...checkInNavigationItem);
    }

    if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasTimeClock)
      && this.userSettingsService.userHasPermission('AccessTimeClock')) {
      navigationMenu.push(...[
        { label: LocalizationService.rks.TimeClock, icon: 'pi pi-clock', routerLink: ['/timeclock'] }
      ]);
    }

    if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasLockers)
      && this.userSettingsService.userHasPermission('OperateLockerRooms')
      && (this.environment === 'Development' || this.environment === 'QA')) {
      navigationMenu.push(...[
        { label: LocalizationService.rks.Lockers, icon: 'pi pi-lock', routerLink: ['/locker-rooms'] }
      ]);
    }

    if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasEquipment)
      && this.userSettingsService.userHasPermission('OperateEquipment')) {
      navigationMenu.push(...[
        { label: LocalizationService.rks.Equipment, icon: 'pi pi-tags', routerLink: ['/equipment'] }
      ]);
    }

    if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasEZleagues)) {
      navigationMenu.push(...[
        {
          label: LocalizationService.rks.EZLeaguesNoSpacing,
          icon: 'pi pi-circle-off',
          routerLink: null,
          command: () => {
            this.authService.openEZLInNewWindow('/admin/administration.aspx', this.ezlUser);
          }
        }
      ]);
    }

    const reportItems = this.getReportItems();

    if (reportItems.length) {
      navigationMenu.push(...[
        {
          label: LocalizationService.rks.Reports,
          icon: 'pi pi-table',
          styleClass: 'has-submenu',
          routerLink: ['/reports'],
          items: reportItems,
          expanded: this.routerUrl.indexOf('/reports') >= 0,
        }
      ]);
    }

    if (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasSmuRegistrations)) {
      navigationMenu.push(...[
        { label: LocalizationService.rks.EZSignUp, icon: 'pi pi-book', routerLink: ['/ezsignup'] }
      ]);
    }

    // Temporary removed RegEngine link from sidebar due to task #29586
    // if (this.environment === 'Development' || this.environment === 'QA') {
    //   navigationMenu.push(...[
    //     { label: LocalizationService.rks.RegEngine, icon: 'pi pi-bolt', routerLink: ['/reg-engine'] }
    //   ]);
    // }

    const administrationItems = this.administrationService.getAdministrationItems();

    if (administrationItems.length) {
      const expanded: boolean = this.routerUrl.indexOf('/admin') >= 0;
      navigationMenu.push(...[
        {
          label: LocalizationService.rks.Administration,
          icon: 'pi pi-cog',
          routerLink: ['/admin'],
          styleClass: 'has-submenu',
          expanded: expanded,
          items: administrationItems
        }
      ]);
    }

    if (this.sessionUser.adminLevel >= 100 || this.environment === 'Development') {
      navigationMenu.push(...[
        { label: LocalizationService.rks.Support, icon: 'pi pi-info-circle', routerLink: ['/support'] }
      ]);
    }

    if (this.environment === 'Development') {
      navigationMenu.push(...[
        { label: LocalizationService.rks.Chat, icon: 'pi pi-comments', routerLink: ['/chat'] },
      ]);
    }

    this.navigationMenu$.next(navigationMenu);
    this.build = appversion.build;
  }

  private getScheduleSubItems(): MenuItem[] {
    const view = this.viewDatebook ? 'datebook' : 'ez-view';
    const expanded: boolean = this.routerUrl.indexOf('/schedule') >= 0;
    const items: MenuItem[] = [
      { label: LocalizationService.rks.Day, styleClass: 'is-subitem', routerLink: ['/schedule'], queryParams: { 'view': view, 'mode': 'day', 'noPadding': true }, visible: expanded },
      { label: LocalizationService.rks.Week, styleClass: 'is-subitem', routerLink: ['/schedule'], queryParams: { 'view': view, 'mode': 'week', 'noPadding': true }, visible: expanded },
      { label: LocalizationService.rks.Month, styleClass: 'is-subitem', routerLink: ['/schedule'], queryParams: { 'view': view, 'mode': 'month', 'noPadding': true }, visible: expanded },
      { label: this.viewDatebook ? LocalizationService.rks.EZView : LocalizationService.rks.Datebook, styleClass: 'is-subitem', routerLink: ['/schedule'], queryParams: { 'view': this.viewDatebook ? 'ez-view' : 'datebook', 'noPadding': true }, visible: expanded },

    ];
    if (this.userSettingsService.userHasPermission('ViewClients')) {
      items.push({ label: LocalizationService.rks.Search, styleClass: 'is-subitem', routerLink: ['/schedule/search'], queryParams: { 'adjustHeight': true } });
    }

    return items;
  }

  private getClientsSubItems(): MenuItem[] {

    const subitems: MenuItem[] = [];

    if (this.userSettingsService.userHasPermission('ViewClients')
      && this.userSettingsService.userHasPermission('ViewClientList')
      && this.userSettingsService.userHasPermission('EditClients')) {
      subitems.push({ label: LocalizationService.rks.AuditClients, styleClass: 'is-subitem', routerLink: ['/clients/audit-clients'] });
    }

    if (this.userSettingsService.userHasPermission('BillingStatements')) {
      subitems.push({ label: LocalizationService.rks.BillingStatements, styleClass: 'is-subitem', routerLink: ['/clients/billing-statements'] });
    }

    return [
      {
        label: LocalizationService.rks.Clients, icon: 'pi pi-users', routerLink: ['/clients'],
        styleClass: 'has-submenu',
        expanded: this.routerUrl.indexOf('/clients') >= 0,
        items: subitems
      }
    ];
  }

  private getEmailCampaignsItems(): MenuItem[] {
    const emailCampaignSubItems: MenuItem[] = [];
    emailCampaignSubItems.push(
      { label: LocalizationService.rks.Drafts, styleClass: 'is-subitem', routerLink: ['/email-campaigns/campaigns/drafts'] }
    );
    if (this.userSettingsService.userHasPermission('FullAccessEmailCampaign')) {
      emailCampaignSubItems.push(
        { label: LocalizationService.rks.NewCampaign, styleClass: 'is-subitem', routerLink: ['/email-campaigns/campaigns/new-campaign'] }
      );
    }
    emailCampaignSubItems.push(
      { label: LocalizationService.rks.Scheduled, styleClass: 'is-subitem', routerLink: ['/email-campaigns/campaigns/scheduled'] }
    );
    emailCampaignSubItems.push(
      { label: LocalizationService.rks.Sent, styleClass: 'is-subitem', routerLink: ['/email-campaigns/campaigns/sent'] }
    );

    const subitems: MenuItem[] = [
      {
        label: LocalizationService.rks.Campaigns, styleClass: 'is-subitem has-submenu',
        expanded: this.routerUrl.indexOf('/email-campaigns/campaigns') >= 0,
        routerLink: ['/email-campaigns/campaigns'],
        items: emailCampaignSubItems
      },
      { label: LocalizationService.rks.FailedEmails, styleClass: 'is-subitem', routerLink: ['/email-campaigns/failed-emails'] }
    ];


    return [
      {
        label: LocalizationService.rks.EmailCampaigns,
        icon: 'pi pi-envelope',
        styleClass: 'has-submenu',
        expanded: this.routerUrl.indexOf('/email-campaigns/') >= 0,
        items: subitems
      }
    ];
  }

  private getGroupsItems(): MenuItem[] {
    return [{
      label: LocalizationService.rks.Groups, icon: 'pi pi-sitemap', styleClass: 'has-submenu', routerLink: ['/groups'],
    }];
  }

  private getCheckInNavigationItems(includeCheckInDashboard: boolean, selfCheckinToken: string) {

    const checkInItems: MenuItem[] = [
      {
        label: LocalizationService.rks.StaffCheckIn, styleClass: 'is-subitem', routerLink: null,
        command: () => {
          this.authService.openTmsInNewWindow('checkinlite.aspx', 'scrollbars=yes,width=805,height=800');
        }
      },
      {
        label: LocalizationService.rks.SelfCheckIn, styleClass: 'is-subitem', routerLink: null,
        command: () => {
          this.tmsIframeSelfCheckin.addPostValue('SelfCheckinToken', selfCheckinToken);
          this.tmsIframeSelfCheckin.submitForm(false);
        }
      }
    ];

    if (includeCheckInDashboard) {
      checkInItems.push(
        { label: LocalizationService.rks.CheckInDashboard, styleClass: 'is-subitem', routerLink: ['/checkin/checkin-dashboard'], queryParams: { 'adjustHeight': true } }
      );
    }

    return [
      {
        label: LocalizationService.rks.CheckIn, icon: 'pi pi-id-card', styleClass: 'has-submenu',
        items: checkInItems
      }
    ];
  }

  private getBillingHasDirectMerchant() {
    const tempMenu = this.navigationMenu$.value;
    const adminLevel = this.getMenuItem(tempMenu, 'Administration');
    const billingLevel = this.getMenuItem(adminLevel.items, 'Billing');

    if (this.hasDirectDebitMerchantStreaming && this.userSettingsService.userHasPermission('AccountAdministration')) {
      billingLevel.items.push(...[
        { label: LocalizationService.rks.DirectDebitBatches, styleClass: 'is-subitem', routerLink: ['/admin/billing/direct-debit-batches'], queryParams: { 'adjustHeight': true } }
      ]);
    }

    this.navigationMenu$.next(tempMenu);
  }

  private getReportItems() {
    const reportItems = [];

    if (this.reportsAuthService.showFinancialReports()) {
      reportItems.push(...[
        { label: LocalizationService.rks.Financial, styleClass: 'is-subitem', routerLink: ['/reports/financial-reports'] }
      ]);
    }

    if (this.reportsAuthService.showTrainingReports()) {
      reportItems.push(...[
        { label: LocalizationService.rks.Training, styleClass: 'is-subitem', routerLink: ['/reports/training-reports'] }
      ]);
    }

    if (this.reportsAuthService.showVenueBookingReports()) {
      reportItems.push(...[
        { label: LocalizationService.rks.VenueBooking, styleClass: 'is-subitem', routerLink: ['/reports/venue-booking-reports'] }
      ]);
    }

    if (this.reportsAuthService.showEquipmentReports()) {
      reportItems.push(...[
        { label: LocalizationService.rks.Equipment, styleClass: 'is-subitem', routerLink: ['/reports/equipment-reports'] }
      ]);
    }

    if (this.reportsAuthService.showTimeClockReports()) {
      reportItems.push(...[
        { label: LocalizationService.rks.TimeClock, styleClass: 'is-subitem', routerLink: ['/reports/timeclock-reports'] }
      ]);
    }

    if (this.reportsAuthService.showMembershipReports()) {
      reportItems.push(...[
        { label: LocalizationService.rks.Membership, styleClass: 'is-subitem', routerLink: ['/reports/membership-reports'] }
      ]);
    }

    if (this.reportsAuthService.showPointOfSalesReports()) {
      reportItems.push(...[
        { label: LocalizationService.rks.PointOfSales, styleClass: 'is-subitem', routerLink: ['/reports/point-of-sales-reports'] }
      ]);
    }

    if (this.reportsAuthService.showMarketingReports()) {
      reportItems.push(...[
        { label: LocalizationService.rks.Marketing, styleClass: 'is-subitem', routerLink: ['/reports/marketing-reports'] }
      ]);
    }

    if (this.reportsAuthService.showSystemUsageReports()) {
      reportItems.push(...[
        { label: LocalizationService.rks.SystemUsage, styleClass: 'is-subitem', routerLink: ['/reports/system-usage-reports'] }
      ]);
    }

    if (this.reportsAuthService.showLockerReports()) {
      reportItems.push(...[
        { label: LocalizationService.rks.Locker, styleClass: 'is-subitem', routerLink: ['/reports/locker-reports'] }
      ]);
    }

    return reportItems;
  }

  private getMenuItem(array: any, label: string): any {
    return array.find(item => item.label === label);
  }
}

