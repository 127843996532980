import * as SessionActions from './session.actions';
import { ActionWithPayload } from '@ezuiaws/ez-packages/ez-socket';
import { createReducer, on } from '@ngrx/store';
import { SessionResponse } from '@app-autogen/ez.authentication/contracts/response/session-response';
import { SessionLoggingLevel } from '@app-autogen/ez.core/models/enums/session-logging-level';
import { SessionSupportStatus } from '@app-autogen/ez.core/models/enums/session-support-status';

const initialState: SessionResponse = {
  jwtToken: '',
  userGuid: '',
  currentLocationId: 0,
  currentCompanyId: 0,
  currentLocationTimeZone: '',
  currentLocationCurrencyCode: '',
  currentLocationCulture: '',
  currentLocationCountryCode: '',
  currentLocationPublicName: '',
  currentLocationName: '',
  currentLocationEmail: '',
  userId: 0,
  error: null,
  loggingLevel: SessionLoggingLevel.debug,
  supportStatus: SessionSupportStatus.nosupport,
  userName: '',
  firstName: '',
  lastName: '',
  userCalendarId: 0,
  userResourceId: 0,
  userResourceCalendarId: 0,
  userLocationId: 0,
  userIdentifier: '',
  isActive: true,
  emailAddress: '',
  adminLevel: 4,
  userPermissions: [],
  userPreferences: null,
  locationModules: null,
  locationPreferences: null,
  shareClientsAcrossLocations: false,
  selfCheckinAuthorizationToken: ''
};

const reducer = createReducer<SessionResponse>(
  initialState,

  on(SessionActions.getUserSessionSuccess, (state, { payload }) => {
    return payload;
  }),

  on(SessionActions.clearUserSession, (state) => {
    return initialState;
  }),

  on(SessionActions.setCurrentLocationId, (state, { payload }) => {
    return {
      ...state,
      currentLocationId: payload
    };
  }),

  on(SessionActions.setCurrentCompanyId, (state, { payload }) => {
    return {
      ...state,
      currentCompanyId: payload
    };
  }),

  on(SessionActions.setCurrentLocationTimeZone, (state, { payload }) => {
    return {
      ...state,
      currentLocationTimeZone: payload
    };
  }),

  on(SessionActions.setCurrentUserId, (state, { payload }) => {
    return {
      ...state,
      userId: payload
    };
  }),


  on(SessionActions.setUserCalendarId, (state, { payload }) => {
    return {
      ...state,
      userCalendarId: payload
    };
  }),


  on(SessionActions.addGlobalError, (state, { payload }) => {
    return {
      ...state,
      error: payload
    };
  }),

  on(SessionActions.setUserName, (state, { payload }) => {
    return {
      ...state,
      userName: payload
    };
  }),

  on(SessionActions.setFirstName, (state, { payload }) => {
    return {
      ...state,
      firstName: payload
    };
  }),

  on(SessionActions.setLastName, (state, { payload }) => {
    return {
      ...state,
      lastName: payload
    };
  }),

  on(SessionActions.setLoggingLevel, (state, { payload }) => {
    return {
      ...state,
      loggingLevel: payload
    };
  }),

  on(SessionActions.setSupportStatus, (state, { payload }) => {
    return {
      ...state,
      supportStatus: payload
    };
  }),

  on(SessionActions.setClientConnectionGuid, (state, { payload }) => {
    return {
      ...state,
      userIdentifier: payload
    };
  })
);

export function SessionReducer(state: SessionResponse | undefined, action: ActionWithPayload) {
  return reducer(state, action);
}
