import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EzControlValueAccessor, EzFormControl } from '../../ez-form-controls';
import { LabelValueViewModel } from '../../../autogen/ez.common/contracts/response/label-value-view-model';
import { RecurrencePatternUnit } from '../../../autogen/ez.schedule/contracts/request/recurrence-pattern-unit';
import { RecurrenceCustomMonthlyOptions } from '../../../autogen/ez.schedule/contracts/request/recurrence-custom-monthly-options';
import { DayOfWeek } from '../../../autogen/ez.schedule/contracts/response/day-of-week';

import { ResourcesProvider } from '@ezuiaws/ez-packages/ez-localization';

import { CustomValidators } from '../../validators';
import { EzDatePipeFormat } from '../../pipes';
import { getCypressFieldName, getDateTimeInUserTimeZone } from '../../helpers';

@UntilDestroy()
@Component({
  selector: 'ez-recurrence-pattern-type-select',
  templateUrl: './ez-recurrence-pattern-type-select.component.html',
  styleUrls: ['./ez-recurrence-pattern-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzRecurrencePatternTypeSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EzRecurrencePatternTypeSelectComponent),
      multi: true
    }
  ]
})
export class EzRecurrencePatternTypeSelectComponent extends EzControlValueAccessor<UntypedFormGroup> implements OnInit, AfterViewInit, OnDestroy {

  _startDate = new Date(getDateTimeInUserTimeZone());
  @Input() set startDate(startDate: Date) {
    this._startDate = startDate;
    this.dayOfWeek = this._startDate.getDay();
  }

  @Input() control: EzFormControl;

  recurrencePatternUnit = RecurrencePatternUnit;
  recurrenceCustomMonthlyOptions = RecurrenceCustomMonthlyOptions;
  EzDatePipeFormat = EzDatePipeFormat;

  form: UntypedFormGroup;
  cypressName: string = '';

  titleCasePipe = new TitleCasePipe();

  isRecurrenceCustom: boolean = false;
  dayOfWeek: DayOfWeek;

  customRepeatEveryRecurrenceOptions: LabelValueViewModel[] = [
    {
      label: this.titleCasePipe.transform(ResourcesProvider.rks.Days),
      value: RecurrencePatternUnit.Daily
    },
    {
      label: this.titleCasePipe.transform(ResourcesProvider.rks.Weeks),
      value: RecurrencePatternUnit.Weekly
    },
    {
      label: this.titleCasePipe.transform(ResourcesProvider.rks.Months),
      value: RecurrencePatternUnit.Monthly
    }
  ];

  recurrenceOptions: LabelValueViewModel[] = [
    {
      label: ResourcesProvider.rks.Daily,
      value: RecurrencePatternUnit.Daily
    },
    {
      label: ResourcesProvider.rks.Weekly,
      value: RecurrencePatternUnit.Weekly
    },
    {
      label: ResourcesProvider.rks.Monthly,
      value: RecurrencePatternUnit.Monthly
    },
    {
      label: ResourcesProvider.rks.Custom,
      value: RecurrencePatternUnit.Custom
    }
  ];

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.cypressName = getCypressFieldName(this.control);
    this.dayOfWeek = this._startDate.getDay();
    this.buildForm();
    this.subscribeOnFormValueChanges();
  }

  ngOnDestroy(): void {
    //need to reset the form values when the parent form select no for recurring option
    this.form.reset();
  }

  ngAfterViewInit(): void {
    // used forceChange to initialize parent form using EzControlValueAccessor
    this.forceChange();
  }

  private buildForm() {
    this.form = this.fb.group({
      recurrenceType: new EzFormControl(RecurrencePatternUnit.Weekly, null, null, 'recurrenceType', ResourcesProvider.rks.Recurrence),
      customRepeatEveryQuantity: new EzFormControl(null, [CustomValidators.integerValidator], null, 'customRepeatEveryQuantity', ResourcesProvider.rks.RepeatEvery),
      customRepeatEveryRecurrenceType: new EzFormControl(RecurrencePatternUnit.Daily, null, null, 'customRepeatEveryRecurrenceType'),
      recurrenceCustomMonthlyOption: new EzFormControl(RecurrenceCustomMonthlyOptions.DayOfMonth, [Validators.required], null, 'recurrenceCustomMonthlyOption'),
      daysOfWeek: []
    });
  }

  subscribeOnFormValueChanges() {
    this.form.get('recurrenceType').valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((recurrencePatternUnit: RecurrencePatternUnit) => {
        this.isRecurrenceCustom = recurrencePatternUnit === RecurrencePatternUnit.Custom;
      });
  }

  getWeekNumber(): string {
    let weekNumber: number = 0;
    let currentMonth: number = this._startDate.getMonth();
    let calculatedDate: Date = new Date(this._startDate.toString());

    while (calculatedDate.getMonth() == currentMonth) {
      calculatedDate.setDate(calculatedDate.getDate() - 7);
      weekNumber++;
    }

    if (weekNumber == 1)
      return ResourcesProvider.rks.First;
    else if (weekNumber == 2)
      return this.titleCasePipe.transform(ResourcesProvider.rks.Second);
    else if (weekNumber == 3)
      return ResourcesProvider.rks.Third;
    else if (weekNumber == 4)
      return ResourcesProvider.rks.Fourth;
    else if (weekNumber == 5)
      return ResourcesProvider.rks.Last;
  }
}
