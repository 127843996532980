import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Prime Imports
import { FileUploadModule } from 'primeng/fileupload';

import { EzLocalizationModule } from '@ezuiaws/ez-packages/ez-localization';

// Module Imports
import { EzFileChooserComponent } from './components/ez-file-chooser/ez-file-chooser.component';

@NgModule({
  declarations: [
    EzFileChooserComponent
  ],
  imports: [
    CommonModule,
    EzLocalizationModule,
    FileUploadModule
  ],
  exports: [
    EzFileChooserComponent
  ]
})
export class EzFileModule { }
