import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'ez-ellipsis',
  templateUrl: './ez-ellipsis.component.html',
  styleUrls: ['./ez-ellipsis.component.scss']
})
export class EzEllipsisComponent implements OnInit {
  @ViewChild('allText', { static: true }) panel: OverlayPanel;

  @Input() text: string = '';
  @Input() maxLength: number = 50;

  outputText: string = '';
  ellipsisText: string = '';

  constructor() { }

  ngOnInit(): void {
    this.outputText = this.text;
    if (this.text.length > this.maxLength - 1) {
      this.outputText = this.text.substring(0, this.maxLength - 1);
      this.ellipsisText = this.text;
    }
  }

  hideInfoPanel(place, currentOverlay: OverlayPanel) {
    const overlay = (currentOverlay.container as HTMLElement);
    const hasScroll = overlay.scrollHeight > overlay.offsetHeight;
    if (place === 'link' && !hasScroll) {
      currentOverlay.hide();
    } else if (place === 'overlayPanel') {
      currentOverlay.hide();
    }

  }

}
