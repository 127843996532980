import { createReducer, on } from '@ngrx/store';
import { LabelValueViewModel } from "@app-autogen/ez.common/contracts/response/label-value-view-model";
import { LocationEmailTemplate } from "@app-autogen/ez.email/contracts/response/location-email-template";

import * as EmailTemplateActions from './email-template-state.actions';

export const EMAIL_TEMPLATES_FEATURE_KEY = 'email-templates';

export interface State {
  emailTemplateCategories: LabelValueViewModel[];
  emailTemplateSubCategories: LabelValueViewModel[];
  emailTemplateSubCategoryTemplates: LabelValueViewModel[];
  templates: LocationEmailTemplate[];
  locationEmailTemplate: LocationEmailTemplate;
  emailTemplateHtmlSource: string;
  createdEmailTemplateId: number;
  isEmailTemplateNameInputVisible: boolean;
}

export interface EmailTemplateState {
  readonly [EMAIL_TEMPLATES_FEATURE_KEY]: State;
}

const initialState: State = {
  emailTemplateCategories: [],
  emailTemplateSubCategories: [],
  emailTemplateSubCategoryTemplates: [],
  templates: [],
  locationEmailTemplate: null,
  emailTemplateHtmlSource: null,
  createdEmailTemplateId: null,
  isEmailTemplateNameInputVisible: true
};

export const emailTemplateReducer = createReducer<State>(
  initialState,
  on(EmailTemplateActions.getEmailTemplateCategoriesOnLoadSuccess, (state: State, { payload }) => {
    return {
      ...state,
      emailTemplateCategories: payload
    };
  }),
  on(EmailTemplateActions.getEmailTemplateCategoriesOnLoadFail, (state: State) => {
    return {
      ...state,
      emailTemplateCategories: []
    };
  }),
  on(EmailTemplateActions.getEmailTemplateSubCategoriesSuccess, (state: State, { payload }) => {
    return {
      ...state,
      emailTemplateSubCategories: payload
    };
  }),
  on(EmailTemplateActions.getEmailTemplateSubCategoriesFail, (state: State) => {
    return {
      ...state,
      emailTemplateSubCategories: []
    };
  }),
  on(EmailTemplateActions.getEmailTemplateSubCategoryTemplatesSuccess, (state: State, { payload }) => {
    return {
      ...state,
      emailTemplateSubCategoryTemplates: payload
    };
  }),
  on(EmailTemplateActions.getEmailTemplateSubCategoryTemplatesFail, (state: State) => {
    return {
      ...state,
      emailTemplateSubCategoryTemplates: []
    };
  }),
  on(EmailTemplateActions.getLocationEmailTemplatesSuccess, (state: State, { payload }) => {
    return {
      ...state,
      templates: payload.locationEmailTemplates
    };
  }),
  on(EmailTemplateActions.getLocationEmailTemplatesFail, (state: State) => {
    return {
      ...state,
      templates: []
    };
  }),
  on(EmailTemplateActions.getLocationEmailTemplateSuccess, (state: State, { payload }) => {
    return {
      ...state,
      locationEmailTemplate: payload.locationEmailTemplate
    };
  }),
  on(EmailTemplateActions.getLocationEmailTemplateFail, (state: State) => {
    return {
      ...state,
      emailTemplateHtmlSource: ''
    };
  }),
  on(EmailTemplateActions.getEmailTemplateHtmlSourceSuccess, (state: State, { payload }) => {
    return {
      ...state,
      emailTemplateHtmlSource: payload
    };
  }),
  on(EmailTemplateActions.getEmailTemplateHtmlSourceFail, (state: State) => {
    return {
      ...state,
      emailTemplateHtmlSource: ''
    };
  }),
  on(EmailTemplateActions.addLocationEmailTemplateSuccess, (state: State, { payload }) => {
    return {
      ...state,
      createdEmailTemplateId: payload.emailTemplateId
    };
  }),
  on(EmailTemplateActions.clearState, (state: State) => {
    return initialState;
  }),
  on(EmailTemplateActions.clearCreatedEmailTemplateId, (state: State) => {
    return {
      ...state,
      createdEmailTemplateId: null
    };
  }),
  on(EmailTemplateActions.toggleEmailTemplateNameInput, (state: State, { payload }) => {
    return {
      ...state,
      isEmailTemplateNameInputVisible: payload
    };
  })
);
