import { SupportActionTypes } from '@app-autogen/ez.core/contracts/support/action-types/support-action-types';
import { SupportClientStatisticResponse } from '@app-autogen/ez.support/contracts/response/support-client-statistic-response';
import { createActionHelper } from '@ezuiaws/ez-packages/ez-store';
import { BrowserResponse } from '@app-autogen/ez.core/contracts/browser/response/browser-response';
import { BrowserLocationResponse } from '@app-autogen/ez.core/contracts/browser/response/browser-location-response';

export const getRequestStatisticOnLoad = createActionHelper<SupportClientStatisticResponse>(SupportActionTypes.GetRequestStatisticOnLoad);
export const getRequestStatisticOnLoadSuccess = createActionHelper<SupportClientStatisticResponse>(SupportActionTypes.GetRequestStatisticOnLoadSuccess);
export const getRequestStatisticOnLoadFail = createActionHelper<string>(SupportActionTypes.GetRequestStatisticOnLoadFail);
export const setBrowserDetailsData = createActionHelper<BrowserResponse>(SupportActionTypes.SetBrowserDetailsData);
export const setBrowserLocationDetailsData = createActionHelper<BrowserLocationResponse>(SupportActionTypes.SetBrowserLocationDetailsData);
export const setActionHandlers = createActionHelper<string[]>(SupportActionTypes.SetActionHandlers);
