import { createActionHelper } from '@ezuiaws/ez-packages/ez-store';

import { LabelValueViewModel } from '@app-autogen/ez.common/contracts/response/label-value-view-model';
import { EmailTemplateActionTypes } from '@app-autogen/ez.email/contracts/action-types/email-template-action-types';
import { GetEmailTemplateHtmlSourceRequest } from '@app-autogen/ez.email/contracts/request/get-email-template-html-source-request';
import { GetEmailTemplateSubCategoriesRequest } from '@app-autogen/ez.email/contracts/request/get-email-template-sub-categories-request';
import { GetEmailTemplateSubCategoryTemplatesRequest } from '@app-autogen/ez.email/contracts/request/get-email-template-sub-category-templates-request';
import { GetLocationEmailTemplateRequest } from '@app-autogen/ez.email/contracts/request/get-location-email-template-request';
import { SendFeedbackEmailRequest } from '@app-autogen/ez.email/contracts/request/send-feedback-email-request';
import { CreateLocationEmailTemplateRequest } from "@app-autogen/ez.email/contracts/request/create-location-email-template-request";
import { CreateLocationEmailTemplateResponse } from "@app-autogen/ez.email/contracts/response/create-location-email-template-response";
import { DeleteLocationEmailTemplateRequest } from "@app-autogen/ez.email/contracts/request/delete-location-email-template-request";
import { GetLocationEmailTemplatesRequest } from "@app-autogen/ez.email/contracts/request/get-location-email-templates-request";
import { GetLocationEmailTemplateResponse } from "@app-autogen/ez.email/contracts/response/get-location-email-template-response";
import { GetLocationEmailTemplatesResponse } from "@app-autogen/ez.email/contracts/response/get-location-email-templates-response";
import { UpdateLocationEmailTemplateRequest } from '@app-autogen/ez.email/contracts/request/update-location-email-template-request';

// ----------------------------------------------------------- //
//  Emails Actions
export const getEmailTemplateCategoriesOnLoad = createActionHelper(EmailTemplateActionTypes.GetEmailTemplateCategoriesOnLoad);
export const getEmailTemplateCategoriesOnLoadSuccess = createActionHelper<[]>(EmailTemplateActionTypes.GetEmailTemplateCategoriesOnLoadSuccess);
export const getEmailTemplateCategoriesOnLoadFail = createActionHelper<string>(EmailTemplateActionTypes.GetEmailTemplateCategoriesOnLoadFail);

export const getEmailTemplateSubCategories = createActionHelper<GetEmailTemplateSubCategoriesRequest>(EmailTemplateActionTypes.GetEmailTemplateSubCategories);
export const getEmailTemplateSubCategoriesSuccess = createActionHelper<LabelValueViewModel[]>(EmailTemplateActionTypes.GetEmailTemplateSubCategoriesSuccess);
export const getEmailTemplateSubCategoriesFail = createActionHelper<string>(EmailTemplateActionTypes.GetEmailTemplateSubCategoriesFail);

export const getEmailTemplateSubCategoryTemplates = createActionHelper<GetEmailTemplateSubCategoryTemplatesRequest>(EmailTemplateActionTypes.GetEmailTemplateSubCategoryTemplates);
export const getEmailTemplateSubCategoryTemplatesSuccess = createActionHelper<LabelValueViewModel[]>(EmailTemplateActionTypes.GetEmailTemplateSubCategoryTemplatesSuccess);
export const getEmailTemplateSubCategoryTemplatesFail = createActionHelper<string>(EmailTemplateActionTypes.GetEmailTemplateSubCategoryTemplatesFail);

export const getLocationEmailTemplates = createActionHelper<GetLocationEmailTemplatesRequest>(EmailTemplateActionTypes.GetLocationEmailTemplates);
export const getLocationEmailTemplatesSuccess = createActionHelper<GetLocationEmailTemplatesResponse>(EmailTemplateActionTypes.GetLocationEmailTemplatesSuccess);
export const getLocationEmailTemplatesFail = createActionHelper<string>(EmailTemplateActionTypes.GetLocationEmailTemplatesFail);

export const getLocationEmailTemplate = createActionHelper<GetLocationEmailTemplateRequest>(EmailTemplateActionTypes.GetLocationEmailTemplate);
export const getLocationEmailTemplateSuccess = createActionHelper<GetLocationEmailTemplateResponse>(EmailTemplateActionTypes.GetLocationEmailTemplateSuccess);
export const getLocationEmailTemplateFail = createActionHelper<string>(EmailTemplateActionTypes.GetLocationEmailTemplateFail);

export const getEmailTemplateHtmlSource = createActionHelper<GetEmailTemplateHtmlSourceRequest>(EmailTemplateActionTypes.GetEmailTemplateHtmlSource);
export const getEmailTemplateHtmlSourceSuccess = createActionHelper<string>(EmailTemplateActionTypes.GetEmailTemplateHtmlSourceSuccess);
export const getEmailTemplateHtmlSourceFail = createActionHelper<string>(EmailTemplateActionTypes.GetEmailTemplateHtmlSourceFail);

export const sendFeedbackEmail = createActionHelper<SendFeedbackEmailRequest>(EmailTemplateActionTypes.SendFeedbackEmail);
export const sendFeedbackEmailSuccess = createActionHelper<number>(EmailTemplateActionTypes.SendFeedbackEmailSuccess);
export const sendFeedbackEmailFail = createActionHelper<string>(EmailTemplateActionTypes.SendFeedbackEmailFail);

export const addLocationEmailTemplate = createActionHelper<CreateLocationEmailTemplateRequest>(EmailTemplateActionTypes.AddLocationEmailTemplate);
export const addLocationEmailTemplateSuccess = createActionHelper<CreateLocationEmailTemplateResponse>(EmailTemplateActionTypes.AddLocationEmailTemplateSuccess);
export const addLocationEmailTemplateFail = createActionHelper<string>(EmailTemplateActionTypes.AddLocationEmailTemplateFail);

export const updateLocationEmailTemplate = createActionHelper<UpdateLocationEmailTemplateRequest>(EmailTemplateActionTypes.UpdateLocationEmailTemplate);
export const updateLocationEmailTemplateSuccess = createActionHelper<number>(EmailTemplateActionTypes.UpdateLocationEmailTemplateSuccess);
export const updateLocationEmailTemplateFail = createActionHelper<string>(EmailTemplateActionTypes.UpdateLocationEmailTemplateFail);

export const deleteLocationEmailTemplate = createActionHelper<DeleteLocationEmailTemplateRequest>(EmailTemplateActionTypes.DeleteLocationEmailTemplate);
export const deleteLocationEmailTemplateSuccess = createActionHelper<number>(EmailTemplateActionTypes.DeleteLocationEmailTemplateSuccess);
export const deleteLocationEmailTemplateFail = createActionHelper<string>(EmailTemplateActionTypes.DeleteLocationEmailTemplateFail);

export const clearState = createActionHelper<boolean>('[Email]- Clear State.');
export const clearCreatedEmailTemplateId = createActionHelper<boolean>('[Email]- Clear Created Email Template Id.');

export const toggleEmailTemplateNameInput = createActionHelper<boolean>('[Email]- Show Hide Email TemplateName Input.');

