import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { EzCryptoService } from '@ez-packages';
import { EzLocaleHelper } from '@ezuiaws/ez-packages/ez-localization';

import { environment } from 'src/environments/environment';
import { LoginRequest } from '@app-autogen/ez.common/contracts/request/login-request';
import { SessionResponse } from '@app-autogen/ez.authentication/contracts/response/session-response';
import { SessionFacade } from '@src/app/state/session-state/session.facade';
import { SharedStateFacade } from '@src/app/state/shared-state/shared-state.facade';
import { AppDetailsFacade } from '@src/app/state/app-details-state/app-details.facade';
import { LocationSettingsService } from '@app-core/auth/location-settings.service';
import { UiWebOptions } from '@app-shared/interfaces/ui-web-options';
import { LocalizationService } from '@app-core/localization/localization.service';
import { EZLeaguesUserResponse } from '@app-autogen/ez.ezleagues/contracts/response/ez-leagues-user-response';
import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';

import * as appRouter from '@app-root/state/router-state';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private appIsInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  userSession$: Observable<SessionResponse> = this.sessionFacade.userSession$;
  userSession: SessionResponse;

  isLogging: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLogging$: Observable<boolean> = this.isLogging.asObservable();

  uiWebOptions$: Observable<UiWebOptions> = this.sharedStateFacade.uiWebOptions$;
  uiWebOptions: UiWebOptions;

  constructor(
    private appDetailsFacade: AppDetailsFacade,
    private sessionFacade: SessionFacade,
    private sharedStateFacade: SharedStateFacade,
    private locationSettingsService: LocationSettingsService,
    private cryptoHelper: EzCryptoService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private storeHelper: EzStoreService<appRouter.State>
  ) {
    this.checkForTokenInUrl();
    this.subscribeOnUserSession();
    this.getUiWebOptions();
  }

  private getUiWebOptions() {
    this.sharedStateFacade.fetchUiWebOptions();
    this.uiWebOptions$.subscribe((uiWebOptions: UiWebOptions) => this.uiWebOptions = uiWebOptions);
  }

  private subscribeOnUserSession() {
    this.userSession$
      .pipe(filter((session) => !!session && session.userId > 0))
      .subscribe((userSession) => {
        this.userSession = userSession;
      });
  }

  /**
   * Determine if we need to redirect to TMS
   * @param tmsPage
   * @param checkDefaultPlatform
   */
  redirectToTmsIfNeeded(tmsPage: string = null, checkDefaultPlatform: boolean = true) {
    if (this.redirectToTms(checkDefaultPlatform)) {
      this.openTmsInSameWindow(this.jwt, tmsPage, false);
    }
  }

  private redirectToTms(checkDefaultPlatform: boolean): boolean {
    const enableTMSPlatform: boolean = this.locationSettingsService.getLocationPreferenceBooleanValue('EnableTMSPlatform');
    const enableHybridPlatform: boolean = this.locationSettingsService.getLocationPreferenceBooleanValue('EnableHybridPlatform');
    const defaultPlatform: string = this.locationSettingsService.getLocationPreferenceStringValue('DefaultPlatform');

    let redirectToTms: boolean = enableTMSPlatform && !enableHybridPlatform;
    if (checkDefaultPlatform) {
      redirectToTms = redirectToTms || (enableTMSPlatform && enableHybridPlatform && defaultPlatform === 'TMS');
    }
    return redirectToTms;
  }

  getIsUserAuthorized(): boolean {
    const jwt = this.jwt;
    if (jwt) {
      try {
        this.decodeJwt(jwt);
        return !this.jwtHelper.isTokenExpired(jwt);
      } catch (e) {
        console.warn(e);
      }
    }
    return false;
  }

  getTokenExpirationDate(): Date {
    const jwt = this.jwt;
    if (jwt) {
      try {
        this.decodeJwt(jwt);
        const tokenExpirationDate = this.jwtHelper.getTokenExpirationDate(jwt);
        if (tokenExpirationDate instanceof Promise) {
          return null;
        }
        return tokenExpirationDate;
      } catch (e) {
        console.warn(e);
      }
    }
    return null;
  }

  loadUserSessionData(currentLocationId: number = null): void {
    const locationId = currentLocationId || this.getUserLocationIdFromLocalStorage();

    this.sessionFacade.fetchUserSession(this.jwt, locationId);
  }

  setApplicationIsInitialized(status: boolean) {
    this.appDetailsFacade.setAppInitiated(status);
    this.appIsInitialized$.next(status);
  }

  getApplicationIsInitialized(): BehaviorSubject<boolean> {
    return this.appIsInitialized$;
  }

  login(credentials: LoginRequest) {
    credentials = {
      ...credentials,
      password: this.cryptoHelper.encrypt(credentials.password)
    };

    this.setIsLogging(true);
    this.sessionFacade.fetchUserLogin(credentials);
  }

  loginSingleSignOn(userId: number, locationId: number, singleSignOnJwt: string) {
    this.setIsLogging(true);
    this.setUserLocationIdToLocalStorage(locationId);
    this.sessionFacade.fetchSingleSignOn(userId, locationId, singleSignOnJwt);
  }

  signOnSignUpSuccess() {
    this.setApplicationIsInitialized(false);
    this.setIsLogging(false);

    this.storeHelper.stateStreamSelector(appRouter.getRouterFeatureState).pipe(
      filter(route => !!route.state),
      take(1)
    ).subscribe(route => {
      if (route.state.queryParams?.isRedirected && route.state.queryParams?.newadminid) {
        return this.router.navigate([route.state.queryParams?.redirectPath], { queryParams: { newadminid: route.state.queryParams?.newadminid } });
      }
      else {
        return this.router.navigateByUrl(`/`);
      }
    });
  }

  changeUserLocation(locationId: number) {
    this.setApplicationIsInitialized(false);
    this.setUserLocationIdToLocalStorage(locationId);
    this.loadUserSessionData(locationId);
    return this.router.navigateByUrl(`/`);
  }

  logout() {
    this.clearLocalStorage();

    this.sessionFacade.clearState();
    window.location.href = `${this.getTmsBaseUrl()}/login.aspx`;
  }

  configureAppSettingsWithUserSessionData(session: SessionResponse) {
    /**
     * Set user config data in LocaleStorage
     */
    this.setJwt(session.jwtToken);
    this.setUserLocationIdToLocalStorage(session.currentLocationId);
    EzLocaleHelper.setCurrentLocale(session.currentLocationCulture);
    EzLocaleHelper.setCurrencyCode(session.currentLocationCurrencyCode);
    EzLocaleHelper.setTimeZone(session.currentLocationTimeZone);

    /**
     * Reload localization resources after new user locale settings
     */
    void LocalizationService.loadResources();

    /**
     *  Determine if we need to redirect to TMS
     *  without check if the default platform is TMS
     *  When we are switching locations, we do not care about the default, only if new UI is enabled
     */
    this.redirectToTmsIfNeeded('overview2.aspx', false);

    this.setApplicationIsInitialized(true);
  }

  setJwt(jwt: string) {
    localStorage.setItem(environment.jwt_key, jwt);
  }

  get jwt(): string {
    return localStorage.getItem(environment.jwt_key);
  }

  removeJwt() {
    localStorage.removeItem(environment.jwt_key);
  }

  setUserLocationIdToLocalStorage(locationId: number) {
    localStorage.setItem('location_id', locationId.toString());
  }

  getUserLocationIdFromLocalStorage(): number {
    const stringLocationId = localStorage.getItem('location_id');
    return stringLocationId ? Number(stringLocationId) : null;
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  decodeJwt(jwt: string): any {
    return this.jwtHelper.decodeToken(jwt);
  }

  setIsLogging(value: boolean) {
    this.isLogging.next(value);
  }

  checkForTokenInUrl() {
    // TODO This should be put in a better place and maybe not use window object.

    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    if (token) {
      this.setJwt(token);
    }
  }

  getTmsBaseUrl(): string {
    const tmsBaseUrl: string = this.uiWebOptions.BaseTMSUrl[0];
    // const tmsBaseUrl = 'https://1104ezww.ezfacility.net';
    // const tmsBaseUrl = 'https://tms-qa.ord.ezfacility.com/';
    return tmsBaseUrl;
  }

  getEZLeaguesBaseUrl(): string {
    const ezlBaseUrl: string = this.uiWebOptions.BaseEZLeaguesUrl[0];
    // const ezlBaseUrl = 'https://localhost/Ezleagues';
    // const ezlBaseUrl = 'https://tms-qa.ord.ezfacility.com/';
    return ezlBaseUrl;
  }

  getEnvironment(): string {
    return this.uiWebOptions?.Environment ?? 'QA';
  }

  openTmsInNewWindow(tmsPage: string, features?: string) {
    const tmsForm = this.getTmsForm();
    tmsForm.target = 'TMS';

    this.addJwtTokenInputToForm(tmsForm);
    this.addRedirectPageInputToForm(tmsForm, tmsPage);

    document.body.appendChild(tmsForm);

    const tms = window.open('', 'TMS', features);

    if (tms) {
      tmsForm.submit();
    } else {
      alert('You must allow popups for this to work.');
    }
  }

  openTmsInSameWindow(jwt: string, tmsPage: string, logSourcePage: boolean) {
    const tmsForm = this.getTmsForm();

    this.addJwtTokenInputToForm(tmsForm, jwt);
    if (tmsPage) {
      this.addRedirectPageInputToForm(tmsForm, tmsPage);
    }

    const suppressEzuiIframeInput = document.createElement('input');
    suppressEzuiIframeInput.type = 'hidden';
    suppressEzuiIframeInput.name = 'SuppressEzuiIframe';
    suppressEzuiIframeInput.value = 'true';
    tmsForm.appendChild(suppressEzuiIframeInput);

    if (logSourcePage) {
      const sourcePageInput = document.createElement('input');
      sourcePageInput.type = 'hidden';
      sourcePageInput.name = 'SourcePage';
      sourcePageInput.value = this.router.url;
      tmsForm.appendChild(sourcePageInput);
    }

    document.body.appendChild(tmsForm);

    tmsForm.submit();
  }

  openEZLInNewWindow(ezlPage: string, ezlUser: EZLeaguesUserResponse) {

    const ezlForm = this.getEzlForm(true);

    this.addJwtTokenInputToForm(ezlForm, ezlUser.jwtEzlToken);

    if (ezlPage) {
      this.addRedirectPageInputToForm(ezlForm, ezlPage);
    }

    const suppressEzuiIframeInput = document.createElement('input');
    suppressEzuiIframeInput.type = 'hidden';
    suppressEzuiIframeInput.name = 'SuppressEzuiIframe';
    suppressEzuiIframeInput.value = 'true';
    ezlForm.appendChild(suppressEzuiIframeInput);

    document.body.appendChild(ezlForm);

    ezlForm.submit();
  }

  getTmsForm() {
    const tmsForm = document.createElement('form');
    tmsForm.method = 'POST';
    tmsForm.action = `${this.getTmsBaseUrl()}/login.aspx`;
    return tmsForm;
  }

  getEzlForm(newWindow: boolean = false) {
    const ezlForm = document.createElement('form');
    ezlForm.method = 'POST';
    ezlForm.action = `${this.getEZLeaguesBaseUrl()}/admin/login.aspx`;
    if (newWindow) {
      ezlForm.target = '_blank';
    }
    return ezlForm;
  }

  addJwtTokenInputToForm(tmsForm, jwt: string = null) {
    const jwtTokenInput = document.createElement('input');
    jwtTokenInput.type = 'hidden';
    jwtTokenInput.name = 'jwtToken';
    jwtTokenInput.value = jwt ?? this.jwt;
    tmsForm.appendChild(jwtTokenInput);
  }

  addRedirectPageInputToForm(tmsForm, tmsPage: string) {
    const redirectPageInput = document.createElement('input');
    redirectPageInput.type = 'hidden';
    redirectPageInput.name = 'RedirectPage';
    redirectPageInput.value = tmsPage;
    tmsForm.appendChild(redirectPageInput);
  }

}
