import { Injectable, Injector } from '@angular/core';

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class EzInjectorService {

  private static injector: Injector;

  static setInjector(injector: Injector) {
    EzInjectorService.injector = injector;
  }

  static getInjector(): Injector {
    return EzInjectorService.injector;
  }
}
