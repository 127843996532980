import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Prime Imports
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChipsModule } from 'primeng/chips';
import { DividerModule } from 'primeng/divider';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';

// NgRx
import { StoreModule } from '@ngrx/store';

// Module Imports
import {
  EzComponentsModule,
  EzSharedPipesModule
} from '@ez-packages';

import { EzLocalizationModule } from '@ezuiaws/ez-packages/ez-localization';
import { EzLayoutModule, EzMobileOverlayComponent, EzSidemenuComponent } from '@ezuiaws/ez-packages/ez-layout';
import { EzButtonsModule } from '@ezuiaws/ez-packages/ez-buttons';
import { EzBannerModule } from '@ezuiaws/ez-packages/ez-banner';
import { EzCheckboxModule } from '@ezuiaws/ez-packages/ez-checkbox';
import { EzDirectivesModule } from '@ezuiaws/ez-packages/ez-directives';

import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TmsIframeComponent } from './tms-iframe/tms-iframe.component';
import { FooterComponent } from './footer/footer.component';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { FooterOnlyLayoutComponent } from './footer-only-layout/footer-only-layout.component';
import { NoSidebarLayoutComponent } from './no-sidebar-layout/no-sidebar-layout.component';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import { SelectorsModule } from '@app-shared/components/selectors/selectors.module';
import { themeReducer } from './theme-switcher/state/theme.reducer';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { DynamicMenuComponent } from './header/components/dynamic-menu/dynamic-menu.component';
import { DynamicMenuDirective } from './header/directives/dynamic-menu.directive';
import { WelcomeModal } from './welcome/welcome.modal';
import { ImportantMessageComponent } from './important-message/important-message.component';
import { MessageOnlyLayoutComponent } from './message-only-layout/message-only-layout.component';
import { ProfilePopupComponent } from '@app-shared/components/profile-popup/profile-popup.component';
import { ClickOutsideDirective } from '@app-shared/directives/click-outside.directive';
import { EmailTemplateStateModule } from "@app-shared/state/email-templates-state/email-template-state.module";

@NgModule({
  declarations: [
    ThemeSwitcherComponent,
    HeaderComponent,
    SidebarComponent,
    TmsIframeComponent,
    FooterComponent,
    FullLayoutComponent,
    FooterOnlyLayoutComponent,
    NoSidebarLayoutComponent,
    LanguageSwitcherComponent,
    FeedbackFormComponent,
    DynamicMenuComponent,
    DynamicMenuDirective,
    WelcomeModal,
    ImportantMessageComponent,
    MessageOnlyLayoutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    StoreModule.forFeature('appTheme', themeReducer),
    SidebarModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    SelectButtonModule,
    ChipsModule,
    ListboxModule,
    OverlayPanelModule,
    SelectorsModule,
    EzLayoutModule,
    EzComponentsModule,
    EzButtonsModule,
    EzBannerModule,
    EzLocalizationModule,
    RadioButtonModule,
    DropdownModule,
    DividerModule,
    MenuModule,
    EzSharedPipesModule,
    ProfilePopupComponent,
    ClickOutsideDirective,
    EzCheckboxModule,
    EzDirectivesModule,
    EmailTemplateStateModule,
    EzMobileOverlayComponent,
    EzSidemenuComponent
  ],
  exports: [
    ThemeSwitcherComponent,
    TmsIframeComponent,
    FullLayoutComponent,
    NoSidebarLayoutComponent,
    FooterOnlyLayoutComponent,
  ],
})
export class LayoutModule { }
