import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';

// External Imports
import { JwtModule } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';

// NgRx Imports
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';

// Ez-packages
import {
  EzInjectorService,
  EzComponentsModule,
} from '@ez-packages';

//EZUI AWS
import {
  EZ_CRYPTO_PASSWORD,
  EZ_APP_NAME,
  EZ_BUILD_VERSION,
  EZ_HUB_ENDPOINT,
  EZ_JWT_KEY,
  EZ_SOCKET_KEY,
  EZ_USER_FILES_ENDPOINT
} from '@ezuiaws/ez-packages/ez-socket';
import { EzNotificationModule } from '@ezuiaws/ez-packages/ez-notification';
import { EzLocalizationModule, EzLocaleHelper } from '@ezuiaws/ez-packages/ez-localization';
import { EzStoreModule } from '@ezuiaws/ez-packages/ez-store';
import { EzStatisticsModule } from '@ezuiaws/ez-packages/ez-statistics';

// Core module
import { CoreModule } from '@app-core/core.module';
import { SocketProvider } from '@app-core/socket/socket.provider';
import { socketProviderFactory } from '@app-core/socket/socket-provider-factory';
import { LocalizationService } from '@app-core/localization/localization.service';
import { localizationResourcesFactory } from '@app-core/localization/localization-resources-provider-factory';
import { datadogRumFactory } from '@app-core/datadog/services/datadog-rum-factory';
import { DatadogService } from '@app-core/datadog/services/datadog.service';

// App Imports
import { environment } from 'src/environments/environment';
import { appversion } from '@src/environments/appversion';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from '@app-shared/layout/layout.module';
import { SessionEffects } from '@src/app/state/session-state/session-effects';
import { CustomSerializer, metaReducers } from './state/router-state';
import { FailResponseReducer } from '@src/app/state/fail-state/fails.reducer';
import { SessionReducer } from './state/session-state/session.reducer';
import { AppDetailsReducer } from './state/app-details-state/app-details.reducer';
import { SharedStateReducer } from '@src/app/state/shared-state/shared-state.reducer';
import { SocketEffects } from '@src/app/state/socket-state/socket.effects';
import { SharedStateFacade } from '@src/app/state/shared-state/shared-state.facade';
import { SessionFacade } from '@src/app/state/session-state/session.facade';
import { AppDetailsFacade } from '@src/app/state/app-details-state/app-details.facade';
import { CustomRouteReuseStrategy } from '@app-shared/route-reuse/custom-route-reuses-strategy';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    CoreModule,
    EzComponentsModule,
    EzStoreModule,
    EzNotificationModule,
    EzLocalizationModule,
    EzStatisticsModule,
    StoreModule.forFeature('appSession', SessionReducer),
    StoreModule.forFeature('appDetails', AppDetailsReducer),
    StoreModule.forFeature('appShared', SharedStateReducer),
    StoreModule.forFeature('appFailResponses', FailResponseReducer),
    StoreModule.forFeature('appRouter', routerReducer, { metaReducers }),
    EffectsModule.forFeature([SessionEffects, SocketEffects]),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'appRouter'
    }),
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      name: 'App DevTools',
      maxAge: 25,
      logOnly: true
    }),
    ToastrModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem(environment.jwt_key)
      }
    }),
    // !environment.production ?
    //   HttpClientInMemoryWebApiModule.forRoot(MockDataService) : [],
    //    HttpClientInMemoryWebApiModule.forRoot(MockDataService),
  ],
  providers: [
    LocalizationService,
    SharedStateFacade,
    SessionFacade,
    AppDetailsFacade,
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: LOCALE_ID, useValue: `${EzLocaleHelper.locale}-${EzLocaleHelper.currencyCode}` },
    { provide: APP_INITIALIZER, useFactory: datadogRumFactory, deps: [DatadogService], multi: true},
    { provide: APP_INITIALIZER, useFactory: socketProviderFactory, deps: [SocketProvider], multi: true },
    { provide: APP_INITIALIZER, useFactory: localizationResourcesFactory, deps: [LocalizationService], multi: true },
    { provide: EZ_CRYPTO_PASSWORD, useValue: environment.encryption_key },
    { provide: EZ_JWT_KEY, useValue: environment.jwt_key },
    { provide: EZ_APP_NAME, useValue: environment.client_software },
    { provide: EZ_BUILD_VERSION, useValue: appversion.build },
    { provide: EZ_SOCKET_KEY, useValue: environment.tms_hub_key },
    { provide: EZ_HUB_ENDPOINT, useValue: environment.tmshub },
    { provide: EZ_USER_FILES_ENDPOINT, useValue: environment.file_upload_url },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
  ]
})
export class AppModule {
  constructor(injector: Injector) {
    EzInjectorService.setInjector(injector);
  }
}
