import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';
import { Observable, of } from 'rxjs';

import * as ClientAccessibleToUserSelector from '@app-shared/state/client-accessible-to-user-state/client-accessible-to-user.selector';
import * as ClientAccessibleToUserActions from '@app-shared/state/client-accessible-to-user-state/client-accessible-to-user.actions';
import * as fromClientAccessibleToUser from '@app-shared/state/client-accessible-to-user-state/client-accessible-to-user.reducer';

import { filter, map } from 'rxjs/operators';
import { GetClientIsAccessibleForUserRequest } from '@app-autogen/ez.client/contracts/request/get-client-is-accessible-for-user-request';
import { GetClientIsAccessibleForUserResponse } from '@app-autogen/ez.client/contracts/response/get-client-is-accessible-for-user-response';

@Injectable({
  providedIn: 'root'
})
export class ClientAccessibleToUserGuardService {

  constructor(
    private router: Router,
    private clientAccessibleToUserStoreHelper: EzStoreService<fromClientAccessibleToUser.ClientAccessibleToUserState>) {
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    let activate: Observable<boolean> = of(true);
    if (route.params.id) {
      activate = this.canActivateValidation(route);
    }
    return activate;
  }

  canActivateValidation(route: ActivatedRouteSnapshot): Observable<boolean> {
    const request: GetClientIsAccessibleForUserRequest = {
      clientId: route.params.id,
    };

    this.clientAccessibleToUserStoreHelper.dispatchAction(ClientAccessibleToUserActions.getClientIsAccessibleForUser(request));

    return this.clientAccessibleToUserStoreHelper.stateStreamSelector(ClientAccessibleToUserSelector.getClientIsAccessibleForUser)
      .pipe(
        filter((getClientIsAccessibleForUserResponse: GetClientIsAccessibleForUserResponse) => !!getClientIsAccessibleForUserResponse),
        map((getClientIsAccessibleForUserResponse) => {
          const activate: boolean = getClientIsAccessibleForUserResponse.clientIsAccessibleForUser;
          if (!activate) {
            this.router.navigate(['access-denied']);
          }
          this.clientAccessibleToUserStoreHelper.dispatchAction(ClientAccessibleToUserActions.clearClientIsAccessibleForUser());
          return activate;
        })
      );
  }
}
