<form [formGroup]="form">
  <ez-form-control-template [control]="form.get('daysOfWeek')">
    <ez-checkbox-list [sourceList]="daysList"
      [disabled]="disabled"
      [selectedItems]="selectedDaysOfWeek"
      [horizontal]='horizontal'
      (targetList)='onDaysOfWeekSelectedChanged($event)'>
    </ez-checkbox-list>
  </ez-form-control-template>
</form>
