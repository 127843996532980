<ez-form-control-template [control]="control"
  [disabled]="isDisabled"
  [horizontalAlignment]="horizontalAlignment"
  [showLabel]="showLabel"
  [toolTipHtml]="toolTipHtml">
  <div class="switch">
    <div class="onoffswitch"
      [ngClass]="{'horizontal-alignment': horizontalAlignment}"
      [attr.data-cy]="cyName">
      <label class="onoffswitch-label">
        <input #input
          type="checkbox"
          class="onoffswitch-checkbox"
          [checked]="checked"
          [attr.disabled]="isDisabled ? '' : null"
          (input)="onChange($event)"
          (focus)="onTouched()" />
        <span class="slider"></span>
        <span class="labels"
          data-on="ON"
          data-off="OFF">
        </span>
      </label>
    </div>
  </div>
</ez-form-control-template>
