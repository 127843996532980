export const ezValidationMessages = {
  required: ' is required.',
  requiredTrue: ' is required.',
  maxlength: ' exceeds max length of ',
  max: ' should be lower than ',
  min: ' should be greater than or equal to ',
  numberValid: ' should be a number.',
  currencyValid: ' should be a valid currency.',
  integerValid: ' should be a whole number.',
  specialCharsValid: ' cannot contain special characters.',
  requireCheckboxesToBeChecked: ' should be selected.',
  dateLessThan: ' should be less than ',
  dayLessThan: ' cannot be before ',
  timeLessThan: ' cannot be before ',
  invalidDateTime: 'Invalid time and date.',
  dateWithinRange: ' should be within ',
  numberLessThan: ' should be less than ',
  creditCardNumberValid: ' is not a valid credit card number ',
  pattern: ' is not a valid value.',
  whitespace: ' is required.',
  email: 'Must be a valid email address.',
  clientUsageExceeded: ' usage exceeded',
  couponDoesntExist: ` doesn't exist or is expired.`,
  signatureInvalid: 'Signature invalid.',
  termLengthQuantityValid: 'The length of each period for the fee is not valid.',
  termQuantityValid: 'The number of payments for the fee is not valid.',
  ageLessThen: 'FROM age should be greater than or equal to TO age',
  oneOptionMustBeSelected: 'At least one option must be selected.',
  validateDuplicateEmailTemplateName:  'A template with this name already exists. Please choose another.',
  zeroFeeDoesNotAllow: (fieldName) => `The ${fieldName} may not be zero.`,
  zeroAndNegativeNumberDoesNotAllow: (fieldName) => `The ${fieldName} should be greater than zero.`,
  renewalDiscountGreaterThanFee: (discount, perTermFee) => `The total discount for renewals ($${discount}) exceeds the per term fee for renewals ($${perTermFee}), please either change the discount or the fee amount accordingly.`,
  daysExceedNorm: (maxDays) => ` should be lower than ${maxDays}`,
  emailsListExceedNorm: (maxEmails) => `You can enter only ${maxEmails} email addresses.`,
};
