import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EzCalendarFormat, EzCalendarView } from '../ez-date-picker/ez-date-picker.component';
import { EzControlValueAccessor, EzFormControl } from '../../ez-form-controls';

import { ResourcesProvider } from '@ezuiaws/ez-packages/ez-localization';

import { CustomValidators } from '../../validators';
import { EndingOptionEnum } from './ez-ending-option-enum';
import { distinctUntilChanged, filter } from 'rxjs';
import { getCypressFieldName } from '../../helpers';

@UntilDestroy()
@Component({
  selector: 'ez-ending-on-after-section',
  templateUrl: './ez-ending-on-after-section.component.html',
  styleUrls: ['./ez-ending-on-after-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzEndingOnAfterSectionComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EzEndingOnAfterSectionComponent),
      multi: true
    }
  ]
})
export class EzEndingOnAfterSectionComponent extends EzControlValueAccessor<UntypedFormGroup> implements OnInit, AfterViewInit {

  @Input() control: EzFormControl;
  @Input() minDate: Date;
  @Output() endingOptionChangedEvent: EventEmitter<EndingOptionEnum> = new EventEmitter<EndingOptionEnum>();
  @Output() endingOnDateChangedEvent: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() endingAfterOccurrencesOptionChangedEvent: EventEmitter<number> = new EventEmitter<number>();

  form: UntypedFormGroup;
  cypressName: string = '';

  endingOption = EndingOptionEnum;

  viewFormat: EzCalendarView = EzCalendarView.date;
  dateFormat: EzCalendarFormat = EzCalendarFormat.shortDate;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngAfterViewInit(): void {
    // used forceChange to initialize parent form using EzControlValueAccessor
    this.forceChange();
    this.form.controls['endingAfterOccurrences'].disable();
  }

  ngOnInit(): void {
    this.cypressName = getCypressFieldName(this.control);
    this.initForm();
    this.subscribeOnFormValueChanges();
  }

  initForm() {
    this.form = this.fb.group({
      endingOnOrAfter: new EzFormControl(EndingOptionEnum.On, [Validators.required], null, 'endingOnOrAfter', null),
      endingOnDate: new EzFormControl(
        null,
        [Validators.required, CustomValidators.daysExceedNorm(this.minDate, 999)],
        null,
        'endingOnDate',
        ResourcesProvider.rks.OnOccurrences
      ),
      endingAfterOccurrences: new EzFormControl(
        null,
        [Validators.required, CustomValidators.integerValidator, Validators.min(1), Validators.max(999)],
        null,
        'endingAfterOccurrences',
        ResourcesProvider.rks.AfterOccurrences
      ),
    });
  }

  subscribeOnFormValueChanges() {
    this.form.get('endingOnOrAfter').valueChanges
      .pipe(distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe((endingOption: EndingOptionEnum) => {
        this.endingOptionChangedEvent.emit(endingOption);
        const endingAfterOccurrencesControl: AbstractControl = this.form.controls['endingAfterOccurrences'];
        const endingOnDateControl: AbstractControl = this.form.controls['endingOnDate'];

        if (endingOption == EndingOptionEnum.On) {
          endingOnDateControl.enable();
          endingAfterOccurrencesControl.disable();
        } else if (endingOption == EndingOptionEnum.After) {
          endingAfterOccurrencesControl.enable();
          endingOnDateControl.disable();
        }
        this.form.updateValueAndValidity();
      });

    this.form.get('endingOnDate').valueChanges
      .pipe(distinctUntilChanged(),
        filter((endingOnDate: Date) => endingOnDate != null),
        untilDestroyed(this))
      .subscribe((endingOnDate: Date) => {
        this.endingOnDateChangedEvent.emit(endingOnDate);
      });

    this.form.get('endingAfterOccurrences').valueChanges
      .pipe(distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe((endingAfterOccurrences: number) => {
        this.endingAfterOccurrencesOptionChangedEvent.emit(endingAfterOccurrences);
      });
  }

}
