<ng-container *ngIf="_showDropDown">
  <ez-form-control-template [control]="control"
    [showAdvancedPlaceholder]="true"
    [placeholderVisible]="_placeholderVisible"
    [placeholderText]="_dropDownPlaceholderText"
    [linkText]="_dropDownLinkText"
    [disabled]='isDisabled'
    (placeholderClicked)="setFocus()"
    (linkClicked)="linkClicked()">
    <p-dropdown #dropdown
      detectDropdownPanelShowDirection
      styleClass="d-block"
      [ngModel]="selectedValue"
      [showClear]="showClear"
      [placeholder]="simplePlaceHolder"
      [attr.data-cy]="cypressName"
      [options]="_options"
      [disabled]='isDisabled'
      [overlayOptions]="{appendTo: 'body', styleClass: 'ez-select-overlay-panel'}"
      (onChange)="dropdownChange($event.value)"
      (onHide)='onHide()'
      (onFocus)="dropdownFocus()">

      <ng-template let-item
        pTemplate="item">
        <ng-container *ngIf="item.info && item.info.length; else labelWithoutTooltip">
          <ez-tooltip [showLabel]="true"
            [label]="item.label"
            [tooltipContent]="item.info">
          </ez-tooltip>
        </ng-container>
        <ng-template #labelWithoutTooltip>
          {{item.label}}
        </ng-template>
      </ng-template>

    </p-dropdown>
  </ez-form-control-template>
</ng-container>
<ng-container *ngIf="!_showDropDown">
  <ng-content></ng-content>
</ng-container>
