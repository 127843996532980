import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { filter, fromEvent, Subscription } from "rxjs";

@UntilDestroy()
@Directive({
	selector: '[clickOutside]',
	standalone: true
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {

	@Output() clickedOutside = new EventEmitter<void>();

	documentClickSubscription: Subscription | undefined;

	constructor(
		private elementRef: ElementRef,
		@Inject(DOCUMENT) private document: Document
	) { }

	ngAfterViewInit(): void {
		this.documentClickSubscription = fromEvent(this.document, 'click')
			.pipe(
				filter((event) => {
					return !this.isInside(event.target as HTMLElement);
				}),
				untilDestroyed(this)
			).subscribe(() => {
				this.clickedOutside.emit();
			});
	}

	isInside(elementToCheck: HTMLElement): boolean {
		return elementToCheck === this.elementRef.nativeElement || this.elementRef.nativeElement.contains(elementToCheck);
	}

	ngOnDestroy(): void {
		this.documentClickSubscription?.unsubscribe();
	}

}