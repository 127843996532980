import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

@UntilDestroy()
@Directive({
	selector: '[appHighlightSearch]'
})
export class HighlightSearchDirective implements AfterViewInit {
	@Input() set searchTerm(text: string) {
		this._search.next(text);
	}
	
	_search: BehaviorSubject<string> = new BehaviorSubject('');
	searchTerm$: Observable<string> = this._search.asObservable();

	prevTerm: string = '';

	constructor(private el: ElementRef) { }

	ngAfterViewInit() {
		this.subscribeOnChangeAndHighlight();
	}

	private subscribeOnChangeAndHighlight() {

		this.searchTerm$.pipe(untilDestroyed(this)).subscribe((searchTerm: string) => {
			if(searchTerm || this.prevTerm){
				this.prevTerm = searchTerm;
				const text = this.el.nativeElement.innerHTML.replace(/<\/?strong>/g, '');
				const regex = new RegExp(`(${searchTerm})`, 'gi');
				const highlightedText = text.replace(regex, '<strong>$1</strong>');
				this.el.nativeElement.innerHTML = highlightedText;
			}
		});
	}
}