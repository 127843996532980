import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as fromSession from '@app-root/state/session-state';
import { SessionLoggingLevel } from '@app-autogen/ez.core/models/enums/session-logging-level';
import { Store, select } from '@ngrx/store';
import { LoggingMessageRequest } from '@app-autogen/ez.core/contracts/logging/request/logging-message-request';
import { SocketHelperService } from '@app-core/socket/socket-helper.service';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoggingService {
  private currentLoggingLevel: SessionLoggingLevel = SessionLoggingLevel.critical;
  localLoggingData: any[] = [];
  localLoggingDataUpdated$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  maxRows = 100;
  itemCount = 0;

  constructor(
    private store: Store<fromSession.State>,
    private socketHelperService: SocketHelperService
  ) {
    this.store
      .pipe(
        select(fromSession.getLoggingLevel),
        filter((loggingLevel: SessionLoggingLevel) => !!loggingLevel),
      ).subscribe(loggingLevel => this.currentLoggingLevel = loggingLevel);
  }

  public logMessage(logMessage: LoggingMessageRequest) {
    if (logMessage.loggingLevel <= this.currentLoggingLevel) {
      switch (logMessage.loggingLevel) {
        case SessionLoggingLevel.nologging:
          break;
        case SessionLoggingLevel.critical:
          console.log('CRIT -> (' + logMessage.title + ') : ', logMessage.message);
          break;
        case SessionLoggingLevel.warning:
          console.log('WARN -> (' + logMessage.title + ') : ', logMessage.message);
          break;
        case SessionLoggingLevel.informational:
          console.log('INFO -> (' + logMessage.title + ') : ', logMessage.message);
          break;
        case SessionLoggingLevel.debug:
          const caller = this.callerName();
          console.log('DEBUG -> (' + caller + ' ) (' + logMessage.title + ') : ', logMessage.message);
          break;
        default:
          break;
      }
    }
    this.serverLogMessage(logMessage);
    this.localLogMessage(logMessage);
  }

  private serverLogMessage(logMessage: LoggingMessageRequest) {
    this.socketHelperService.sendServerLog(JSON.stringify(logMessage));
  }

  private localLogMessage(logMessage: LoggingMessageRequest) {
    const item: any = {};
    item.loglevel = logMessage.loggingLevel;
    item.title = logMessage.title;
    item.data = logMessage.message;
    item.count = this.itemCount++;
    this.localLoggingData.push(item);

    this.clampListToMaxRows();
    this.localLoggingDataUpdated$.next(this.localLoggingData);
  }

  private clampListToMaxRows(): void {
    let length = this.localLoggingData.length;
    while (length-- > this.maxRows) {
      this.localLoggingData.splice(0, 1);
    }
  }

  public updateMaxRows(value: number) {
    this.maxRows = value;
    this.clampListToMaxRows();
  }

  public clearLocalLoggingData(): void {
    this.localLoggingData = [];
    this.localLoggingDataUpdated$.next(this.localLoggingData);
  }

  private callerName() {
    try {
      throw new Error();
    } catch (e) {
      try {
        return e.stack.split('at ')[3].split(' ')[0];
      } catch (e) {
        return '';
      }
    }
  }
}
