import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';


@Component({
  selector: 'ez-select-button',
  templateUrl: './ez-select-button.component.html',
  styleUrls: ['./ez-select-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EzSelectButtonComponent<T> implements OnInit {

  @Input() buttons: SelectItem<T>[];
  @Output() optionSelected: EventEmitter<any> = new EventEmitter();

  // use this to specify the initial value
  @Input() selectedType: T;

  constructor() {
  }

  ngOnInit() {
  }

  onOptionClick(option: SelectItem) {
    this.optionSelected.emit(option.value);
  }

}
