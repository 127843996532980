<p-dropdown name="drpLocations"
  optionLabel="locationName"
  optionValue="locationId"
  styleClass="w-100 location-select"
  [attr.data-cy]="cypressName"
  [placeholder]='locationPlaceholder'
  [filter]="showFilter"
  [options]="dropDownOptions$ | async"
  [disabled]="isDisabled$ | async"
  [ngModel]="currentSelectedLocationId"
  (onChange)="onLocationChanged($event.value)"
  [overlayOptions]="{appendTo: 'body', styleClass: 'ez-select-overlay-panel'}"
  detectDropdownPanelShowDirection>
</p-dropdown>
