import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[inputWidthByPlaceholder]',
})

export class InputWidthByPlaceholderDirective implements AfterViewInit {

	constructor(
		private el: ElementRef
	) { }

	ngAfterViewInit(): void {
		this.el.nativeElement.size = this.el.nativeElement.placeholder.length > 10 ? this.el.nativeElement.placeholder.length : 10;
	}
}
