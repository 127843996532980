import { RouterStateSnapshot, Params, ActivatedRouteSnapshot } from '@angular/router';
import { RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, createFeatureSelector, createSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface State {
  router: RouterReducerState<RouterStateUrl>;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    // destructured

    const { url } = routerState;
    const { queryParams } = routerState.root;
    let state: ActivatedRouteSnapshot = routerState.root;

    // Using spread operator to append params incase some id's are embedded in sub-routes
    let params = { ...state.params };
    while (state.firstChild) {
      state = state.firstChild;
      params = { ...params, ...state.params };
    }
    return { url, queryParams, params };
  }
}

// Selector functions
export const getRouterFeatureState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('appRouter');

export const getRouteInfo = createSelector(
  getRouterFeatureState,
  state => state.state
);

export const getRouteParams = createSelector(
  getRouterFeatureState,
  state => state.state.params
);

export const metaReducers: MetaReducer<State>[] = [logActions];

export function logActions(reducer: ActionReducer<State>) {
  return (state, action) => {
    return reducer(state, action);
  };
}
