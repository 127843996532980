import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, ATTENDANCE_STATUSES_FEATURE_KEY } from './attendance-status.reducer';

const AttendanceStatusFeatureState = createFeatureSelector<State>(ATTENDANCE_STATUSES_FEATURE_KEY);

export const getAttendanceStatuses = createSelector(
  AttendanceStatusFeatureState,
  state => state.attendanceStatuses
);
