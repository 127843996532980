import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileUpload } from 'primeng/fileupload';

import { FileUploadService } from '../../services/file-upload/file-upload.service';
import { FileUploadResponse } from '../../services/file-upload/models/file-upload-response';
import { UploadTypeCodeEnum } from '../../services/file-upload/models/upload-type-code-enum';

@UntilDestroy()
@Component({
  selector: 'ez-file-chooser',
  templateUrl: './ez-file-chooser.component.html',
  styleUrls: ['./ez-file-chooser.component.css'],
  providers: [FileUploadService]
})
export class EzFileChooserComponent implements OnInit, OnDestroy {

  @Input() uploadTypeCode: UploadTypeCodeEnum = UploadTypeCodeEnum.documentAttachment;
  @Input() removeFromEzUserFilesOnDestroy: boolean = false;

  @Input() fileUpload: FileUploadResponse = null;
  @Output() fileUploadChange: EventEmitter<FileUploadResponse> = new EventEmitter<FileUploadResponse>();

  @Input() selectedFiles: File[] = [];
  @Output() selectedFilesChange: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Input() selectedFileNames: string[] = [];
  @Output() selectedFileNameRemoved: EventEmitter<string[]> = new EventEmitter<string[]>();

  @ViewChild('fileInput') fileInput: FileUpload;

  acceptedFileTypes: string = 'application/pdf';
  acceptedFileExtensions: string = 'jpg, jpeg, png, gif';
  maxSizeText: string = '2000x2000px, 10 MB';
  minSizeText: string = '500x500px, 100 KB';

  // 5,000 KB
  maxSize: number = 5000000;

  // call the uploadHandler when file chosen
  autoUpload: boolean = true;

  _selectedFiles: File[] = [];

  constructor(private fileUploadService: FileUploadService) { }

  ngOnInit(): void {
    this.setupControl();
  }

  ngOnDestroy(): void {
    if (this.removeFromEzUserFilesOnDestroy) {
      // TODO: handle removing files from ezuserfiles in file upload service
      // this.fileUploadService.removeFile()
    }
  }

  onSelectedFilesChange(fileInput) {
    this.selectedFilesChange.emit(fileInput.files);
  }

  // add a new type code for each upload type
  setupControl() {
    switch (this.uploadTypeCode) {
      case UploadTypeCodeEnum.documentAttachment:
        this.acceptedFileExtensions = 'pdf';
        this.acceptedFileTypes = 'application/pdf';
        this.maxSizeText = '10 MB';
        this.minSizeText = null;
        break;
      case UploadTypeCodeEnum.clientMemberPhoto:
        // can probably be changed once we move on from rackspace?
        this.acceptedFileExtensions = 'jpg, jpeg, png, gif, tiff';
        this.acceptedFileTypes = 'image/*.jpg,.jpeg,.png,.gif,.tif';
        this.maxSizeText = '2000x2000px, 10 MB';
        this.minSizeText = '500x500px, 100 KB';
        break;

      case UploadTypeCodeEnum.clientActivityItemAttachment:
        this.acceptedFileExtensions = 'All Files (*.*)';
        this.acceptedFileTypes = null;
        this.maxSizeText = null;
        this.minSizeText = null;
        break;

      default:
        this.acceptedFileExtensions = 'jpg, jpeg, png, gif';
        this.acceptedFileTypes = 'image/*';
        this.maxSizeText = '2000x2000px, 10 MB';
        this.minSizeText = '500x500px, 100 KB';
        break;
    }
  }

  uploadHandler(fileInput) {
    // For now only single files
    const file = fileInput.files[0];
    this.fileUploadService.uploadFile({
      file: file,
      uploadTypeCode: this.uploadTypeCode
    }).pipe(
      untilDestroyed(this),
    ).subscribe(
      value => {
        this.fileUploadChange.emit(value);
        console.log('File uploaded to ezuserfiles!' + value.ezUserFilesFileName);
      }
    );
  }

  clearFile() {
    this.fileUploadChange.emit(null);
    this.selectedFilesChange.emit([]);
    // TODO: handle removing files from ezuserfiles in file upload service
    // this.fileUploadService.removeFile();
  }

  // trigger upload using view child when autoUpload is false
  uploadToEzUserFiles() {
    this.fileInput.upload();
  }

  onSelectedFileNameRemoved(fileName) {
    this.selectedFileNames = this.selectedFileNames.filter(x => x !== fileName);
    this.selectedFileNameRemoved.emit(fileName);
  }

}
