import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@app-core/auth/auth-guard.service';
import { FullLayoutComponent } from '@app-shared/layout/full-layout/full-layout.component';
import { NoSidebarLayoutComponent } from '@app-shared/layout/no-sidebar-layout/no-sidebar-layout.component';
import { LoginGuardService } from '@app-core/auth/login-guard.service';
import { AuthResolver } from '@app-core/auth/auth.resolver';
import { AccessDeniedIframeComponent } from '@app-shared/components/access-denied-iframe/access-denied-iframe.component';
import { ClientAccessibleToUserGuardService } from '@app-core/auth/client-accessible-to-user-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'schedule', pathMatch: 'full' },
  {
    path: '', component: FullLayoutComponent, canActivateChild: [AuthGuardService], resolve: { resolver: AuthResolver }, children: [
      { path: 'dashboard', loadChildren: () => import('@app-root/dashboard-module/dashboard.module').then(m => m.DashboardModule) },
      {
        path: 'schedule',
        loadChildren: () => import('@app-root/schedule-module/schedule.module').then(m => m.ScheduleModule)
      },
      {
        path: 'clients',
        canActivateChild: [ClientAccessibleToUserGuardService],
        loadChildren: () => import('@app-root/clients-module/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'email-campaigns',
        loadChildren: () => import('@app-root/email-campaigns-module/email-campaigns.module').then(m => m.EmailCampaignsModule)
      },
      { path: 'groups', loadChildren: () => import('@app-root/groups-module/groups.module').then(m => m.GroupsModule) },
      { path: 'attendance', loadChildren: () => import('@app-root/attendance-module/attendance.module').then(m => m.AttendanceModule) },
      { path: 'equipment', loadChildren: () => import('@app-root/equipment-module/equipment.module').then(m => m.EquipmentModule) },
      { path: 'timeclock', loadChildren: () => import('@app-root/timeclock-module/timeclock.module').then(m => m.TimeClockModule) },
      { path: 'locker-rooms', loadChildren: () => import('@app-root/lockers-module/lockers.module').then(m => m.LockersModule) },
      { path: 'checkin', loadChildren: () => import('@app-root/checkin-module/checkin.module').then(m => m.CheckInModule) },
      { path: 'reports', loadChildren: () => import('@app-root/reports-module/reports.module').then(m => m.ReportsModule) },
      { path: 'support', loadChildren: () => import('@app-root/support-module/support.module').then(m => m.SupportModule) },
      { path: 'ezsignup', loadChildren: () => import('@app-root/registration-module/registration.module').then(m => m.RegistrationModule) },
      { path: 'reg-engine', loadChildren: () => import('@app-root/reg-engine-module/reg-engine.module').then(m => m.RegEngineModule) },
      { path: 'admin', loadChildren: () => import('@app-root/admin-module/admin.module').then(m => m.AdminModule) },
      { path: 'access-denied', component: AccessDeniedIframeComponent }
    ]
  },
  {
    path: '', component: NoSidebarLayoutComponent, children: [
      { path: 'login', canActivateChild: [LoginGuardService], loadChildren: () => import('@app-root/auth-module/auth.module').then(m => m.AuthModule) },
      { path: 'chat', resolve: { resolver: AuthResolver }, canActivateChild: [AuthGuardService], loadChildren: () => import('@src/app/chat-module/chat.module').then(m => m.ChatModule) },
    ]
  },
  { path: '**', redirectTo: 'schedule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload', bindToComponentInputs: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
