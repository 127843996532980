import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@app-core/auth/auth.service';
import { LocationSettingsService } from '@app-core/auth/location-settings.service';
import { UserSettingsService } from '@app-core/auth/user-settings.service';
import { SessionFacade } from '@app-root/state/session-state/session.facade';
import { Observable } from 'rxjs';
import { EzLocalizationModule } from '@ezuiaws/ez-packages/ez-localization';
import { ClickOutsideDirective } from '@app-shared/directives/click-outside.directive';
import { EzMobileOverlayComponent } from '@ezuiaws/ez-packages/ez-layout';

@Component({
selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, EzLocalizationModule, ClickOutsideDirective, EzMobileOverlayComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfilePopupComponent implements OnInit {

  @Input() isMobileView: boolean = false;
  @Output() profilePopupOpened: EventEmitter<void> = new EventEmitter<void>;

  userName$: Observable<string> = this.sessionFacade.userName$;
  showProfilePopup: boolean = false;
  showOldPlatform: boolean = this.locationSettingsService.getLocationPreferenceBooleanValue('EnableTMSPlatform') && this.locationSettingsService.getLocationPreferenceBooleanValue('EnableHybridPlatform');
  showSystemAdmin: boolean = this.userSettingsService.userSession.adminLevel >= 100;

  constructor(    private sessionFacade: SessionFacade,
    private authService: AuthService,
    private locationSettingsService: LocationSettingsService,
    private userSettingsService: UserSettingsService,) { }

  ngOnInit(): void {

  }

  toggleProfilePopup() {
    this.showProfilePopup = !this.showProfilePopup;
    this.onProfilePopupOpened();
  }

  clickedOutside() {
    this.showProfilePopup = false;
  }

  logout() {
    this.sessionFacade.fetchUserLogout();
  }

  redirectToOldPlatform(page: string = null) {
    this.authService.openTmsInSameWindow(null, page, true);
  }

  private onProfilePopupOpened() {
    if (this.showProfilePopup) {
      this.profilePopupOpened.emit();
    }
  }

}
