import { AppDetailsState } from './app-details-state';
import { HubConnectionActivityResponse } from '@app-autogen/ez.core/contracts/support/response/hub-connection-activity-response';
import { HubActivity } from '@app-autogen/ez.core/contracts/support/response/hub-activity';
import * as AppDetailsActions from './app-details-actions';
import { ActionWithPayload } from '@ezuiaws/ez-packages/ez-socket';
import { createReducer, on } from '@ngrx/store';

const initialState: AppDetailsState = {
  hubConnected: false,
  applicationInitialized: false,
  hubConnectionActivity: { hubActivity: [] },
  browserDetails: null,
  browserLocationDetails: null
};

const reducer = createReducer<AppDetailsState>(
  initialState,
  on(AppDetailsActions.setHubConnected, (state, { payload }) => {
    return {
      ...state,
      hubConnected: payload
    };
  }),

  on(AppDetailsActions.setAppInitiated, (state, { payload }) => {
    return {
      ...state,
      applicationInitialized: payload
    };
  }),

  on(AppDetailsActions.logConnectionActivity, (state, { payload }) => {
    const currentActivity = [...state.hubConnectionActivity.hubActivity, payload];
    const newHubConnectionActivity: HubConnectionActivityResponse = { hubActivity: currentActivity };

    return {
      ...state,
      hubConnectionActivity: newHubConnectionActivity
    };
  }),

  on(AppDetailsActions.setBrowserDetails, (state, { payload }): AppDetailsState => {
    return {
      ...state,
      browserDetails: payload
    };
  }),

  on(AppDetailsActions.setBrowserLocation, (state, { payload }): AppDetailsState => {
    return {
      ...state,
      browserLocationDetails: payload
    };
  })
);

export function AppDetailsReducer(state: AppDetailsState | undefined, action: ActionWithPayload) {
  return reducer(state, action);
}
