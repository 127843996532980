export const environment = {
  production: false,
  browser_details: 'https://extreme-ip-lookup.com/json',
  jwt_key: 'access_token',
  encryption_key: 'd886f4c3163b411396d374f11efc15d5',
  client_software: 'EZUI',
  file_upload_url: 'https://ezui-qa.ezfacility.com/userfiles/upload/uploadfile',
  survey_link: 'https://t.maze.co/269653762',
  unlayer_project_id: 195944
};
