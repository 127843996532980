<ez-sidemenu [menuItems]='navigationMenu$ | async'
  [showSideBar]='showSideBar$ | async'
  [isDesktopDevice]="isDesktopDevice"
  [isTabletDevice]="isTabletDevice"
  [isMobileDevice]="isMobileDevice"
  (toggleMenuEvent)='toggleMenu()'>
  <ng-container postLogo>
    <ng-container *ngIf="showSideBar$ | async; else collapsedSidebarHeader">
      <div class="profile-element d-flex align-items-center">
        <img alt="logo-image"
          class="logo"
          src="assets/images/EZLogo_full-color.svg"/>
      </div>
    </ng-container>
    <ng-template #collapsedSidebarHeader>
      <div class="profile-element-collapsed d-flex align-items-center">
        <img alt="logo-image"
          class="logo"
          src="assets/images/EZ-logo-small-2-letters.svg"/>
      </div>
    </ng-template>
    <img alt="collapse-menu-arrow-icon"
      class="icon-arrow-double-right"
      src="assets/images/icons/icon-arrow_double-right-4pt.svg"
      [ngClass]="{ 'rotate-left': (showSideBar$ | async) }"
      (click)="toggleMenu()"/>
    <div class="connection-state d-flex align-items-center">
      <h6>{{ build }}</h6>
      <span class="{{ connectionState }}"></span>
    </div>
  </ng-container>
  <ng-container customTMScontainer>
    <tms-iframe redirectPage="selfcheckin"
      [submitAfterViewInit]="false"
      #tmsIframeSelfCheckin>
    </tms-iframe>
  </ng-container>
</ez-sidemenu>
