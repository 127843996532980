import { Injectable } from '@angular/core';
import localeRu from '@angular/common/locales/ru';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

import { EzResourceKeys, ResourcesProvider } from '@ezuiaws/ez-packages/ez-localization';

import { ResourceKeys } from './resource-keys';
import localeEnUsUsd from './locales/en-US-USD';
import localeEnGbGbp from './locales/en-GB-GBP';
import localeEnCaCad from './locales/en-CA-CAD';
import localeEnAuAud from './locales/en-AU-AUD';
import localeArAeAed from './locales/ar-AE-AED';
import localeEnIeEur from './locales/en-IE-EUR';
import localeEnUsCad from './locales/en-US-CAD';
import localeEnMuMur from './locales/en-MU-MUR';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  static rks: Partial<ResourceKeys>;

  static loadResources(): Promise<void | EzResourceKeys> {
    return ResourcesProvider.loadResources().then(() => {
      LocalizationService.rks = Object.assign({}, ResourcesProvider.rks);
    });
  }

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      void ResourcesProvider.loadResources()
        .then(() => {
          LocalizationService.rks = ResourcesProvider.rks;
          registerLocaleData(localeEnUsUsd, 'en-US-USD');
          registerLocaleData(localeEnGbGbp, 'en-GB-GBP');
          registerLocaleData(localeEnCaCad, 'en-CA-CAD');
          registerLocaleData(localeEnAuAud, 'en-AU-AUD');
          registerLocaleData(localeArAeAed, 'ar-AE-AED');
          registerLocaleData(localeEnIeEur, 'en-IE-EUR');
          registerLocaleData(localeEnUsCad, 'en-US-CAD');
          registerLocaleData(localeEnMuMur, 'en-MU-MUR');
          registerLocaleData(localeRu, 'ru');
          registerLocaleData(localeEs, 'es');
        })
        .then(() => resolve(true));
    });
  }
}
