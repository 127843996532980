import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Prime Imports
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EditorModule } from 'primeng/editor';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChipsModule } from 'primeng/chips';
import { TreeTableModule } from 'primeng/treetable';
import { RippleModule } from 'primeng/ripple';
import { TreeModule } from 'primeng/tree';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';

// Module Imports
import { EzSharedPipesModule } from '../pipes';

import { EzLocalizationModule } from '@ezuiaws/ez-packages/ez-localization';
import { EzButtonsModule } from '@ezuiaws/ez-packages/ez-buttons';
import { EzCheckboxModule } from '@ezuiaws/ez-packages/ez-checkbox';
import { EzTooltipComponent } from '@ezuiaws/ez-packages/ez-tooltip';
import { EzDirectivesModule } from '@ezuiaws/ez-packages/ez-directives';

// Components Imports
import { EzPanelComponent } from './ez-panel/ez-panel.component';
import { EzFormControlTemplateComponent } from './ez-form-control-template/ez-form-control-template.component';
import { EzFormControlSwitchComponent } from './ez-form-control-switch/ez-form-control-switch.component';
import { EzDatePickerComponent } from './ez-date-picker/ez-date-picker.component';
import { EzTimePickerComponent } from './ez-time-picker/ez-time-picker.component';
import { EzCarousalDatePickerComponent } from './ez-carousal-date-picker/ez-carousal-date-picker.component';
import { EzFormControlInputComponent } from './ez-form-control-input/ez-form-control-input.component';
import { EzSpinnerComponent } from './ez-spinner/ez-spinner.component';
import { EzFormControlPlaceholderComponent } from './ez-form-control-placeholder/ez-form-control-placeholder.component';
import { EzFormControlInputPopupComponent } from './ez-form-control-input-popup/ez-form-control-input-popup.component';
import { EzFormControlDropdownComponent } from './ez-form-control-dropdown/ez-form-control-dropdown.component';
import { EzFormControlEditorComponent } from './ez-form-control-editor/ez-form-control-editor.component';
import { EzChipsComponent } from './ez-chips/ez-chips.component';
import { EzSelectButtonComponent } from './ez-select-button/ez-select-button.component';
import { EzSubHeaderComponent } from './ez-sub-header/ez-sub-header.component';
import { EzTabsNavigationButtonsComponent } from './ez-tabs';
import { EzTabTemplateDirective } from './ez-tabs';
import { EzEllipsisComponent } from './ez-ellipsis/ez-ellipsis.component';
import { EzUserLoginComponent } from './ez-user-login/ez-user-login.component';
import { EzTreeComponentComponent } from './ez-tree-component/ez-tree-component.component';
import { EzEditPanelComponent } from './ez-edit-panel/ez-edit-panel.component';
import { EzSubHeaderLinkComponent } from './ez-sub-header-link/ez-sub-header-link.component';
import { EzRecurrencePatternTypeSelectComponent } from './ez-recurrence-pattern-type-select/ez-recurrence-pattern-type-select.component';
import { EzDaysOfWeekCheckboxComponent } from './ez-days-of-week-checkbox/ez-days-of-week-checkbox.component';
import { EzEndingOnAfterSectionComponent } from './ez-ending-on-after-section/ez-ending-on-after-section.component';
import { EzSharedDirectivesModule } from '../directives';
import { EzBackLinkComponent } from './ez-back-link/ez-back-link.component';
import { EzFormControlSplitInputDropdownComponent } from './ez-form-control-split-input-dropdown/ez-form-control-split-input-dropdown.component';
import { EzFormControlDropdownMultiselectComponent } from './ez-form-control-dropdown-multiselect/ez-form-control-dropdown-multiselect.component';
import { EzCheckboxListComponent } from './ez-checkbox-list/ez-checkbox-list.component';

@NgModule({
  declarations: [
    EzPanelComponent,
    EzFormControlTemplateComponent,
    EzFormControlSwitchComponent,
    EzDatePickerComponent,
    EzTimePickerComponent,
    EzCarousalDatePickerComponent,
    EzFormControlInputComponent,
    EzSpinnerComponent,
    EzFormControlInputPopupComponent,
    EzFormControlInputPopupComponent,
    EzFormControlPlaceholderComponent,
    EzFormControlDropdownComponent,
    EzFormControlEditorComponent,
    EzFormControlSplitInputDropdownComponent,
    EzChipsComponent,
    EzSelectButtonComponent,
    EzSubHeaderComponent,
    EzSubHeaderLinkComponent,
    EzTabsNavigationButtonsComponent,
    EzTabTemplateDirective,
    EzEllipsisComponent,
    EzEllipsisComponent,
    EzUserLoginComponent,
    EzTreeComponentComponent,
    EzEditPanelComponent,
    EzRecurrencePatternTypeSelectComponent,
    EzDaysOfWeekCheckboxComponent,
    EzEndingOnAfterSectionComponent,
    EzBackLinkComponent,
    EzFormControlDropdownMultiselectComponent,
    EzCheckboxListComponent
  ],
  imports: [
    CommonModule,
    PanelModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    CalendarModule,
    TooltipModule,
    DialogModule,
    InputNumberModule,
    InputTextareaModule,
    InputMaskModule,
    RadioButtonModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    EditorModule,
    BreadcrumbModule,
    SelectButtonModule,
    ChipsModule,
    RippleModule,
    TreeModule,
    TreeTableModule,
    EzSharedPipesModule,
    EzButtonsModule,
    EzCheckboxModule,
    EzSharedDirectivesModule,
    EzDirectivesModule,
    EzLocalizationModule,
    EzTooltipComponent,
  ],
  exports: [
    EzPanelComponent,
    EzFormControlTemplateComponent,
    EzFormControlSwitchComponent,
    EzDatePickerComponent,
    EzTimePickerComponent,
    EzCarousalDatePickerComponent,
    EzFormControlInputComponent,
    EzSpinnerComponent,
    EzFormControlInputPopupComponent,
    EzFormControlInputPopupComponent,
    EzFormControlPlaceholderComponent,
    EzFormControlDropdownComponent,
    EzFormControlEditorComponent,
    EzFormControlSplitInputDropdownComponent,
    EzChipsComponent,
    EzSelectButtonComponent,
    EzSubHeaderComponent,
    EzSubHeaderLinkComponent,
    EzTabsNavigationButtonsComponent,
    EzTabTemplateDirective,
    EzEllipsisComponent,
    EzEllipsisComponent,
    EzUserLoginComponent,
    EzTreeComponentComponent,
    EzEditPanelComponent,
    EzRecurrencePatternTypeSelectComponent,
    EzDaysOfWeekCheckboxComponent,
    EzEndingOnAfterSectionComponent,
    EzBackLinkComponent,
    EzFormControlDropdownMultiselectComponent,
    EzCheckboxListComponent
  ],
  providers: []
})
export class EzComponentsModule { }
