// NPM/Angular Imports
import { AuthenticationDetailsActionTypes } from '@app-autogen/ez.authentication/contracts/action-types/authentication-details-action-types';
import { HubActivity } from '@app-autogen/ez.core/contracts/support/response/hub-activity';
import { BrowserResponse } from '@app-autogen/ez.core/contracts/browser/response/browser-response';
import { BrowserLocationResponse } from '@app-autogen/ez.core/contracts/browser/response/browser-location-response';
import { createActionHelper } from '@ezuiaws/ez-packages/ez-store';

export const setHubConnected = createActionHelper<boolean>(AuthenticationDetailsActionTypes.SetHubConnected);
export const setAppInitiated = createActionHelper<boolean>(AuthenticationDetailsActionTypes.SetAppInitiated);
export const logConnectionActivity = createActionHelper<HubActivity>(AuthenticationDetailsActionTypes.LogHubConnectionActivity);
export const setBrowserDetails = createActionHelper<BrowserResponse>(AuthenticationDetailsActionTypes.SetBrowserDetails);
export const setBrowserLocation = createActionHelper<BrowserLocationResponse>(AuthenticationDetailsActionTypes.SetBrowserLocationDetails);
