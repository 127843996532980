export const datadogRumConfig = {
    applicationId: "731deebd-bb36-4b4e-9752-73ee767d7bbc",
    clientToken: "pubadaf86d37fc5c56dea2efd87ed8311c4",
    site: "datadoghq.com",
    service: "ezui",
    sessionSampleRate: 50,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    useCrossSiteSessionCookie: true,
}