import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

// Module Imports
import { SocketHelperService } from '@app-core/socket/socket-helper.service';
import * as socketActions from './socket.actions';

// NgRx/Redux - State
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionWithPayload } from '@ezuiaws/ez-packages/ez-socket';

@Injectable()
export class SocketEffects {

  // constructor(private actions$: Actions, private socketHelperService: SocketHelperService) {}
  //
  // sendToHub$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(socketActions.relayToServer),
  //     tap((action: ActionWithPayload) => this.socketHelperService.relayActionAddToQueue(action.payload))
  //   ), { dispatch: false }
  // );
}


