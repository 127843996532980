import { Directive, ElementRef, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EzCalendarHoverEvent } from '../ez-schedule';

@Directive({
  selector: '[ezRepositionFloatingElement]'
})
export class RepositionFloatingElementDirective implements OnChanges {

  _hoveredElement: EzCalendarHoverEvent = null
  @Input() set hoveredElement(hovEl: EzCalendarHoverEvent) {
    this._hoveredElement = hovEl;
    this.clearPositioning();
    this.repositionFloatingElement();
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  private repositionFloatingElement() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'visibility', 'hidden');

    setTimeout(() => {
      if (!this._hoveredElement) return;

      const viewportWidth: number = window.innerWidth || document.documentElement.clientWidth;
      const viewportHeight: number = window.innerHeight || document.documentElement.clientHeight;

      const floatingElementWidth: number = this.elementRef.nativeElement.offsetWidth;
      const floatingElementHeight: number = this.elementRef.nativeElement.offsetHeight;

      const hoveredElementRect: DOMRect = this._hoveredElement.htmlElement.getBoundingClientRect();

      const spaceAbove: number = hoveredElementRect.top;
      const spaceBelow: number = viewportHeight - hoveredElementRect.bottom;
      const spaceRight: number = viewportWidth - hoveredElementRect.right;

      let floatingElementTop: number;
      let floatingElementLeft: number;

      // Position below the hovered element if there's enough space, otherwise position above
      if (spaceBelow >= floatingElementHeight) {
        floatingElementTop = hoveredElementRect.bottom; // Position below the hovered element
      } else if (spaceAbove >= floatingElementHeight) {
        floatingElementTop = hoveredElementRect.top - floatingElementHeight; // Position above the hovered element
      } else {
        // Default: position below if not enough space above and below
        floatingElementTop = hoveredElementRect.bottom;
      }

      // Use spaceRight to check if there is enough space on the right
      if (spaceRight >= floatingElementWidth) {
        // There is enough space on the right, align to the left of the hovered element
        floatingElementLeft = hoveredElementRect.left;
      } else {
        // Not enough space on the right, align the right side of the floating element with the right side of the hovered element
        floatingElementLeft = hoveredElementRect.right - floatingElementWidth;
      }

      // Apply the calculated positions
      this.renderer.setStyle(this.elementRef.nativeElement, 'top', floatingElementTop + 'px');
      this.renderer.setStyle(this.elementRef.nativeElement, 'left', floatingElementLeft + 'px');
      this.renderer.setStyle(this.elementRef.nativeElement, 'visibility', 'visible');

      const buttonElement = this.elementRef.nativeElement.querySelector('.hover-reservation-details-button');
      if (buttonElement) {
        this.renderer.setStyle(buttonElement, 'display', 'block');
      };
    }, 400);

  }

  private clearPositioning() {
    this.renderer.removeStyle(this.elementRef.nativeElement, 'top');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'left');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'right');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'bottom');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'max-height');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'overflow-y');
    // We have to manually hide the button othervise it will be visible for a fraction of a second
    const buttonElement = this.elementRef.nativeElement.querySelector('.hover-reservation-details-button');
    if (buttonElement) {
      this.renderer.setStyle(buttonElement, 'display', 'none');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.clearPositioning();
    }
  }
}
