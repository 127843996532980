import { Injectable } from '@angular/core';
import { map, filter, catchError, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SocketHelperService } from '@app-core/socket/socket-helper.service';
import { LoggingService } from '../logging/logging.service';
import { SessionLoggingLevel } from '@app-autogen/ez.core/models/enums/session-logging-level';
import { SessionResponse } from '@app-autogen/ez.authentication/contracts/response/session-response';
import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';
import { BrowserResponse } from '@app-autogen/ez.core/contracts/browser/response/browser-response';
import { BrowserLocationResponse } from '@app-autogen/ez.core/contracts/browser/response/browser-location-response';
import { EzStatisticsService } from '@ezuiaws/ez-packages/ez-statistics';
import { AppDetailsFacade } from '@src/app/state/app-details-state/app-details.facade';

import * as fromSession from '@app-root/state/session-state';
import * as statisticActions from '@src/app/support-module/state/statistics-state/statistic.actions';
import * as fromStatistic from '@src/app/support-module/state/statistics-state';

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class ServerMiddlewareHelperService {

  constructor(
    private socketHelperService: SocketHelperService,
    private statisticService: EzStatisticsService,
    private loggingService: LoggingService,
    private storeSessionHelper: Store<fromSession.State>,
    private appDetailsFacade: AppDetailsFacade,
    private storeStatisticHelper: EzStoreService<fromStatistic.State>,
  ) { }

  public upsertSessionDataOnServer() {
    this.storeSessionHelper.pipe(
      select(fromSession.getCurrentState),
      filter((session: SessionResponse) => session.userId > 0),
      take(1)
    ).subscribe((sessionData: SessionResponse) => {
      this.socketHelperService.sendServerUpsertSession(JSON.stringify(sessionData)).pipe(
        map(result => {
          this.loggingService.logMessage({
            loggingLevel: SessionLoggingLevel.debug,
            title: 'Session Upsert Result',
            message: result
          });
        }),
        take(1),
        catchError(err => of(err))
      ).subscribe();
    });
  }

  public getHubTaskIdFromServer() {
    return this.socketHelperService.getHubTaskIdFromServer();
  }

  public getAllActionHandlersFromServer() {
    this.socketHelperService.sendServerGetAllActionsHandlers().pipe(
      filter((actionList: string[]) => actionList.length > 0),
      tap((actionList: string[]) => {
        this.loggingService.logMessage({
          loggingLevel: SessionLoggingLevel.debug,
          title: 'Get all action handlers',
          message: `Got ${actionList.length} action types: ${actionList.join(', ')}`
        });
      }),
      take(1),
      catchError(err => of(err))
    ).subscribe((actionList: string[]) => {
      this.storeStatisticHelper.dispatchAction(statisticActions.setActionHandlers(actionList));
    });
  }

  public upsertClientLocationDataOnHub() {
    this.setBrowserDetails();
    this.setBrowserLocationDetails();
  }


  private setBrowserDetails() {
    const deviceInfo = this.statisticService.getDeviceInfo();
    const browserDetails: BrowserResponse = {
      browser: deviceInfo.browser,
      browser_version: deviceInfo.browser_version,
      device: deviceInfo.device,
      os: deviceInfo.os,
      os_version: deviceInfo.os_version,
      ua: '',
      userAgent: deviceInfo.userAgent,
      isDesktop: this.statisticService.isDesktop(),
      isMobile: this.statisticService.isMobile(),
      isTablet: this.statisticService.isTablet()
    };

    this.appDetailsFacade.setBrowserDetails(browserDetails);
    this.storeStatisticHelper.dispatchAction(statisticActions.setBrowserDetailsData(browserDetails));
  }

  private setBrowserLocationDetails() {
    this.statisticService.locationDetailsStream.pipe(
      filter(locationDetails => !!locationDetails)
    ).subscribe((locationDetails: BrowserLocationResponse) => {
      this.appDetailsFacade.setBrowserLocation(locationDetails);
      this.storeStatisticHelper.dispatchAction(statisticActions.setBrowserLocationDetailsData(locationDetails));
      this.socketHelperService.setClientIP(locationDetails.query ? locationDetails.query : '');
    });
  }

}
