export enum EzCalendarViewType {
  Month = 'dayGridMonth',
  MonthList = 'listMonth',
  Week = 'timeGridWeek',
  WeekList = 'listWeek',
  WeekGrid = 'dayGridWeek',
  Day = 'timeGridDay',
  DayList = 'listDay',
  DayGrid = 'dayGridDay',
  Resource = 'resourceTimeline',
  VerticalResourceView = 'resourceTimeGridDay'
}
