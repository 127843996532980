import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ez-form-control-placeholder',
  templateUrl: './ez-form-control-placeholder.component.html',
  styleUrls: ['./ez-form-control-placeholder.component.scss']
})
export class EzFormControlPlaceholderComponent implements OnInit {

  _placeholderVisible = true;
  @Input() set placeholderVisible(show: boolean) {
    this._placeholderVisible = show;
  }

  _placeholderText = '';
  @Input() set placeholderText(text: string) {
    this._placeholderText = text;
  }

  _linkText = '';
  @Input() set linkText(text: string) {
    this._linkText = text;
  }

  _hasLabel: boolean = false;
  @Input() set hasLabel(type: boolean) {
    this._hasLabel = type;
  }

  @Output() placeholderClicked: EventEmitter<any> = new EventEmitter();
  @Output() linkClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  placeholderClick() {
    this.placeholderClicked.emit();
  }

  linkClick() {
    this.linkClicked.emit();
  }

}
