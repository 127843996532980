<div class="profile-item" [ngClass]="{ 'active-menuitem': showProfilePopup }">
  <a class="topbar-menu-item"
    tabindex="0"
    (click)="toggleProfilePopup()"
    clickOutside
    (clickedOutside)="clickedOutside()">
    <ng-container *ngIf="isMobileView === false; else mobileView">
      <img src="/assets/images/EZ_logo_small.png"
        alt="diamond-layout"
        class="profile-image"/>
      <span class="profile-name">{{ userName$ | async }}</span>
    </ng-container>

    <ng-template #mobileView>
      <div class="user-icon-container">
        <i class="green-icon user-2pt ez-icon"></i>
      </div>
    </ng-template>
  </a>
  <ul class="profile-menu fade-in-up">
    <ng-container *ngIf="isMobileView">
      <li>
        <h2 class="welcome-text">
          {{ "WelcomeText" | ezLocale }}, {{ userName$ | async }}
        </h2>
      </li>
    </ng-container>
    <li *ngIf="showOldPlatform">
      <a tabindex="0"
        (click)="redirectToOldPlatform('overview2.aspx')">
        <span>{{ "SwitchToClassic" | ezLocale }}</span>
      </a>
    </li>
    <li *ngIf="showSystemAdmin">
      <a tabindex="0"
        (click)="redirectToOldPlatform()">
        <span>{{ "SystemAdmin" | ezLocale }}</span>
      </a>
    </li>
    <li>
      <a tabindex="0"
        (click)="logout()">
        <ng-container *ngIf="isMobileView === false">
          <i class="pi pi-power-off"></i>
        </ng-container>
        <span>{{ "LogOut" | ezLocale }}</span>
      </a>
    </li>
  </ul>
</div>

<ng-container *ngIf="isMobileView">
  <ez-mobile-overlay [overlayEnabled]="showProfilePopup"
    (overlayTriggered)="toggleProfilePopup()">
  </ez-mobile-overlay>
</ng-container>

