import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longToDots'
})
export class LongToDotsPipe implements PipeTransform {

  transform(value: string | string[], textSize: number, isList?: boolean, numberOfItemsToOutput?: number): string {
    if (isList && Array.isArray(value)) {
      if (numberOfItemsToOutput && numberOfItemsToOutput < value.length) {
        const items = value.slice(0, numberOfItemsToOutput).join(', ');
        return items.trim() + ' ...';
      } else {
        return value.join(', ');
      }
    } else {
      value = typeof value === 'string' ? value : JSON.stringify(value);
      return value.length > textSize ? value.substring(0, textSize) + ' ...' : value;
    };
  }

}
