<div class="layout-topbar">
  <div class="topbar-left">
    <ng-container *ngIf="isMobileDevice">
      <div class="menu-icon">
        <i class="menu-2pt ez-icon "
          (click)="toggleMenu()"></i>
      </div>
      <h2 class="page-title">{{ pageTitle$ | async }}</h2>
    </ng-container>
    <img id="logo-mobile"
      alt="diamond-layout"
      class="mobile-logo"
      src="assets/images/EZLogo_full-color-small.png" />
    <form [formGroup]="headerForm"
      class="navbar-form-custom"
      style="height: auto">
      <ez-form-control-template [control]="headerForm.get('locationId')">
        <app-location-select formControlName="locationId"
          [control]="headerForm.get('locationId')"
          [returnObject]="true"
          [showFilter]='true'
          [confirmLocationChange]='showConfirmationOnLocationChange$ | async'
          (canSwitchLocation)="canSwitchLocation($event)"
          (changed)="selectedLocationChanged($event)">
        </app-location-select>
      </ez-form-control-template>
    </form>
  </div>

  <ng-container *ngIf="isMobileDevice === false">
    <div class="topbar-right">
      <ul class="topbar-menu">
        <li class="help-item"
          *ngIf="enableChatSupport">
          <a class="topbar-menu-item"
            tabindex="0"
            (click)="openZenDeskWidget()">
            <i class="pi pi-comment"></i>
          </a>
        </li>
        <li class="dynamic-menu-item">
          <app-dynamic-menu></app-dynamic-menu>
        </li>
        <li class="help-item">
          <ez-button-icon [isIconOnly]="true"
            [isPrimaryButton]="false"
            [cssClass]="'button-icon-large'"
            [iconClass]="'help-2pt gray-icon'"
            cyName="button-help"
            (click)="openHelpPage()">
          </ez-button-icon>
        </li>
        <li class="help-item">
          <ez-button-icon [isIconOnly]="true"
            [isPrimaryButton]="false"
            [cssClass]="'button-icon-large'"
            [iconClass]="'notification-2pt green-icon'"
            [ngClass]="{'pending-activity':userHasPendingActivityItem$|async}"
            cyName="button-help"
            (click)="onNotificationIconClick()">
          </ez-button-icon>
        </li>
        <li>
          <app-profile-popup></app-profile-popup>
        </li>
      </ul>
    </div>
  </ng-container>
</div>
<!-- Global Notifications -->
<ng-container *ngIf="(banners$ | async)?.length > 0">
  <div class="layout-banners">
    <ez-banner *ngFor='let banner of banners'
      [bannerType]='banner.NotificationType'
      [content]='banner.NotificationText'
      [cyName]='(NotificationScope[banner.NotificationScope] | lowercase) + "-announcement"'
      [showTitle]='banner.NotificationType !== BannerNotificationModel.Survey'
      [bannerContentAlignment]='BannerContentAlignment.Center'
      [showCustomIcon]='false'
      (surveyClicked)="onSurveyClicked()">
    </ez-banner>
  </div>
</ng-container>
<!-- Conditional Banners Notifications -->
<ng-container *ngIf="(conditionalBanners$ | async)?.length > 0">
  <div class="layout-banners conditional-layout-banners">
    <ez-banner *ngFor='let banner of conditionalBanners$ | async'
      [bannerType]='banner.NotificationType'
      [content]='banner.NotificationText'
      [cyName]='(NotificationScope[banner.NotificationScope] | lowercase) + "-feedback"'
      [showTitle]='banner.NotificationType !== BannerNotificationModel.Survey'
      (surveyClicked)="onSurveyClicked()">
    </ez-banner>
  </div>
</ng-container>
<div [id]="toastContainerId"
  class="layout-toast inline-toast-container"></div>
