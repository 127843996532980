import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SessionResponse } from '@app-autogen/ez.authentication/contracts/response/session-response';
import { SessionFacade } from '@src/app/state/session-state/session.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocationModulesEnum } from '@app-core/auth/location-modules-enum';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class LocationSettingsService {

  userSession$: Observable<SessionResponse> = this.sessionFacade.userSession$;
  userSession: SessionResponse;

  constructor(private sessionFacade: SessionFacade) {
    this.userSession$.pipe(
      untilDestroyed(this)
    ).subscribe((userSession: SessionResponse) => this.userSession = userSession);
  }

  getLocationPreferenceStringValue(preferenceCode: string): string {
    if (this.userSession.locationPreferences && this.userSession.locationPreferences[preferenceCode]) {
      return this.userSession.locationPreferences[preferenceCode];
    }
    return null;
  }

  getLocationPreferenceBooleanValue(preferenceCode: string): boolean {
    const hasLocationPreference: string = this.getLocationPreferenceStringValue(preferenceCode);
    return hasLocationPreference ? hasLocationPreference.toLowerCase() === 'true' : false;
  }

  getLocationPreferenceNumberValue(preferenceCode: string): number {
    const locationPreference: string = this.getLocationPreferenceStringValue(preferenceCode);
    return (!isNaN(+locationPreference)) ? Number(locationPreference) : 0;
  }

  locationHasModule(locationModule: LocationModulesEnum): boolean {
    return this.userSession.locationModules && this.userSession.locationModules[LocationModulesEnum[locationModule]];
  }

  locationHasImportantMessage() {
    // important message for location is set with a new location preference.
    // currently this token will change when a new message is shown
    // this is one place to set and find this
    return this.getLocationPreferenceBooleanValue('ShowAWSMaintenanceAlertForLocation');
  }

}
