import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged } from 'rxjs';
import { AuthService } from '@app-core/auth/auth.service';
import { ServerMiddlewareHelperService } from '@app-core/server-middleware-helper/server-middleware-helper.service';
import { EZLayoutService } from '@ezuiaws/ez-packages/ez-layout';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

  @Input() isMobileDevice: boolean = false;
  @Input() sideBarIsOpen: boolean = false;

  tokenExpDate: Date;
  showPopupEvent: Subject<void> = new Subject<void>();

  serviceCode$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  footerIsActive$: Observable<boolean> = this.layoutService.footerIsActive$;
  showMobileSwitchToGridFooterButton$: Observable<boolean> = this.layoutService.showMobileSwitchToGridFooterButton$;

  constructor(
    private authService: AuthService,
    private serverMiddlewareHelperService: ServerMiddlewareHelperService,
    private layoutService: EZLayoutService,
  ) { }

  ngOnInit() {
    this.tokenExpDate = this.authService.getTokenExpirationDate();
    this.configureServiceCode();
  }

  showFeedBackPopup() {
    this.showPopupEvent.next();
  }

  private configureServiceCode() {
    this.serverMiddlewareHelperService.getHubTaskIdFromServer().pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((hubTaskId: string) => {
      const serviceCode: string = hubTaskId + '-00' + this.authService.getUserLocationIdFromLocalStorage().toString();
      this.serviceCode$.next(serviceCode);
    });
  }

  openHelpPage() {
    window.open('https://ezfacility.zendesk.com/hc/en-us', '_blank');
  }

  closeSidebar() {
    if (this.isMobileDevice && this.sideBarIsOpen) {
      this.layoutService.toggleSidebar();
    }
  }

  switchToGridFooter() {
    this.layoutService.changeFooterStatus(false);
  }

}
