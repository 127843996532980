import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'titleCapitalize', pure: false })
export class TitleCapitalizePipe implements PipeTransform {
  transform(text: string, capitalizeAllWords?: boolean): string {
    if (capitalizeAllWords) {
      return this.capitalizeAllWords(text);
    } else {
      return this.capitalizeFirstLetter(text);
    }
  }

  private capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  private capitalizeAllWords(text: string): string {
    return text.split(' ').map(word => this.capitalizeFirstLetter(word)).join(' ');
  }
}
