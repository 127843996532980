import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DatadogService {

    constructor() { }

    public startSessionReplayRecording() {

        if (environment.datadogRumConfig) {

            datadogRum.init({
                applicationId: environment.datadogRumConfig.applicationId,
                clientToken: environment.datadogRumConfig.clientToken,
                site: environment.datadogRumConfig.site,
                service: environment.datadogRumConfig.service,
                env: environment.datadogEnv,
                // Specify a version number to identify the deployed version of your application in Datadog 
                // version: '1.0.0',
                sessionSampleRate: environment.datadogRumConfig.sessionSampleRate,
                sessionReplaySampleRate: environment.datadogRumConfig.sessionReplaySampleRate,
                trackUserInteractions: environment.datadogRumConfig.trackUserInteractions,
                trackResources: environment.datadogRumConfig.trackResources,
                trackLongTasks: environment.datadogRumConfig.trackLongTasks,
                useCrossSiteSessionCookie: environment.datadogRumConfig.useCrossSiteSessionCookie,
                defaultPrivacyLevel: "mask-user-input"
            });

            datadogRum.startSessionReplayRecording();
        }
    }
}