<a class="dynamic-menu-button ez-button-action-primary"
  [ngClass]="{'is-active': (isOpen$ | async) === true }"
  tabindex="0"
  (click)='menu.toggle($event)'>
  <i class="ez-icon white-icon"
    [ngClass]='(isOpen$ | async) ? "action-arrow-up-2pt" : "action-arrow-down-2pt"'>
  </i>
</a>

<p-menu #menu
  [appendTo]="'body'"
  [popup]="true"
  [styleClass]="'dynamic-menu'"
  [model]="dynamicItems$ | async"
  (onHide)='showStatusChanged(false)'
  (onShow)='showStatusChanged(true)'>
</p-menu>

<ng-template appDynamicMenuComponent></ng-template>

<ng-container *ngIf="isMobileView">
  <ez-mobile-overlay [overlayEnabled]="isOpen$ | async"
    [overlayPositionRight]="true"
    (overlayTriggered)="menu.toggle($event)">
  </ez-mobile-overlay>
</ng-container>
