<p-panel [(collapsed)]="_collapsed"
  [styleClass]="styleClass + ' mb-3'"
  [showHeader]="showHeader">
  <p-header>
    <ng-content select="dragHandle"></ng-content>
    <h4 class="d-inline-block panel-title heading-gray"
      [ngClass]="{
        'drag-handle-visible': showDragHandle,
        'text-heading-1' : largePanelTitle === true,
        'text-heading-2': largePanelTitle === false}">
      <span [outerHTML]='panelTitle'></span>
    </h4>
    <span *ngIf="showHeaderLink"
        class="link"
        [attr.data-cy]="'panel-header-link'"
        (click)="onHeaderLinkClick()">
        {{ headerLinkLabel }}
    </span>
    <div class="panel-toolbox">
      <ng-content select="[toolbox]"></ng-content>
      <button pButton
        pRipple
        type="button"
        [icon]="_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
        class="p-button-rounded p-button-secondary p-button-text"
        [ngClass]="{'d-none' : !collapsable}"
        (click)="togglePanel($event)"></button>
      <button *ngIf="showClose"
        (click)="closeClick.emit()"
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        class="p-button-rounded p-button-secondary p-button-text side-modal-close"></button>
    </div>
  </p-header>
  <div [ngClass]="{'mb-3': padBottomContent, 'd-none': _collapsed }">
    <ng-content></ng-content>
  </div>
  <p-footer class="footer-container"
    [ngClass]="{'d-none':!showFooter || _collapsed}">
    <ng-content select="[secondaryActions]"></ng-content>
    <div class="footer-toolbox"
      [ngClass]="{'d-none':!showButtons}">

      <ez-secondary-button *ngIf="showCancel"
        cssClass="mr-2"
        (click)="btnCancelClick()"
        [disabled]="!enableButtons && !enableCancelButton">
      </ez-secondary-button>

      <ez-primary-button [label]="saveButtonText"
        [disabled]="!enableButtons"
        (click)="btnSaveClick()">
      </ez-primary-button>
    </div>
  </p-footer>
</p-panel>