import { FailResponseState } from '@src/app/state/fail-state/fail-state';
import * as FailsActions from '@src/app/state/fail-state/fails.actions';
import { ClientActionFailResponse } from '@app-autogen-socketmodels/client-action-fail-response';
import { ActionWithPayload } from '@ezuiaws/ez-packages/ez-socket';
import { createReducer, on } from '@ngrx/store';

const initialState: FailResponseState = {
  failResponses: []
};

const reducer = createReducer<FailResponseState>(
  initialState,
  on(FailsActions.addFailResponse, (state, { payload }) => {
    return {
      ...state,
      failResponses: [
        ...state.failResponses,
        payload
      ]
    };
  }),
  on(FailsActions.removeFailResponse, (state) => {
    return {
      ...state,
      failResponses: []
    };
  })
);


export function FailResponseReducer(state: FailResponseState | undefined, action: ActionWithPayload) {
  return reducer(state, action);
}
