<form [formGroup]='loginForm'
  (ngSubmit)="loginFormEvent()"
  autocomplete='on'>
  <div class="row form-group">
    <div class="col-lg-12">
      <ez-form-control-input formControlName='username'
        [attr.data-cy]="'user-login-username'"
        [control]='loginForm.controls["username"]'
        [placeholderText]='usernameLabel'
        [autocompleteAttribute]='"username"'
        [validateOnKeyStroke]='true'
        [showLabel]='showLabel'>
      </ez-form-control-input>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-lg-12">
      <ez-form-control-input formControlName='password'
        [attr.data-cy]="'user-login-password'"
        [control]='loginForm.controls["password"]'
        [placeholderText]='passwordLabel'
        [isPassword]='true'
        [validateOnKeyStroke]='true'
        [autocompleteAttribute]='"current-password"'
        [showLabel]='showLabel'>
      </ez-form-control-input>
    </div>
  </div>
  <div class='row form-group'>
    <div class='col-lg-12'>
      <div class="d-inline-block pull-left w-100">
        <ez-primary-button [disabled]='!loginForm.valid'
          [label]="loginButtonText"
          cyName="button-user-login-submit"
          [fullWidth]="true"
          [type]='"submit"'>
        </ez-primary-button>
      </div>
    </div>
  </div>
</form>
