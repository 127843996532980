export enum LocationModulesEnum {
  HasEZleagues = 1,
  HasCheckIn,
  HasQuickbooks,
  HasQuickbooksOnline,
  HasLockers,
  HasPhotoID,
  HasVenueScheduling,
  HasEquipment,
  HasOnlineRegistrations,
  HasSmuRegistrations,
  HasPOS,
  HasPOSv2,
  HasOnlineRentals,
  HasPackages,
  HasAdvancedMemberships,
  HasSmartwaiver,
  HasTimeClock,
  HasEZMessaging,
  HasZoom,
  HasNewEmailCampaign
}
