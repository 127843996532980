<div class="d-inline-flex align-items-center">
  <ez-button-icon [iconClass]="'arrow-left-2pt'"
    [isSecondaryButton]="false"
    (click)="leftClicked()">
  </ez-button-icon>
  <h3 class='ml-2 mr-2 mb-0 text-heading-1'>{{ displayedDateText }}</h3>
  <div class="d-inline mr-2">
    <ez-date-picker [showButtonBar]="false"
      [showInputTextbox]="false"
      [minDate]="minDate"
      [viewFormat]="viewFormat"
      [dateFormat]="dateFormat"
      [showIconOnly]='true'
      (selectedDateChange)="onDatePickerChange($event)">
    </ez-date-picker>
  </div>
  <ez-button-icon [iconClass]="'arrow-right-2pt'"
    [isSecondaryButton]="false"
    (click)="rightClicked()">
  </ez-button-icon>
</div>
