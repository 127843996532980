import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { toCypressName } from '../../helpers';
import { LabelValueViewModel } from '@ezuiaws/ez.ui.models';

@Component({
  selector: 'ez-checkbox-list',
  templateUrl: './ez-checkbox-list.component.html',
  styleUrls: ['./ez-checkbox-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EzCheckboxListComponent<T> implements OnInit {

  _sourceList: LabelValueViewModel[] = [];
  _selectedItems: T[] = [];

  @Input() set sourceList(source: LabelValueViewModel[]) {
    this._sourceList = source;
  }

  @Input() set selectedItems(selectedItems: T[]) {
    this._selectedItems = Array.isArray(selectedItems) ? selectedItems : [];
  }

  @Input() horizontal = true;
  @Input() disabled = false;
  @Input() emptyListMessageText: string = '';

  @Output() targetList: EventEmitter<T[]> = new EventEmitter<T[]>();

  constructor() {
  }

  onChange(selected: boolean, itemValue: T) {
    const itemIndex: number = this._selectedItems.indexOf(itemValue);

    if (selected) {
      itemIndex === -1 ? this._selectedItems.push(itemValue) : null;
    } else {
      itemIndex > -1 ? this._selectedItems.splice(itemIndex, 1) : null;
    }

    this.targetList.emit(this._selectedItems);
  }

  ngOnInit() {
    /*
    Need onChange here to detect nested form element
     */
    // this.onChange();
  }

  cypressName(input: string) {
    return 'checkbox-' + toCypressName(input);
  }
}
