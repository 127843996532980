<div class="overlay-placeholder"
  [ngClass]="{'hasLabel':_hasLabel, 'd-none': !_placeholderVisible}">
  <span class="placeholder-text"
    (click)="placeholderClick()">
    {{_placeholderText}}
  </span>
  <span class="placeholder-link link"
    (click)="linkClick()">
    {{_linkText}}
  </span>
</div>
