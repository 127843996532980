import { datadogRumConfig } from './datadog-rum-config';
import { environment as defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,
  production: true,
  tms_hub_key: 'tmsui',
  tmshub: '/tmsui',
  datadogRumConfig: datadogRumConfig,
  datadogEnv: "prod",
  survey_link: 'https://t.maze.co/269653762'
};
