// NPM/Angular Imports
import { SessionActionTypes } from '@app-autogen/ez.authentication/contracts/action-types/session-action-types';
import { SessionLoggingLevel } from '@app-autogen/ez.core/models/enums/session-logging-level';
import { SessionSupportStatus } from '@app-autogen/ez.core/models/enums/session-support-status';
import { SessionResponse } from '@app-autogen/ez.authentication/contracts/response/session-response';
import { LoginRequest } from '@app-autogen/ez.common/contracts/request/login-request';
import { UserRequest } from '@app-autogen/ez.common/contracts/request/user-request';
import { UserSingleSignOnRequest } from '@app-autogen/ez.common/contracts/request/user-single-sign-on-request';
import { createActionHelper } from '@ezuiaws/ez-packages/ez-store';
import { LoginResultStatuses } from '@app-autogen/ez.authentication/contracts/response/login-result-statuses';

export const userLogout = createActionHelper(SessionActionTypes.UserLogout);
export const userSessionOut = createActionHelper(SessionActionTypes.UserSessionOut);
export const userLogin = createActionHelper<LoginRequest>(SessionActionTypes.UserLogin);
export const userLoginSuccess = createActionHelper<string>(SessionActionTypes.UserLoginSuccess);
export const userLoginFail = createActionHelper<LoginResultStatuses>(SessionActionTypes.UserLoginFail);
export const userSingleSignOnSuccess = createActionHelper<string>(SessionActionTypes.UserSingleSignOnSuccess);
export const userSingleSignOn = createActionHelper<UserSingleSignOnRequest>(SessionActionTypes.UserSingleSignOn);
export const userSingleSignOnFail = createActionHelper<string>(SessionActionTypes.UserSingleSignOnFail);
export const getUserSession = createActionHelper<UserRequest>(SessionActionTypes.GetUserSession);
export const getUserSessionSuccess = createActionHelper<SessionResponse>(SessionActionTypes.GetUserSessionSuccess);
export const setCurrentUserId = createActionHelper<number>(SessionActionTypes.SetCurrentUserId);
export const setCurrentLocationId = createActionHelper<number>(SessionActionTypes.SetCurrentLocationId);
export const setCurrentCompanyId = createActionHelper<number>(SessionActionTypes.SetCurrentCompanyId);
export const setCurrentLocationTimeZone = createActionHelper<string>(SessionActionTypes.SetCurrentLocationTimeZone);
export const addGlobalError = createActionHelper<any>(SessionActionTypes.AddGlobalError);
export const setUserName = createActionHelper<string>(SessionActionTypes.SetUserName);
export const setFirstName = createActionHelper<string>(SessionActionTypes.SetFirstName);
export const setLastName = createActionHelper<string>(SessionActionTypes.SetLastName);
export const setUserCalendarId = createActionHelper<number>(SessionActionTypes.SetUserCalendarId);
export const setLoggingLevel = createActionHelper<SessionLoggingLevel>(SessionActionTypes.SetLoggingLevel);
export const setSupportStatus = createActionHelper<SessionSupportStatus>(SessionActionTypes.SetSupportStatus);
export const setClientConnectionGuid = createActionHelper<string>(SessionActionTypes.SetClientConnectionGuid);
export const clearUserSession = createActionHelper(SessionActionTypes.ClearUserSession);
