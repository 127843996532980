import { Injectable } from '@angular/core';

import * as fromSharedState from './index';
import * as sharedStateActions from './shared-state.actions';

import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { getDateTimeInUserTimeZone } from '@ez-packages';
import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';

import { LocationContactPreferencesResponse } from '@app-autogen/ez.common/contracts/response/location-contact-preferences-response';
import { LabelValueViewModel } from '@app-autogen/ez.common/contracts/response/label-value-view-model';
import { ProgramFeeResponse } from '@app-autogen/ez.common/contracts/response/program-fee-response';
import { MembershipPlanResponse } from '@app-autogen/ez.common/contracts/response/membership-plan-response';
import { LocationResponse } from '@app-autogen/ez.common/contracts/response/location-response';
import { MonitorPerformanceInfoResponse } from '@app-autogen-core-models/monitor/response/monitor-performance-info-response';
import { EventLogResponse } from '@app-autogen/ez.errorlogging/contracts/response/event-log-response';
import { EventLogTimeByRangeRequest } from '@app-autogen/ez.errorlogging/contracts/request/event-log-time-by-range-request';
import { MonitorTimeByRangeRequest } from '@app-autogen-core-models/monitor/request/monitor-time-by-range-request';
import { TaxResponse } from '@app-autogen/ez.common/contracts/response/tax-response';
import { TaxesRequest } from '@app-autogen/ez.common/contracts/request/taxes-request';
import { UiWebResponse } from '@app-autogen-core-models/ui-web/response/ui-web-response';
import { UiWebOptions } from '@app-shared/interfaces/ui-web-options';
import { UpdateUserPreferenceRequest } from '@app-autogen/ez.common/contracts/request/update-user-preference-request';
import { MembershipTypeResponse } from '@app-autogen/ez.common/contracts/response/membership-type-response';
import { ReservationTypeResponse } from '@app-autogen/ez.common/contracts/response/reservation-type-response';
import { ResourceSelectResponse } from '@app-autogen/ez.common/contracts/response/resource-select-response';
import { CustomField } from '@app-autogen/ez.common/contracts/response/custom-field';
import { CashRegister } from '@app-autogen/ez.point-of-sale/contracts/response/cash-register';
import { LockerRoomResponse } from '@app-autogen/ez.locker/contracts/response/locker-room-response';
import { EZLeaguesUserResponse } from '@app-autogen/ez.ezleagues/contracts/response/ez-leagues-user-response';
import { DocumentTemplateResponse } from '@app-autogen/ez.document/contracts/response/document-template-response';

@Injectable()
export class SharedStateFacade {

  cashRegisters$: Observable<CashRegister[]> = this.storeHelper.stateStreamSelector(fromSharedState.getCashRegisters);
  resourcesAndScheduleGroups$: Observable<ResourceSelectResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getResourcesAndScheduleGroups);
  locationContactPreferences$: Observable<LocationContactPreferencesResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getLocationContactPreferences);
  tShirtSizes$: Observable<LabelValueViewModel[]> = this.storeHelper.stateStreamSelector(fromSharedState.getTShirtSizes);
  programFees$: Observable<ProgramFeeResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getProgramFees);
  membershipPlans$: Observable<MembershipPlanResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getMembershipPlans);
  loadingMembershipPlans$: Observable<boolean> = this.storeHelper.stateStreamSelector(fromSharedState.getLoadingMembershipPlans);
  membershipTypes$: Observable<MembershipTypeResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getMembershipTypes);
  documentTemplate$: Observable<DocumentTemplateResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getDocumentTemplate);
  locations$: Observable<LocationResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getLocations);
  taxes$: Observable<TaxResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getTaxes);
  dashboardModel$: Observable<MonitorPerformanceInfoResponse> = this.storeHelper.stateStreamSelector(fromSharedState.getDashboard);
  eventLog$: Observable<EventLogResponse> = this.storeHelper.stateStreamSelector(fromSharedState.getEventLog);
  customFields$: Observable<CustomField[]> = this.storeHelper.stateStreamSelector(fromSharedState.getCustomFields);
  reservationTypes$: Observable<ReservationTypeResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getReservationTypes);
  lockerRooms$: Observable<LockerRoomResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getLockerRooms);
  activitySubjects$: Observable<LabelValueViewModel[]> = this.storeHelper.stateStreamSelector(fromSharedState.getActivitySubjects);
  checkInStations$: Observable<LabelValueViewModel[]> = this.storeHelper.stateStreamSelector(fromSharedState.getCheckInStations);
  jobTypes$: Observable<LabelValueViewModel[]> = this.storeHelper.stateStreamSelector(fromSharedState.getLocationJobTypes);
  smsProviders$: Observable<LabelValueViewModel[]> = this.storeHelper.stateStreamSelector(fromSharedState.getSMSProviders);
  uiWebOptions$: Observable<UiWebOptions> = this.storeHelper.stateStreamSelector(fromSharedState.getUiWebOptions).pipe(
    filter((uiWebOptions: UiWebResponse) => !!uiWebOptions),
    map((uiWebOptions: UiWebResponse) => JSON.parse(uiWebOptions.uiWebOptions))
  );

  resourcesAndScheduleGroupsAutoload$: Observable<ResourceSelectResponse[]> = this.storeHelper.stateAutoLoadSelector(
    fromSharedState.getResourcesAndScheduleGroups,
    sharedStateActions.getResourcesAndScheduleGroups
  );
  membershipPlansAutoload$: Observable<MembershipPlanResponse[]> = this.storeHelper.stateStreamSelector(fromSharedState.getMembershipPlans);

  ezleaguesUser$: Observable<EZLeaguesUserResponse> = this.storeHelper.stateStreamSelector(fromSharedState.getEZLeaguesUser);

  userHasPendingActivityItem$: Observable<boolean> = this.storeHelper.stateStreamSelector(fromSharedState.getUserPendingActivityItem);

  selectLocationById = (locationId: number) => this.storeHelper.stateStreamSelector(fromSharedState.selectLocationById({ id: locationId }));

  constructor(private storeHelper: EzStoreService<fromSharedState.State>) { }

  fetchLocationJobTypes() {
    this.storeHelper.dispatchAction(sharedStateActions.getLocationJobTypesOnLoad());
  }

  fetchSMSProviders() {
    this.storeHelper.dispatchAction(sharedStateActions.getSMSProvidersOnLoad());
  }

  fetchCheckInStations() {
    this.storeHelper.dispatchAction(sharedStateActions.getCheckInStationsOnLoad());
  }

  fetchLockerRooms() {
    this.storeHelper.dispatchAction(sharedStateActions.getLockerRooms());
  }

  fetchCashRegisters() {
    this.storeHelper.dispatchAction(sharedStateActions.getCashRegisters());
  }

  fetchResourcesAndScheduleGroups() {
    this.storeHelper.dispatchAction(sharedStateActions.getResourcesAndScheduleGroups());
  }

  fetchReservationTypes() {
    this.storeHelper.dispatchAction(sharedStateActions.getReservationTypesOnLoad());
  }

  fetchLocationContactPreferences() {
    this.storeHelper.dispatchAction(sharedStateActions.getLocationContactPreferencesOnLoad());
  }

  fetchTShirtSizes() {
    this.storeHelper.dispatchAction(sharedStateActions.getTShirtSizesOnLoad());
  }

  fetchUiWebOptions() {
    this.storeHelper.dispatchAction(sharedStateActions.getUiWebOptions());
  }

  fetchProgramFees() {
    this.storeHelper.dispatchAction(sharedStateActions.getProgramFees());
  }

  fetchMembershipPlans() {
    this.storeHelper.dispatchAction(sharedStateActions.getMembershipPlansOnLoad());
  }

  fetchMembershipTypes() {
    this.storeHelper.dispatchAction(sharedStateActions.getMembershipTypesOnLoad());
  }

  fetchDocumentTemplate() {
    this.storeHelper.dispatchAction(sharedStateActions.getDocumentTemplateOnLoad());
  }


  fetchLocations() {
    this.storeHelper.dispatchAction(sharedStateActions.getLocationsOnLoad());
  }

  fetchLocationInfo() {
    this.storeHelper.dispatchAction(sharedStateActions.getLocationInfoOnLoad());
  }

  fetchDashboard() {
    this.storeHelper.dispatchAction(sharedStateActions.getDashboardOnLoad());
  }

  fetchDashboardWithTime(formModel: MonitorTimeByRangeRequest) {
    this.storeHelper.dispatchAction(sharedStateActions.getDashboardWithTime(formModel));
  }

  fetchEventLog() {
    this.storeHelper.dispatchAction(sharedStateActions.getEventLogOnLoad());
  }

  fetchEventLogTimeByRange(eventModel: EventLogTimeByRangeRequest) {
    this.storeHelper.dispatchAction(sharedStateActions.getEventLogTimeByRange(eventModel));
  }

  fetchTaxes(includeDeactivated: boolean = true, asOfDate?: Date) {
    asOfDate = asOfDate ? asOfDate : new Date(getDateTimeInUserTimeZone());

    const request: TaxesRequest = {
      includeDeactivated: includeDeactivated,
      asOfDate: asOfDate
    };

    this.storeHelper.dispatchAction(sharedStateActions.getTaxes(request));
  }

  fetchLocationCustomFields() {
    this.storeHelper.dispatchAction(sharedStateActions.getCustomFields());
  }

  clearTaxes() {
    // TODO create and use 'clearTaxesState' action instead
    this.storeHelper.dispatchAction(sharedStateActions.getTaxesSuccess([]));
  }

  updateUserPreference(preferenceCode: string, preferenceValue: string) {
    const request: UpdateUserPreferenceRequest = {
      preferenceCode: preferenceCode,
      preferenceValue: preferenceValue
    };
    this.storeHelper.dispatchAction(sharedStateActions.updateUserPreference(request));
  }

  fetchEZLeaguesUser(userId: number) {
    this.storeHelper.dispatchAction(sharedStateActions.getEZLUser(userId));
  }

  userHasPendingActivityItem(userId: number) {
    this.storeHelper.dispatchAction(sharedStateActions.hasUserPendingActivityItem(userId));
  }

  clearMembershipPlansState() {
    this.storeHelper.dispatchAction(sharedStateActions.clearMembershipPlansState());
  }
}
