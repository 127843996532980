import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowRelationshipPipe } from './show-relationship.pipe';
import { EscapeHtmlPipe } from './escape-html.pipe';
import { UncamelcasePipe } from './uncamelcase.pipe';
import { SessionLoginLevelPipe } from './session-login-level.pipe';
import { SessionSupportStatusPipe } from './session-support-status.pipe';
import { LongToDotsPipe } from './long-to-dots.pipe';
import { CheckOrSlashPipe } from './check-or-slash.pipe';
import { EzDatePipe } from './ez-date.pipe';
import { TitleCapitalizePipe } from './title-capitalize.pipe';

@NgModule({
  declarations: [
    ShowRelationshipPipe,
    EscapeHtmlPipe,
    UncamelcasePipe,
    SessionLoginLevelPipe,
    SessionSupportStatusPipe,
    LongToDotsPipe,
    CheckOrSlashPipe,
    EzDatePipe,
    TitleCapitalizePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ShowRelationshipPipe,
    EscapeHtmlPipe,
    UncamelcasePipe,
    SessionLoginLevelPipe,
    SessionSupportStatusPipe,
    LongToDotsPipe,
    CheckOrSlashPipe,
    EzDatePipe,
    TitleCapitalizePipe
  ]
})
export class EzSharedPipesModule { }
