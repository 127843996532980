<div class="p-inputgroup app-control">
  <ng-container *ngIf='showIcon'>
    <span class="p-inputgroup-addon"
      (click)="showOverlay()">
      <i class="pi pi-calendar"></i>
    </span>
  </ng-container>

  <ng-container *ngIf='showIconOnly'>
    <ez-button-icon [isIconOnly]='true'
      [isSecondaryButton]="true"
      [cssClass]="'button-icon-medium'"
      [iconClass]="'calendar-2pt'"
      [attr.data-cy]="'date-picker-button-icon'"
      (click)="showOverlay()">
    </ez-button-icon>
  </ng-container>

  <p-calendar #calendar
    [ngModel]="(_selectedDateSubject | async)"
    [hourFormat]="hourFormat"
    [dateFormat]="_dateFormat"
    [timeOnly]="timeOnly"
    [readonlyInput]="readonlyInput"
    [showTime]="showTime"
    [showButtonBar]="showButtonBar"
    [showSeconds]="showSeconds"
    [disabled]="disabled"
    [view]="viewFormat"
    [placeholder]="placeholder"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [stepMinute]='stepMinute'
    [stepHour]='stepHour'
    [stepSecond]='stepSecond'
    [appendTo]="appendTo"
    [inputStyleClass]="showInputTextbox ? '' : 'hidden'"
    [attr.data-cy]="cypressName + '-date-picker'"
    [clearButtonStyleClass]="clearCssClass"
    (onInput)='onValueInput()'
    (onSelect)="onValueSelect($event)"
    (onClearClick)="onClearClick()"
    (onTodayClick)="setTodayDateTime()"
    (onClose)="onClose()"
    (onShow)="onShow()">
  </p-calendar>
</div>
