import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '@app-root/state/app.state';

import { SharedState } from './shared-state';
import { LocationResponse } from '@app-autogen/ez.common/contracts/response/location-response';

export interface State extends fromRoot.State {
  appShared: SharedState;
}

// Selector functions
const getSharedFeatureState = createFeatureSelector<SharedState>('appShared');

// TODO
// getTrainers was originally added for the trainer drop-down, but that was changed to use the new shared resource-select drop down.
// getTrainers was left here because it will be used by the admin page once we get there.
export const getTrainers = createSelector(
  getSharedFeatureState,
  state => state.trainers
);

// TODO
// getSalesPeople was originally added for the sales people drop-down, but that was changed to use the new shared resource-select drop down.
// getSalesPeople was left here because it will be used by the admin page once we get there.
export const getSalesPeople = createSelector(
  getSharedFeatureState,
  state => state.salesPeople
);

export const getLocations = createSelector(
  getSharedFeatureState,
  state => state.locations
);

export const selectLocationById = (props: { id: number }) =>
  createSelector(getLocations, (locations: LocationResponse[]) => {
    return locations.find(l => l.locationId === props.id);
  });

export const getActivitySubjects = createSelector(
  getSharedFeatureState,
  state => state.activitySubjects
);

export const getSMSProviders = createSelector(
  getSharedFeatureState,
  state => state.smsProviders
);

export const getLocationInfo = createSelector(
  getSharedFeatureState,
  state => state.locationInfo
);

export const getCheckInStations = createSelector(
  getSharedFeatureState,
  state => state.checkInStations
);

export const getProgramFees = createSelector(
  getSharedFeatureState,
  state => state.programFees
);

export const getTaxes = createSelector(
  getSharedFeatureState,
  state => state.taxes
);

export const getLocationContactPreferences = createSelector(
  getSharedFeatureState,
  state => state.locationContactPreferences
);

export const getResourcesAndScheduleGroups = createSelector(
  getSharedFeatureState,
  state => state.resources
);

export const getReservationTypes = createSelector(
  getSharedFeatureState,
  state => state.reservationTypes
);

export const getTShirtSizes = createSelector(
  getSharedFeatureState,
  state => state.tShirtSizes
);

export const getMembershipPlans = createSelector(
  getSharedFeatureState,
  state => state.membershipPlans
);

export const getLoadingMembershipPlans = createSelector(
  getSharedFeatureState,
  state => state.loadingMembershipPlans
);

export const getMembershipTypes = createSelector(
  getSharedFeatureState,
  state => state.membershipTypes
);
export const getDocumentTemplate = createSelector(
  getSharedFeatureState,
  state => state.documentTemplates
);

export const getLocationJobTypes = createSelector(
  getSharedFeatureState,
  state => state.locationJobTypes
);

export const getLockerRooms = createSelector(
  getSharedFeatureState,
  state => state.lockerRooms
);

export const getDashboard = createSelector(
  getSharedFeatureState,
  state => state.dashboard
);

export const getEventLog = createSelector(
  getSharedFeatureState,
  state => state.eventLogs
);

export const getUiWebOptions = createSelector(
  getSharedFeatureState,
  state => state.uiWebOptions
);

export const getCustomFields = createSelector(
  getSharedFeatureState,
  state => state.customFields
);

export const getCashRegisters = createSelector(
  getSharedFeatureState,
  state => state.cashRegisters
);

export const getUserPendingActivityItem = createSelector(
  getSharedFeatureState,
  state => state.userHasPendingActivityItem
);

export const getEZLeaguesUser = createSelector(getSharedFeatureState, (state) => state.ezleaguesUser);
