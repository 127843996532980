import { Injectable } from '@angular/core';
import { TmsMessage } from '@app-autogen/ez.common/contracts/reference-objects/tms-message';
import { AuthService } from '@app-core/auth/auth.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TmsMessageService {

  private tmsMessages = new Subject<TmsMessage>();
  tmsMessages$ = this.tmsMessages.asObservable();

  trustedOrigins: string[] = [];

  constructor(private authService: AuthService) {
    this.trustedOrigins = this.authService.uiWebOptions.BaseTMSUrl;
    // this.trustedOrigins = ['https://local.tms.ezfacility.com'];

    window.addEventListener('message', (msg) => {
      if (!this.trustedOrigins.filter(t => t.startsWith(msg.origin)).length
        || !msg.data) {
        return;
      }

      let messageDataJson;
      // test that the data is parsable JSON
      try {
        messageDataJson = JSON.parse(msg.data);
      } catch (error) {
        return;
      }

      if (messageDataJson.MessageType) {
        this.tmsMessages.next(messageDataJson);
      }

      // console.log('TMS Message Received: ' + msg.data);

    }, false);
  }

  sendMessageToTmsIframe(iframeId: string, message: any) {
    if (iframeId) {
      const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
        input !== null && input.tagName === 'IFRAME';
      const frame = document.getElementById(iframeId);
      if (isIFrame(frame) && frame.contentWindow) {
        frame.contentWindow.postMessage(message, '*');
      }
    }
  }
}
