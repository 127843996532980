import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkOrSlash'
})
export class CheckOrSlashPipe implements PipeTransform {

  transform(value: boolean): string {

    let result: string;

    if (value) {
      result = '<i class="fa fa-check text-info"/>';
    } else {
      result = '<i class="fa fa-ban text-danger"/>';
    }

    return result;
  }
}
