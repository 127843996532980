import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DropdownModule } from 'primeng/dropdown';

import { FullCalendarModule } from '@fullcalendar/angular';

import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';

import { EzComponentsModule } from '../ez-components';
import { EzCalendarComponent } from './components/ez-calendar/ez-calendar.component';

import { EzLocalizationModule } from '@ezuiaws/ez-packages/ez-localization';
import { EzButtonsModule } from '@ezuiaws/ez-packages/ez-buttons';
import { EzSharedDirectivesModule } from '../directives';

@NgModule({
  declarations: [
    EzCalendarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    DropdownModule,
    EzButtonsModule,
    EzComponentsModule,
    ButtonModule,
    RippleModule,
    TooltipModule,
    EzLocalizationModule,
    EzSharedDirectivesModule
  ],
  exports: [
    EzCalendarComponent
  ]
})
export class EzScheduleModule { }
