<form [formGroup]='form'>
  <div class="form-group d-flex mr-2 split-input-dropdown">
    <ez-form-control-input [control]='form.controls["price"]'
      formControlName='price'
      [attr.data-cy]="cypressName + '_price'"
      [placeholderText]='inputPlaceholder'
      [isCurrency]='true'
      [showPreAddon]='true'
      [showLabel]='showLabel'>
    </ez-form-control-input>
    <div>
      <ez-form-control-template [control]="form.get('perOption')">
        <p-dropdown formControlName="perOption"
          name="perOption"
          optionLabel="label"
          optionValue="value"
          styleClass="d-block"
          [attr.data-cy]="cypressName + '_perOption'"
          [options]="_options"
          [showClear]="false"
          [overlayOptions]="{appendTo: 'body', styleClass: 'ez-select-overlay-panel'}"
          detectDropdownPanelShowDirection>
        </p-dropdown>
      </ez-form-control-template>
    </div>
  </div>
</form>
