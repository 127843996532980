<div class="ez-time-picker"
  [ngClass]="{'time-gb': hourFormat !== '12'}">
  <div class="w-100">
    <p-inputMask #txtInput
      mask="99:99"
      [ngModel]="_selectedTime"
      [slotChar]='placeholder'
      [placeholder]='placeholder'
      [disabled]='disabled'
      [attr.data-cy]="cypressName +  '-time-picker-input'"
      [styleClass]='"form-control"'
      (onBlur)='onValueChange(txtInput.value)'>
    </p-inputMask>
  </div>
  <div>
    <p-dropdown *ngIf='hourFormat === "12"'
      name="drpScheduleType"
      optionLabel="label"
      optionValue='value'
      [attr.data-cy]="cypressName + '-time-picker-meridian-selector'"
      [ngModel]='_selectedMeridianMode'
      [options]="meridianOptions"
      [disabled]='disabled'
      (onChange)="onMeridianModeChange($event.value)"
      [overlayOptions]="{appendTo: 'body', styleClass: 'ez-select-overlay-panel'}"
      detectDropdownPanelShowDirection>
    </p-dropdown>
  </div>
</div>


