import { ClientActionTypes } from '@app-autogen/ez.client/contracts/action-types/client-action-types';
import { GetClientIsAccessibleForUserRequest } from '@app-autogen/ez.client/contracts/request/get-client-is-accessible-for-user-request';
import { GetClientIsAccessibleForUserResponse } from '@app-autogen/ez.client/contracts/response/get-client-is-accessible-for-user-response';
import { createActionHelper } from '@ezuiaws/ez-packages/ez-store';

export const clearClientIsAccessibleForUser = createActionHelper(ClientActionTypes.ClearClientIsAccessibleForUser);

export const getClientIsAccessibleForUser = createActionHelper<GetClientIsAccessibleForUserRequest>(ClientActionTypes.ClientIsAccessibleForUser);
export const getClientIsAccessibleForUserSuccess = createActionHelper<GetClientIsAccessibleForUserResponse>(ClientActionTypes.ClientIsAccessibleForUserSuccess);
export const getClientIsAccessibleForUserFail = createActionHelper<GetClientIsAccessibleForUserResponse>(ClientActionTypes.ClientIsAccessibleForUserFail);
