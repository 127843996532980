import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import * as AttendanceStatusActions from './attendance-status.actions';

import { LocalizationService } from '@app-core/localization/localization.service';
import { EzNotificationService } from '@ezuiaws/ez-packages/ez-notification';

import { NotificationType } from '@app-autogen/ez.common/enums/notification-type';
import { UpdateAttendanceStatusResponseType } from '@app-autogen/ez.attendance/contracts/response/update-attendance-status-response-type';
import { AttendanceStatuses } from '@app-autogen/ez.common/contracts/response/attendance-statuses';

@Injectable()
export class AttendanceStatusEffects {
  constructor(private actions$: Actions, private notificationService: EzNotificationService) {}

  updateClientAttendanceStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AttendanceStatusActions.updateAttendanceStatusOnClickSuccess),
        tap(({ payload }) => {
          switch (payload.responseType) {
            case UpdateAttendanceStatusResponseType.Success:
              if (payload.attendanceStatus === AttendanceStatuses.Scheduled) {
                this.notificationService.sendSuccessNotification({
                  type: NotificationType.success,
                  title: LocalizationService.rks.StatusUpdated,
                  message: LocalizationService.rks.ClientsScheduled,
                });
              } else if (payload.attendanceStatus === AttendanceStatuses.Waitlist) {
                this.notificationService.sendSuccessNotification({
                  type: NotificationType.success,
                  title: LocalizationService.rks.StatusUpdated,
                  message: LocalizationService.rks.ClientsWaitlisted,
                });
              } else {
                this.notificationService.sendSuccessNotification({
                  type: NotificationType.success,
                  title: LocalizationService.rks.StatusUpdated,
                  message: LocalizationService.rks.Success,
                });
              }

              break;
            case UpdateAttendanceStatusResponseType.CustomErrorMessage:
              this.notificationService.sendFailNotification({
                type: NotificationType.error,
                title: LocalizationService.rks.AttendanceStatusNotChanged,
                message: payload.failureReason,
              });
              break;
            case UpdateAttendanceStatusResponseType.PermissionError:
              this.notificationService.sendFailNotification({
                type: NotificationType.error,
                title: LocalizationService.rks.AttendanceStatusNotChanged,
                message: LocalizationService.rks.InvalidChangeAttendanceStatusPermissionError(
                  payload.failureCount
                ),
              });
              break;
            case UpdateAttendanceStatusResponseType.ClassIsFull:
              this.notificationService.sendFailNotification({
                type: NotificationType.error,
                title: LocalizationService.rks.AttendanceStatusNotChanged,
                message: LocalizationService.rks.InvalidChangeAttendanceStatusClassIsFull(
                  payload.failureCount
                ),
              });
              break;
            case UpdateAttendanceStatusResponseType.WaitlistSelectPackage:
            case UpdateAttendanceStatusResponseType.ReservedToScheduleOneClient:
            default:
              // should be handled on front end already
              console.log(
                '%cPayload',
                'background-color:purple;color:yellow',
                payload.failureCount
              );
          }
        })
      ),
    { dispatch: false }
  );

  updateClientAttendanceStatusFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AttendanceStatusActions.updateAttendanceStatusOnClickFail),
        tap(({ payload }) => {
          this.notificationService.sendFailNotification({
            type: NotificationType.error,
            title: LocalizationService.rks.AttendanceStatusNotChanged,
            message: payload,
          });
        })
      ),
    { dispatch: false }
  );
}
