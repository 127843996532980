import { ChangeDetectionStrategy, Component, OnInit, Renderer2, Inject } from '@angular/core';
import { ServerMiddlewareHelperService } from '@app-core/server-middleware-helper/server-middleware-helper.service';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
// import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from '@app-core/auth/auth.service';
import { SharedStateFacade } from './state/shared-state/shared-state.facade';
import { UiWebOptions } from '@app-shared/interfaces/ui-web-options';
import { Router } from '@angular/router';

import { EzNotificationService } from '@ezuiaws/ez-packages/ez-notification';
import { LocationSettingsService } from '@app-core/auth/location-settings.service';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  applicationIsInitialized$: Observable<boolean>;
  environment: string;

  uiWebOptions$: Observable<UiWebOptions> = this.sharedStateFacade.uiWebOptions$;
  uiWebOptions: UiWebOptions;

  constructor(
    private authService: AuthService,
    private sharedStateFacade: SharedStateFacade,
    private notificationService: EzNotificationService,
    private serverMiddlewareHelperService: ServerMiddlewareHelperService,
    private locationSettingsService: LocationSettingsService,
    public router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
  }

  ngOnInit() {
    this.applicationIsInitialized$ = this.authService.getApplicationIsInitialized();
    this.applicationIsInitialized$.pipe(
      filter(applicationIsInitialized => applicationIsInitialized),
      untilDestroyed(this)
    ).subscribe(() => {
      this.notificationService.startListenNotifications();
      this.notificationService.fetchBanners();
      this.serverMiddlewareHelperService.getAllActionHandlersFromServer();
      this.serverMiddlewareHelperService.upsertSessionDataOnServer();
      this.serverMiddlewareHelperService.upsertClientLocationDataOnHub();
      this.sharedStateFacade.fetchUiWebOptions();

      this.environment = this.authService.getEnvironment();
      localStorage.setItem('environment', this.environment);

      this.getUiWebOptions();
      this.addZenDeskChatSupportScript();
    });
  }

  private getUiWebOptions() {
    this.uiWebOptions$
      .pipe(take(1))
      .subscribe((uiWebOptions: UiWebOptions) => {
        this.uiWebOptions = uiWebOptions;
        this.addGoogleAnalyticsScript();
      });
  }

  private addGoogleAnalyticsScript() {
    const firstTag = document.createElement('script'); // creates the script tag
    firstTag.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.uiWebOptions.GoogleAnalyticsTrackingCode; // sets the source (insert url in between quotes)
    firstTag.async = true; // makes script run asynchronously
    document.getElementsByTagName('head')[0].appendChild(firstTag);

    const secondTag = document.createElement('script'); // creates the script tag
    secondTag.innerHTML = 'window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "' + this.uiWebOptions.GoogleAnalyticsTrackingCode + '", {"send_page_view": false});'; // sets the source (insert url in between quotes)
    document.getElementsByTagName('head')[0].appendChild(secondTag);
  }

  private addZenDeskChatSupportScript() {
    const enableChatSupport: boolean = this.locationSettingsService.getLocationPreferenceBooleanValue('EnableChatSupport');
    if (enableChatSupport) {
      const script = this.renderer.createElement('script');
      script.id = 'ze-snippet';
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=e7718d99-34f5-47ff-a0a9-91b129a9b470';
      this.renderer.appendChild(this._document.body, script);

      if (typeof window['zE'] !== 'undefined') {
        window['zE']('messenger', 'close');
      }
    }
  }
}
