import { Injectable } from '@angular/core';

import * as fromAppDetails from '@src/app/state/app-details-state';
import * as appDetailsActions from '@src/app/state/app-details-state/app-details-actions';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';

import { HubConnectionActivityResponse } from '@app-autogen-core-models/support/response/hub-connection-activity-response';
import { BrowserResponse } from '@app-autogen-core-models/browser/response/browser-response';
import { BrowserLocationResponse } from '@app-autogen-core-models/browser/response/browser-location-response';
import { HubActivity } from '@app-autogen-core-models/support/response/hub-activity';

@Injectable()
export class AppDetailsFacade {

  applicationInitialized$: Observable<boolean> = this.storeHelper.stateStreamSelector(fromAppDetails.getApplicationInitialized);
  hubConnectionActivity$: Observable<HubConnectionActivityResponse> = this.storeHelper.stateStreamSelector(fromAppDetails.getHubConnectionActivity);
  browserDetails$: Observable<BrowserResponse> = this.storeHelper.stateStreamSelector(fromAppDetails.getBrowserDetails);
  browserLocationDetails$: Observable<BrowserLocationResponse> = this.storeHelper.stateStreamSelector(fromAppDetails.getBrowserLocationDetails);
  userIPAddress$: Observable<string> = this.storeHelper.stateStreamSelector(fromAppDetails.getBrowserLocationDetails).pipe(
    map((response: BrowserLocationResponse) => {
      return response ? response.query : null;
    })
  );

  constructor(private storeHelper: EzStoreService<fromAppDetails.State>) { }

  setAppInitiated(status: boolean) {
    this.storeHelper.dispatchAction(appDetailsActions.setAppInitiated(status));
  }

  setHubConnected(status: boolean) {
    this.storeHelper.dispatchAction(appDetailsActions.setHubConnected(status));
  }

  logConnectionActivity(hubActivity: HubActivity) {
    this.storeHelper.dispatchAction(appDetailsActions.logConnectionActivity(hubActivity));
  }

  setBrowserDetails(browserDetails: BrowserResponse) {
    this.storeHelper.dispatchAction(appDetailsActions.setBrowserDetails(browserDetails));
  }

  setBrowserLocation(locationDetails: BrowserLocationResponse) {
    this.storeHelper.dispatchAction(appDetailsActions.setBrowserLocation(locationDetails));
  }

  clearState() {
    // todo add action type
  }
}
