import { createReducer, on } from '@ngrx/store';
import { AttendanceStatusInfo } from '@app-autogen/ez.common/contracts/response/attendance-status-info';
import { UpdateAttendanceStatusResult } from '@app-autogen/ez.attendance/contracts/response/update-attendance-status-result';
import * as AttendanceStatusActions from './attendance-status.actions';

export const ATTENDANCE_STATUSES_FEATURE_KEY = 'clientAttendanceStatuses';

export interface State {
  attendanceStatuses: AttendanceStatusInfo[];
  updateStatusResult: UpdateAttendanceStatusResult;
}

export interface AttendanceStatusState {
  readonly [ATTENDANCE_STATUSES_FEATURE_KEY]: State;
}

const initialState: State = {
  attendanceStatuses: [],
  updateStatusResult: null
};

export const clientAttendanceStatusReducer = createReducer<State>(
  initialState,
  on(AttendanceStatusActions.getAttendanceStatusesOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      attendanceStatuses: payload.attendanceStatuses
    };
  }),

  on(AttendanceStatusActions.getAttendanceStatusesOnLoadFail, (state) => {
    return {
      ...state,
      attendanceStatuses: initialState.attendanceStatuses
    };
  }),

  on(AttendanceStatusActions.clearUpdateResultStateOnLoad, (state) => {
    return {
      ...state,
      updateStatusResult: null
    };
  }),
  on(AttendanceStatusActions.updateAttendanceStatusOnClickSuccess, (state, { payload }) => {
    return {
      ...state,
      updateStatusResult: payload
    };
  }),

  on(AttendanceStatusActions.updateAttendanceStatusOnClickFail, (state) => {
    return {
      ...state,
      updateStatusResult: null
    };
  })
);

