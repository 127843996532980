import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as fromSession from './index';
import * as sessionActions from './session.actions';

import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';

import { Observable } from 'rxjs';
import { SessionResponse } from '@app-autogen/ez.authentication/contracts/response/session-response';
import { LoginRequest } from '@app-autogen/ez.common/contracts/request/login-request';
import { UserSingleSignOnRequest } from '@app-autogen/ez.common/contracts/request/user-single-sign-on-request';
import { UserRequest } from '@app-autogen/ez.common/contracts/request/user-request';

@Injectable()
export class SessionFacade {
  userSession$: Observable<SessionResponse> = this.storeHelper.stateStreamSelector(fromSession.getCurrentState);
  userName$: Observable<string> = this.storeHelper.stateStreamSelector(fromSession.getFullName);
  userCurrentLocationId$: Observable<number> = this.storeHelper.stateStreamSelector(fromSession.getCurrentLocationId);
  userCurrentLocationCulture$: Observable<string> = this.storeHelper.stateStreamSelector(fromSession.getCurrentLocationCulture);
  userCurrencyCode$: Observable<string> = this.storeHelper.stateStreamSelector(fromSession.getCurrentLocationCurrencyCode);

  constructor(private storeHelper: EzStoreService<fromSession.State>) { }

  fetchUserSession(jwt: string, locationId: number) {
    const request: UserRequest = {
      token: jwt,
      locationId: locationId,
    };
    this.storeHelper.dispatchAction(sessionActions.getUserSession(request));
  }

  fetchUserLogin(credentials: LoginRequest) {
    this.storeHelper.dispatchAction(sessionActions.userLogin(credentials));
  }

  fetchUserLogout() {
    this.storeHelper.dispatchAction(sessionActions.userLogout());
  }

  setCurrentLocationId(locationId: number) {
    this.storeHelper.dispatchAction(sessionActions.setCurrentLocationId(locationId));
  }

  addGlobalError(error: HttpErrorResponse) {
    this.storeHelper.dispatchAction(sessionActions.addGlobalError(error));
  }

  fetchSingleSignOn(userId: number, locationId: number, singleSignOnJwt: string) {
    const request: UserSingleSignOnRequest = {
      userId: userId,
      locationId: locationId,
      singleSignOnJwt: singleSignOnJwt
    };
    this.storeHelper.dispatchAction(sessionActions.userSingleSignOn(request));
  }

  clearState() {
    this.storeHelper.dispatchAction(sessionActions.clearUserSession());
  }
}
