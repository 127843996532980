<div class="footer app-footer" 
  [ngStyle]="{'z-index': (footerIsActive$ | async) ? 3 : 2}">
  <ng-container *ngIf="isMobileDevice === false; else mobileView">
    <div class="service-code">
      <i class="ez-icon phone-2pt icon-small"></i>
      <span class="text-small"> {{ "ServiceCode" | ezLocale | uppercase }}: </span>
      <span class="text-small">
        {{ serviceCode$ | async }}
      </span>
    </div>
    <div class="text-small">
      {{ "SessionExpirationMessage" | ezLocale }}
      {{ tokenExpDate | date : "medium" }}
      <!-- <a href="https://support.ezfacility.com/hc/en-us/categories/200071757-Product-news-and-updates">Release Notes</a> | -->
      <!--    <a href="https://ezfacility.uservoice.com/forums/64505">Suggest Ideas</a> |-->
      <button class="link feedback-btn" (click)="showFeedBackPopup()">
        {{ "GiveFeedback" | ezLocale }}
      </button>
      <a class="policy-link" 
        target="_blank"
        href="https://www.ezfacility.com/privacy-policy">
        {{ "PrivacyPolicy" | ezLocale }}
      </a>
    </div>
  </ng-container>
  <ng-template #mobileView>
    <ng-container *ngIf="showMobileSwitchToGridFooterButton$ | async">
      <ez-button-icon [cyName]="'switch-to-grid-footer-btn'"
        [cssClass]="'button-icon-medium switch-to-grid-footer-btn'"
        [iconClass]="'list-2pt'"
        [isIconOnly]="true"
        [isSecondaryButton]="true"
        (click)="switchToGridFooter()">
      </ez-button-icon>
    </ng-container>
    <app-dynamic-menu [isMobileView]="isMobileDevice"
      (dynamicMenuOpened)="closeSidebar()">
    </app-dynamic-menu>
    <a class="help-page-link" (click)="openHelpPage()">
      <i class="green-icon help-2pt ez-icon"></i>
    </a>
    <app-profile-popup [isMobileView]="isMobileDevice"
      (profilePopupOpened)="closeSidebar()">
    </app-profile-popup>
  </ng-template>
</div>

<app-feedback-form
  [showPopup]="showPopupEvent.asObservable()">
</app-feedback-form>
