import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  forwardRef,
  Input
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ez-form-control-switch',
  templateUrl: './ez-form-control-switch.component.html',
  styleUrls: ['./ez-form-control-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzFormControlSwitchComponent),
      multi: true
    }
  ]
})
export class EzFormControlSwitchComponent implements OnInit, ControlValueAccessor {
  @ViewChild('input', { static: true }) inputRef: ElementRef;

  @Input() control: AbstractControl;
  @Input() isDisabled: boolean = false;
  @Input() horizontalAlignment: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() toolTipHtml: string = null;
  @Input() cyName: string = 'ez-switch-button';
  
  checked = false;

  propagateChange: (value: {}) => void;
  propagateTouched: () => void;

  constructor() { }

  ngOnInit() { }

  writeValue(value: boolean): void {
    this.checked = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange(event) {
    this.checked = !this.checked;
    this.propagateChange(this.checked);
  }

  onTouched() {
    this.propagateTouched();
  }
}
