import { SharedState } from './shared-state';
import { ActionWithPayload } from '@ezuiaws/ez-packages/ez-socket';
import { createReducer, on } from '@ngrx/store';
import { MonitorPerformanceInfoResponse } from '@app-autogen/ez.core/contracts/monitor/response/monitor-performance-info-response';
import { EventLogResponse } from '@app-autogen/ez.errorlogging/contracts/response/event-log-response';

import * as SharedStateActions from './shared-state.actions';

const initialState: SharedState = {
  trainers: [],
  salesPeople: [],
  locations: null,
  activitySubjects: [],
  smsProviders: [],
  locationInfo: null,
  locationContactPreferences: null,
  checkInStations: [],
  programFees: [],
  taxes: [],
  resources: null,
  reservationTypes: null,
  tShirtSizes: null,
  membershipTypes: [],
  documentTemplates: [],
  membershipPlans: null,
  loadingMembershipPlans: true,
  locationJobTypes: [],
  lockerRooms: [],
  dashboard: new MonitorPerformanceInfoResponse(),
  eventLogs: new EventLogResponse(),
  uiWebOptions: null,
  customFields: null,
  cashRegisters: null,
  ezleaguesUser: null,
  userHasPendingActivityItem: null
};
const reducer = createReducer<SharedState>(
  initialState,
  on(SharedStateActions.getTrainersOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      trainers: payload
    };
  }),

  on(SharedStateActions.getTrainersOnLoadFail, (state) => {
    return {
      ...state,
      trainers: []
    };
  }),

  on(SharedStateActions.getSalesPeopleOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      salesPeople: payload
    };
  }),

  on(SharedStateActions.getSalesPeopleOnLoadFail, (state) => {
    return {
      ...state,
      salesPeople: []
    };
  }),

  on(SharedStateActions.getLocationsOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      locations: payload
    };
  }),

  on(SharedStateActions.getLocationsOnLoadFail, (state) => {
    return {
      ...state,
      locations: []
    };
  }),

  on(SharedStateActions.getSMSProvidersOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      smsProviders: payload
    };
  }),

  on(SharedStateActions.getSMSProvidersOnLoadFail, (state) => {
    return {
      ...state,
      smsProviders: []
    };
  }),

  on(SharedStateActions.getLocationInfoOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      locationInfo: payload
    };
  }),

  on(SharedStateActions.getLocationInfoOnLoadFail, (state) => {
    return {
      ...state,
      locationInfo: null
    };
  }),

  on(SharedStateActions.getCheckInStationsOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      checkInStations: payload
    };
  }),

  on(SharedStateActions.getCheckInStationsOnLoadFail, (state) => {
    return {
      ...state,
      checkInStations: null
    };
  }),

  on(SharedStateActions.getProgramFeesSuccess, (state, { payload }) => {
    return {
      ...state,
      programFees: payload
    };
  }),

  on(SharedStateActions.getProgramFeesFail, (state) => {
    return {
      ...state,
      programFees: null
    };
  }),

  on(SharedStateActions.getTaxesSuccess, (state, { payload }) => {
    return {
      ...state,
      taxes: payload
    };
  }),

  on(SharedStateActions.getTaxesFail, (state) => {
    return {
      ...state,
      taxes: null
    };
  }),

  on(SharedStateActions.getLocationContactPreferencesOnLoadSuccess,
    (state, { payload }) => {
      return {
        ...state,
        locationContactPreferences: payload
      };
    }),

  on(SharedStateActions.getResourcesAndScheduleGroupsSuccess, (state, { payload }) => {
    return {
      ...state,
      resources: payload
    };
  }),

  on(SharedStateActions.getResourcesAndScheduleGroupsFail, (state) => {
    return {
      ...state,
      resources: []
    };
  }),

  on(SharedStateActions.getReservationTypesOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      reservationTypes: payload
    };
  }),

  on(SharedStateActions.getReservationTypesOnLoadFail, (state) => {
    return {
      ...state,
      reservationTypes: []
    };
  }),

  on(SharedStateActions.getTShirtSizesOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      tShirtSizes: payload
    };
  }),

  on(SharedStateActions.getTShirtSizesOnLoadFail, (state) => {
    return {
      ...state,
      tShirtSizes: []
    };
  }),

  on(SharedStateActions.getMembershipPlansOnLoad, (state) => {
    return {
      ...state,
      loadingMembershipPlans: true
    };
  }),

  on(SharedStateActions.getMembershipPlansOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      membershipPlans: payload,
      loadingMembershipPlans: false
    };
  }),

  on(SharedStateActions.getMembershipPlansOnLoadFail, (state) => {
    return {
      ...state,
      membershipPlans: initialState.membershipPlans,
      loadingMembershipPlans: false
    };
  }),

  on(SharedStateActions.getMembershipTypesOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      membershipTypes: payload
    };
  }),

  on(SharedStateActions.getMembershipTypesOnLoadFail, (state) => {
    return {
      ...state,
      membershipTypes: []
    };
  }),
  on(SharedStateActions.getDocumentTemplateOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      documentTemplates: payload
    };
  }),

  on(SharedStateActions.getDocumentTemplateOnLoadFail, (state) => {
    return {
      ...state,
      documentTemplates: []
    };
  }),

  on(SharedStateActions.getLocationJobTypesOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      locationJobTypes: payload
    };
  }),

  on(SharedStateActions.getLocationJobTypesOnLoadFail, (state) => {
    return {
      ...state,
      locationJobTypes: []
    };
  }),

  on(SharedStateActions.getLockerRoomsSuccess, (state, { payload }) => {
    return {
      ...state,
      lockerRooms: payload
    };
  }),

  on(SharedStateActions.getLockerRoomsFail, (state) => {
    return {
      ...state,
      lockerRooms: []
    };
  }),

  on(SharedStateActions.getDashboardOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      dashboard: payload
    };
  }),

  on(SharedStateActions.getDashboardOnLoadFail, (state) => {
    return {
      ...state,
      dashboard: null
    };
  }),

  on(SharedStateActions.getDashboardWithTimeSuccess, (state, { payload }) => {
    return {
      ...state,
      dashboard: payload
    };
  }),

  on(SharedStateActions.getDashboardWithTimeFail, (state) => {
    return {
      ...state,
      dashboard: null
    };
  }),

  on(SharedStateActions.getEventLogOnLoadSuccess, (state, { payload }) => {
    return {
      ...state,
      eventLogs: payload
    };
  }),

  on(SharedStateActions.getEventLogOnLoadFail, (state) => {
    return {
      ...state,
      eventLogs: null
    };
  }),

  on(SharedStateActions.getEventLogTimeByRangeSuccess, (state, { payload }) => {
    return {
      ...state,
      eventLogs: payload
    };
  }),

  on(SharedStateActions.getEventLogTimeByRangeFail, (state) => {
    return {
      ...state,
      eventLogs: null
    };
  }),

  on(SharedStateActions.getEventLogTimeByRangeSuccess, (state, { payload }) => {
    return {
      ...state,
      eventLogs: payload
    };
  }),

  on(SharedStateActions.getUiWebOptionsSuccess, (state, { payload }) => {
    return {
      ...state,
      uiWebOptions: payload
    };
  }),

  on(SharedStateActions.getUiWebOptionsFail, (state) => {
    return {
      ...state,
      uiWebOptions: null
    };
  }),

  on(SharedStateActions.getCustomFieldsSuccess, (state, { payload }) => {
    return {
      ...state,
      customFields: payload.customFields
    };
  }),

  on(SharedStateActions.getCustomFieldsFail, (state) => {
    return {
      ...state,
      customFields: null
    };
  }),

  on(SharedStateActions.getCashRegistersSuccess, (state, { payload }) => {
    return {
      ...state,
      cashRegisters: payload.cashRegisters
    };
  }),

  on(SharedStateActions.getCashRegistersFail, (state) => {
    return {
      ...state,
      cashRegisters: null
    };
  }),

  on(SharedStateActions.getEZLUserSuccess, (state, { payload }) => {
    return {
      ...state,
      ezleaguesUser: payload
    };
  }),

  on(SharedStateActions.hasUserPendingActivityItemSuccess, (state, { payload }) => {
    return {
      ...state,
      userHasPendingActivityItem: payload.hasPendingActivity
    };
  }),

  on(SharedStateActions.hasUserPendingActivityItemFail, (state) => {
    return {
      ...state,
      userHasPendingActivityItem: null
    };
  }),

  on(SharedStateActions.clearMembershipPlansState, (state) => {
    return {
      ...state,
      membershipPlans: null
    };
  }),
  

);

export function SharedStateReducer(state: SharedState | undefined, action: ActionWithPayload) {
  return reducer(state, action);
}
