import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '@app-root/state/app.state';
import { SessionResponse } from '@app-autogen/ez.authentication/contracts/response/session-response';

export interface State extends fromRoot.State {
  appSession: SessionResponse;
}

// Selector functions
const getSessionFeatureState = createFeatureSelector<SessionResponse>('appSession');

export const getCurrentState = createSelector(getSessionFeatureState, (state) => state);
export const getJwt = createSelector(getSessionFeatureState, (state) => state.jwtToken);
export const getCurrentLocationId = createSelector(getSessionFeatureState, (state) => state.currentLocationId);
export const getCurrentLocationCulture = createSelector(getSessionFeatureState, (state) => state.currentLocationCulture);
export const getCurrentLocationCurrencyCode = createSelector(getSessionFeatureState, (state) => state.currentLocationCurrencyCode);
export const getCurrentCompanyId = createSelector(getSessionFeatureState, (state) => state.currentCompanyId);
export const getCurrentLocationTimeZone = createSelector(getSessionFeatureState, (state) => state.currentLocationTimeZone);
export const getCurrentUserId = createSelector(getSessionFeatureState, (state) => state.userId);
export const getCurrentUserResourceCalendarId = createSelector(getSessionFeatureState, (state) => state.userResourceCalendarId);
export const getError = createSelector(getSessionFeatureState, (state) => state.error);
export const getFirstName = createSelector(getSessionFeatureState, (state) => state.firstName);
export const getLastName = createSelector(getSessionFeatureState, (state) => state.lastName);
export const getFullName = createSelector(getSessionFeatureState, (state) => state.firstName + ' ' + state.lastName);
export const getLoggingLevel = createSelector(getSessionFeatureState, (state) => state.loggingLevel);
export const getAdminLevel = createSelector(getSessionFeatureState, (state) => state.adminLevel);
export const getEmailAddress = createSelector(getSessionFeatureState, (state) => state.emailAddress);
export const getIsActive = createSelector(getSessionFeatureState, (state) => state.isActive);
export const getLocationPrefs = createSelector(getSessionFeatureState, (state) => state.locationPreferences);
export const getCurrentLocationCountryCode = createSelector(getSessionFeatureState, (state) => state.currentLocationCountryCode);
export const getCurrentLocationName = createSelector(getSessionFeatureState, (state) => state.currentLocationName);
export const getCurrentLocationPublicName = createSelector(getSessionFeatureState, (state) => state.currentLocationPublicName);
