  <span>
    {{outputText}}
    <span class="link-no-decoration"
      *ngIf="ellipsisText"
      (mouseenter)="allText.show($event)"
      (mouseleave)="hideInfoPanel('link', allText)">...</span>
  </span>


  <p-overlayPanel #allText
    appendTo="body"
    styleClass="table-all-info">
    <div (mouseleave)="hideInfoPanel('overlayPanel',allText)">
      {{ellipsisText}}
    </div>
  </p-overlayPanel>
