// NPM/Angular Imports
import { createActionHelper } from '@ezuiaws/ez-packages/ez-store';
import { ClientAttendanceStatusActionTypes } from '@app-autogen/ez.client/contracts/action-types/client-attendance-status-action-types';
import { AttendanceStatusInfoResponse } from '@app-autogen/ez.common/contracts/response/attendance-status-info-response';
import { UpdateAttendanceStatusesRequest } from '@app-autogen/ez.common/contracts/request/update-attendance-statuses-request';
import { AttendanceActionTypes } from '@app-autogen/ez.attendance/contracts/action-types/attendance-action-types';
import { UpdateAttendanceStatusResult } from '@app-autogen/ez.attendance/contracts/response/update-attendance-status-result';

export const getAttendanceStatusesOnLoad = createActionHelper(ClientAttendanceStatusActionTypes.GetAttendanceStatusesOnLoad);
export const getAttendanceStatusesOnLoadSuccess = createActionHelper<AttendanceStatusInfoResponse>(ClientAttendanceStatusActionTypes.GetAttendanceStatusesOnLoadSuccess);
export const getAttendanceStatusesOnLoadFail = createActionHelper<AttendanceStatusInfoResponse>(ClientAttendanceStatusActionTypes.GetAttendanceStatusesOnLoadFail);

export const updateAttendanceStatusOnClick = createActionHelper<UpdateAttendanceStatusesRequest>(AttendanceActionTypes.UpdateAttendanceStatusOnClick);
export const updateAttendanceStatusOnClickSuccess = createActionHelper<UpdateAttendanceStatusResult>(AttendanceActionTypes.UpdateAttendanceStatusOnClickSuccess);
export const updateAttendanceStatusOnClickFail = createActionHelper<string>(AttendanceActionTypes.UpdateAttendanceStatusOnClickFail);

export const clearUpdateResultStateOnLoad = createActionHelper(AttendanceActionTypes.ClearUpdateResultOnLoad);
