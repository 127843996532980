import { formatDate, FormatWidth, getLocaleDateFormat, getLocaleTimeFormat } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { EzLocaleHelper } from '@ezuiaws/ez-packages/ez-localization';

export enum EzDatePipeFormat {
  shortDate = 'shortDate',
  mediumDate = 'mediumDate',
  longDate = 'longDate',
  fullDate = 'fullDate',
  shortTime = 'shortTime',
  mediumTime = 'mediumTime',
  fullDateShortTime = 'fullDateShortTime',
  shortDateMediumTime = 'shortDateMediumTime',
  shortDateShortTime = 'shortDateShortTime',
  wideMonthFullYear = 'wideMonthFullYear',
  isoDate = 'isoDate',
  wideDayOfWeek = 'wideDayOfWeek',
  dayOfMonth = 'dayOfMonth',
  fullTimeAsString = 'fullTimeAsString',
  timeAsString24Hours = 'timeAsString24Hours', // 24-hour format
  mediumTimeWithAMPM = 'mediumTimeWithAMPM' // 12-hour format with AM/PM
}

@Pipe({
  name: 'ezDate'
})
export class EzDatePipe implements PipeTransform {

  transform(value: string | number | Date, ezDateFormat?: EzDatePipeFormat, useLocale: boolean = true): string | null {

    if (value == null || value === '' || value !== value) { return null; }

    let formatString: string = '';

    // Default to short date if no format is passed in
    if (!ezDateFormat) {
      formatString = getLocaleDateFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short);
    } else {
      switch (ezDateFormat) {
        case EzDatePipeFormat.isoDate:
          // ISO date format is for cases when we need the same format regardless of the locale/culture.
          // For example, for sorting purposes, or sending date values into actions.
          formatString = 'yyyy-MM-dd HH:mm:ss';
          break;

        case EzDatePipeFormat.fullDateShortTime:
          // Custom format that combines fullDate and shortTime
          formatString = getLocaleDateFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Full)
            + ' ' + getLocaleTimeFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short);
          break;

        case EzDatePipeFormat.shortDateMediumTime:
          // Custom format that combines shortDate and mediumTime
          formatString = getLocaleDateFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short)
            + ' ' + getLocaleTimeFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Medium);
          break;

        case EzDatePipeFormat.shortDateShortTime:
          // Combine shortDate and shortTime formats, reusing the shortTime logic
          const shortDateFormat = getLocaleDateFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short);
          let shortTimeFormat = getLocaleTimeFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short);

          // Remove space and adjust AM/PM
          if (shortTimeFormat.includes("h:mm a")) {
            shortTimeFormat = shortTimeFormat.replace("h:mm a", "h:mma");
          }

          // Combine the date and time formats
          formatString = `${shortDateFormat} 'at' ${shortTimeFormat}`;
          break;

        case EzDatePipeFormat.wideMonthFullYear:
          // Custom format that combines wide month and full year, same format for all locales
          formatString = 'MMMM yyyy';
          break;

        case EzDatePipeFormat.wideDayOfWeek:
          // Custom format that shows full name for day of the week, same format for all locales
          formatString = 'EEEE';
          break;

        case EzDatePipeFormat.dayOfMonth:
          // Custom format that shows name of day, same format for all locales
          formatString = getLocaleDateFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short)
            .toLowerCase().match(/d/g).join('');
          break;

        case EzDatePipeFormat.fullTimeAsString:
          formatString = 'HH:mm:ss';
          break;

        case EzDatePipeFormat.timeAsString24Hours:
          formatString = 'HH:mm';
          break;

        case EzDatePipeFormat.mediumTimeWithAMPM:
          // Adjust for 12-hour format with AM/PM
          formatString = 'hh:mm a'; // Explicitly set to 12-hour format without seconds
          break;

        case EzDatePipeFormat.shortTime:
          // Adjust short time format to ensure it includes AM/PM
          formatString = getLocaleTimeFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short);
          if (formatString.includes("h:mm a")) {
            // For en-CA-CAD and en-AU-AUD, we need to adjust formatting, othervise the output for current cultures will be wrong 'amm/pmmm'
            formatString = EzLocaleHelper.localeCultureCurrencyCombo === "en-CA-CAD" || "en-AU-AUD" ?
              formatString.replace("h:mm a", "h:mma") :
              (formatString + "aaaa\'m\''").replace(/ /g, '');
          }
          break;

        case EzDatePipeFormat.shortDate:
          // Ensure short date format is consistent
          formatString = getLocaleDateFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short);
          break;

        default:
          formatString = ezDateFormat;
          break;
      }
    }

    try {
      // TODO - Revisit the time zone stuff when we get to EZ-12959
      return formatDate(value, formatString, EzLocaleHelper.localeCultureCurrencyCombo, useLocale ? EzLocaleHelper.timezone : '+0000');
    } catch (error) {
      console.error('Error formatting date:', error.message);
      return null;
    }
  }

  // TODO for EZ-12959
  // maybe just use another function rather than default boolean??

  // transformWithoutTimeZone(value: string | number | Date, format?: string | EzDatePipeFormat): string | null {

  //   if (value == null || value === '' || value !== value) { return null; }

  //   format = format in EzDatePipeFormat ? format : getLocaleDateFormat(EzLocaleHelper.localeCultureCurrencyCombo, FormatWidth.Short);

  //   try {
  //     return formatDate(value, format, EzLocaleHelper.localeCultureCurrencyCombo, '+0000');
  //   } catch (error) {
  //     throw error.message;
  //   }
  // }
}
