import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HubActivity } from '@app-autogen/ez.core/contracts/support/response/hub-activity';
import { HubActivityStatus } from '@app-autogen/ez.core/contracts/support/response/hub-activity-status';
import { BaseActionTypes } from '@app-autogen/ez.core/messages/action-types/base-action-types';
import { ClientActionResponse } from '@app-autogen/ez.core/messages/client-action-response';
import { MessagePacketHelper } from './message-packet-helper';
import { AppDetailsFacade } from '@src/app/state/app-details-state/app-details.facade';

import { EzSocketService, ActionWithPayload } from '@ezuiaws/ez-packages/ez-socket';
import { EzNotificationService } from '@ezuiaws/ez-packages/ez-notification';
import { ActionMessage } from '@ezuiaws/ez.ui.models';

import * as fromFailResponses from '@app-root/state/fail-state';
import * as failResponseActions from '@app-root/state/fail-state/fails.actions';

@Injectable({ providedIn: 'root' })
export class SocketHelperService {
  public hubConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(Injector) private injector: Injector,
    private notificationService: EzNotificationService,
    private ezSocketService: EzSocketService,
    private storeFailResponses: Store<fromFailResponses.State>,
    private appDetailsFacade: AppDetailsFacade
  ) { }

  initSocket() {
    this.ezSocketService.createHubConnection().subscribe(() => {
      this.listenForHubConnectionStatus();
      this.listenForServerMessages();

      this.hubConnected$.next(true);
    });
  }

  sendServerGetAllActionsHandlers(): Observable<string[]> {
    return this.ezSocketService.sendMessageToServer('getAllActionHandlers');
  }

  sendServerLog(payload: string): Observable<void> {
    return this.ezSocketService.sendMessageToServer('loggingMessage', false, payload);
  }

  sendServerUpsertSession(payload: string): Observable<string> {
    return this.ezSocketService.sendMessageToServer('upsertSession', true, payload);
  }

  getHubTaskIdFromServer(): Observable<string> {
    return this.ezSocketService.sendMessageToServer('getHubTaskId');
  }

  private listenForHubConnectionStatus() {
    this.ezSocketService.getConnectionStatus()
      .subscribe((status: boolean) => {
        this.appDetailsFacade.setHubConnected(status);
        this.logHubConnectionActivity(status);
      });
  }

  private listenForServerMessages() {
    this.ezSocketService.listenForServerMessages().pipe(
      map((car: ClientActionResponse): ClientActionResponse => MessagePacketHelper.handlePackets(car)),
      filter((car: ClientActionResponse) => car !== null),
      tap((car: ClientActionResponse) => {
        if (!car.payLoad || !car.payLoad.actionType) {
          console.warn('Blank action type sent from server');
        }
      }),
      filter((car: ClientActionResponse) => !!car.payLoad && !!car.payLoad.actionType),
      map((car: ClientActionResponse): ActionWithPayload => {
        const carPayload: ActionMessage = car.payLoad;
        return {
          type: carPayload.actionType,
          payload: carPayload.actionPayload ? JSON.parse(carPayload.actionPayload) : null
        };
      }),
      tap((awp: ActionWithPayload) => {
        if (awp.type.lastIndexOf(BaseActionTypes.failure) !== -1) {
          this.storeFailResponses.dispatch(failResponseActions.addFailResponse(awp.payload));
        }
      })).subscribe((awp: ActionWithPayload) => this.storeFailResponses.dispatch(awp));
  }

  private logHubConnectionActivity(connectionStatus: boolean) {
    const hubActivityLog: HubActivity = {
      hubActivityStatus: connectionStatus ? HubActivityStatus.connected : HubActivityStatus.disconnected,
      hubActivityDate: new Date().toISOString()
    };

    this.appDetailsFacade.logConnectionActivity(hubActivityLog);
  }

  setClientIP(clientIP: string) {
     this.ezSocketService.setClientIP(clientIP);
  }
}
