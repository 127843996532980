import { Injectable } from '@angular/core';
import { AuthService } from '@app-core/auth/auth.service';
import { UserSettingsService } from '@app-core/auth/user-settings.service';
import { LocationModulesEnum } from '@app-core/auth/location-modules-enum';
import { LocationSettingsService } from '@app-core/auth/location-settings.service';
import * as fromTheme from '@app-shared/layout/theme-switcher/state';
import * as themeActions from '@app-shared/layout/theme-switcher/state/theme.actions';
import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsAuthService {

  environment: string = this.authService.getEnvironment();

  constructor(
    private authService: AuthService,
    private userSettingsService: UserSettingsService,
    private locationSettingsService: LocationSettingsService,
    private themeStoreHelper: EzStoreService<fromTheme.State>
  ) { }

  //#region Financial Reports
  public showFinancialReports() {
    let show: boolean = false;
    if (this.userSettingsService.userHasPermission('ReportsAllFinancial')
      || this.userSettingsService.userHasPermission('ReportManagement')
      || this.userSettingsService.userHasPermission('ReportDeposit')
      || this.userSettingsService.userHasPermission('ReportDepositOwn')
      || this.userSettingsService.userHasPermission('ReportsAllTrainer')
      || (this.userSettingsService.userHasPermission('ReportsMyTrainer') && this.authService.userSession.userResourceCalendarId)) {
      show = true;
    }
    return show;
  }

  public showAccountReceivableReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showAccruedRentalRevenueReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showAccruedSessionRevenueReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showAllSalesByCouponReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showCsiBatchSettlementReport(): Observable<boolean> {
    this.themeStoreHelper.dispatchAction(themeActions.getAccessToCsiBatchSettlementReport());
    return this.themeStoreHelper.stateStreamSelector(fromTheme.getAccessToCsiBatchSettlementReport).pipe(
      filter(result => result != undefined),
      map((result: any) => {
        return result && this.userSettingsService.userHasPermission('ReportsAllFinancial');
      })
    );
  }

  public showPyxisDisbursementsReport(): Observable<boolean> {
    this.themeStoreHelper.dispatchAction(themeActions.getAccessToPyxisDisbursementsReport());
    return this.themeStoreHelper.stateStreamSelector(fromTheme.getAccessToPyxisDisbursementsReport).pipe(
      filter(result => result != undefined),
      map((result: any) => {
        return result && this.userSettingsService.userHasPermission('ReportsAllFinancial');
      })
    );
  }

  public showCollectionsReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showMembershipDeferredRevenueReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial')
      && this.locationSettingsService.locationHasModule(LocationModulesEnum.HasAdvancedMemberships);
  }

  public showDepositsReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial')
      || this.userSettingsService.userHasPermission('ReportDeposit')
      || this.userSettingsService.userHasPermission('ReportDepositOwn');
  }

  public showDirectDebitFailuresReport() {
    this.themeStoreHelper.dispatchAction(themeActions.getAccessToDirectDebitFailuresReport());
    return this.themeStoreHelper.stateStreamSelector(fromTheme.getAccessToDirectDebitFailuresReport).pipe(
      filter(result => result != undefined),
      map((result: any) => {
        return result && this.userSettingsService.userHasPermission('ReportsAllFinancial');
      })
    );
  }

  public showClientCreditCardExpirationReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showIncomeBalancesByCustomerReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showInvoicesReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showMainDashboardReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showOpenInvoicesByCustomerReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showPackageSalesReport() {
    return this.userSettingsService.userHasPermission('ReportManagement');
  }

  public showRevenueReport() {
    return this.userSettingsService.userHasPermission('ReportManagement');
  }

  public showSalespersonCommissionReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showTrainerCommissionReport() {
    let show: boolean = false;
    if (this.userSettingsService.userHasPermission('ReportsAllFinancial')
      || this.userSettingsService.userHasPermission('ReportsAllTrainer')
      || (this.userSettingsService.userHasPermission('ReportsMyTrainer') && this.authService.userSession.userResourceCalendarId)) {
      show = true;
    }
    return show;
  }

  public showUnappliedPaymentsReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }

  public showUnderpaidItemsReport() {
    return this.userSettingsService.userHasPermission('ReportsAllFinancial');
  }
  //#endregion

  //#region Training Reports
  public showTrainingReports() {
    return this.userSettingsService.userHasPermission('ReportsAllTrainer')
      || this.userSettingsService.userHasPermission('ReportsMyTrainer');
  }

  public showSessionNotesReport() {
    return this.userSettingsService.userHasPermission('ReportsAllTrainer')
      || this.userSettingsService.userHasPermission('ReportsMyTrainer');
  }

  public showSessionsCompletedByClientReport() {
    return this.userSettingsService.userHasPermission('ReportsAllTrainer');
  }

  public showTrainerPerformanceReport() {
    return this.userSettingsService.userHasPermission('ReportsAllTrainer')
      || this.userSettingsService.userHasPermission('ReportsMyTrainer');
  }

  public showTrainerSessionsByWeekReport() {
    return this.userSettingsService.userHasPermission('ReportsAllTrainer');
  }

  public showTrainerVenueScheduleReport() {
    return this.userSettingsService.userHasPermission('ReportsAllTrainer')
      || this.userSettingsService.userHasPermission('ReportsMyTrainer');
  }

  public showTrainingPinupReport() {
    return this.userSettingsService.userHasPermission('ReportsAllTrainer');
  }
  //#endregion

  //#region Venue Booking Reports
  public showVenueBookingReports() {
    return this.userSettingsService.userHasPermission('ReportsAllVenueBooking')
      && this.locationSettingsService.locationHasModule(LocationModulesEnum.HasVenueScheduling);
  }
  //#endregion

  //#region Equipment Reports
  public showEquipmentReports() {
    return this.userSettingsService.userHasPermission('ReportsAllEquipment')
      && this.locationSettingsService.locationHasModule(LocationModulesEnum.HasEquipment);
  }
  //#endregion

  //#region Time Clock Reports
  public showTimeClockReports() {
    return this.userSettingsService.userHasPermission('ReportsAllTimeClock')
      && this.locationSettingsService.locationHasModule(LocationModulesEnum.HasTimeClock);
  }
  //#endregion

  //#region Membership Reports
  public showMembershipReports() {
    return this.userSettingsService.userHasPermission('ReportsAllMembership');
  }

  public showCheckInHistorySummaryReport() {
    return this.locationSettingsService.locationHasModule(LocationModulesEnum.HasCheckIn);
  }

  public showMembershipAttritionReport() {
    return this.locationSettingsService.locationHasModule(LocationModulesEnum.HasAdvancedMemberships);
  }

  public showMembershipsRevenueByMonthReport() {
    return this.authService.userSession.currentLocationCulture.toLocaleLowerCase() === 'en-us';
  }
  //#endregion

  //#region Point Of Sales Reports
  public showPointOfSalesReports() {
    return (this.locationSettingsService.locationHasModule(LocationModulesEnum.HasPOS) || this.locationSettingsService.locationHasModule(LocationModulesEnum.HasPOSv2))
      && this.userSettingsService.userHasPermission('ReportsAllPOS');
  }
  //#endregion

  //#region Marketing Reports
  public showMarketingReports() {
    return this.userSettingsService.userHasPermission('ReportsAllMarketing');
  }
  //#endregion

  //#region System Usage Reports
  public showSystemUsageReports() {
    return this.userSettingsService.userHasPermission('ReportAccountActivity');
  }
  //#endregion

  //#region Locker Reports
  public showLockerReports() {
    return this.locationSettingsService.locationHasModule(LocationModulesEnum.HasLockers)
      && this.userSettingsService.userHasPermission('ReportsAllLocker')
      && (this.environment === 'Development' || this.environment === 'QA');
  }
  //#endregion

}
