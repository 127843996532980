import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ez-sub-header-link',
  templateUrl: './ez-sub-header-link.component.html',
  styleUrls: ['./ez-sub-header-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EzSubHeaderLinkComponent implements OnInit {

  @Input() linkLabel: string;
  @Input() routerLink: string;
  @Input() addSemicolon: boolean = true;

  constructor(private router: Router) {}

  ngOnInit() {}

  goToPage() {
    this.router.navigateByUrl(this.routerLink);
  }

}
