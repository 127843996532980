import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { DynamicMenuDirective } from '../../directives/dynamic-menu.directive';
import { DynamicMenuComponentModel } from '../../models/dynamic-menu-component-model';
import { DynamicMenuItem } from '../../models/dynamic-menu-item';
import { DynamicMenuItemService } from '../../services/dynamic-menu-item.service';
import { BehaviorSubject, Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-dynamic-menu',
  templateUrl: './dynamic-menu.component.html',
  styleUrls: ['./dynamic-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicMenuComponent implements OnInit {

  @ViewChild(DynamicMenuDirective, { static: true }) appDynamicMenuComponent: DynamicMenuDirective;
  @Output() dynamicMenuOpened: EventEmitter<void> = new EventEmitter<void>;
  @Input() isMobileView: boolean = false;

  dynamicItems$: Observable<DynamicMenuItem[]>;
  isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private dynamicMenuItemService: DynamicMenuItemService
  ) { }

  ngOnInit(): void {
    this.dynamicMenuItemService.selectedDynamicItem$.pipe(
      untilDestroyed(this),
      filter(item => !!item)
    ).subscribe(
      item => this.loadComponent(item)
    );

    this.dynamicItems$ = this.dynamicMenuItemService.dynamicItems$;
  }

  loadComponent(item: DynamicMenuItem) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(item.component);

    const viewContainerRef = this.appDynamicMenuComponent.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<DynamicMenuComponentModel>(componentFactory);

    componentRef.instance.data = item.data;
  }

  showStatusChanged(show: boolean) {
    this.isOpen$.next(show);
    this.onDynamicMenuOpened(show);
  }

  private onDynamicMenuOpened(isOpen: boolean) {
    if (isOpen) {
      this.dynamicMenuOpened.emit();
    };
  }

}
