<p-fileUpload #fileInput
  [attr.data-cy]="'file-upload'"
  name="document_attachment"
  [accept]="acceptedFileTypes"
  [maxFileSize]="maxSize"
  [auto]='autoUpload'
  [chooseLabel]="'ChooseFile' | ezLocale"
  [customUpload]="true"
  [draggable]='true'
  [showUploadButton]="false"
  [showCancelButton]="false"
  [files]="selectedFiles"
  (onSelect)="onSelectedFilesChange($event)"
  (uploadHandler)='uploadHandler($event)'>

  <ng-template pTemplate="toolbar">

    <div *ngIf="selectedFileNames?.length > 0">
      <ng-container *ngFor="let item of selectedFileNames">
        <span class="link">
          {{item}}

        </span>
        <button pButton
          pRipple
          [attr.data-cy]="'button-remove-' + item"
          type="button"
          icon="pi pi-times"
          class="p-button-rounded p-button-secondary p-button-text"
          (click)="onSelectedFileNameRemoved(item)"></button>
      </ng-container>
    </div>
  </ng-template>

  <ng-template let-file
    pTemplate="file">

    <div class='d-flex justify-content-center'>
      <span [ngSwitch]="acceptedFileTypes">
        <img *ngSwitchCase="'image/*'"
          height='150'
          width='150'
          [src]="file.objectURL" />

        <i *ngSwitchDefault
          class="fa fa-file">
        </i>
      </span>
    </div>

    <p>{{file.name}} - ({{file.size / 1000}} KB)</p>
  </ng-template>

  <ng-template pTemplate="content"
    let-files>

    <ng-container *ngIf="files.length === 0; else elseTemplate">
      <div class='text-center p-m m-b'>
        <h4>{{ ' Or' | ezLocale }}</h4>
        <h4>{{ 'DragAndDrop' | ezLocale }}</h4>
        <br />
        <br />
        <h5 *ngIf="minSizeText">{{ 'MinimumSize' | ezLocale }}: {{minSizeText}} </h5>
        <h5 *ngIf="maxSizeText">{{ 'MaximumSize' | ezLocale }}: {{maxSizeText}} </h5>
        <h5>{{ 'AcceptedFormats' | ezLocale }}: {{acceptedFileExtensions}}</h5>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class='d-flex justify-content-center'>
        <button pButton
          pRipple
          [attr.data-cy]="'file-upload-button-clear'"
          type="button"
          icon="pi pi-times"
          class="p-button-rounded p-button-secondary p-button-text"
          (click)='fileInput.clear(); clearFile()'></button>
      </div>
    </ng-template>
  </ng-template>

</p-fileUpload>
