import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { LabelValueViewModel } from '@ezuiaws/ez.ui.models';

@Component({
  selector: 'ez-chips',
  templateUrl: './ez-chips.component.html',
  styleUrls: ['./ez-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EzChipsComponent implements OnInit {

  @ViewChild('chipbox', { static: true }) chipbox: ElementRef<HTMLElement>;

  @Input() chips: LabelValueViewModel[] = [];
  @Input() showClear: boolean = false;

  @Output() chipsChange: EventEmitter<LabelValueViewModel[]> = new EventEmitter<LabelValueViewModel[]>();
  @Output() chipRemoved: EventEmitter<LabelValueViewModel> = new EventEmitter<LabelValueViewModel>();

  constructor() { }

  ngOnInit() {
  }

  onChange(event) {
    if (!(event.keyCode === 8 || event.keyCode === 46)) {
      return false;
    }
  }

  onRemove(chip: LabelValueViewModel) {
    // create a new object using the spread operator to trigger onpush change detection.
    // const vals = [
    //   ...this._chips
    // ];
    this.chips = [
      ...this.chips
    ];
    this.chipsChange.emit(this.chips);

    // emit the removed chip for the parent component
    this.chipRemoved.emit(chip);
  }

  onFocus(event) {
    // blur the input on focus so that the user never sees a cursor.
    event.target.blur();
  }
}
