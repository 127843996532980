<div *ngIf="visible"
  class="form-group"
  [attr.data-cy]="cypressName + '-form-group'"
  [ngClass]="{'mt-2': !showLabel && showTopMargin,
              'mb-0': !showBottomMargin,
              'with-tooltip-group': toolTipHtml,
              'invalid-control': isInvalid && (_fieldMessage || _controlMessage || _groupMessage),
              'disabled': (_fc?.disabled || disabled) && lighteningLabelOnDisabled,
              'show-warning': showWarningMessage
            }">
  <ng-container *ngIf="toolTipHtml">
    <ez-tooltip [tooltipContent]="toolTipHtml"
      [keepTooltipOpenOnHover]="_keepTooltipOpenOnHover"
      [enableTooltipWithCheckboxAlignment]="_enableTooltipWithCheckboxAlignment">
    </ez-tooltip>
  </ng-container>

  <div class="content-wrapper"
    [ngClass]="{'d-flex align-items-center': horizontalAlignment}">
    <label class="control-label"
      [ngClass]="{'font-weight-bold': boldLabel || isRequired,
        'hidden': !showLabel || !(fieldLabel$ | async).length
       }"
      [for]="fieldName">
      {{(fieldLabel$ | async) !== ''
      ? (fieldLabel$ | async)
      : null
      }}
    </label>

    <ez-form-control-placeholder [ngClass]="{'d-none': !_showAdvancedPlaceholder}"
      [placeholderText]="_placeholderText"
      [linkText]="_linkText"
      [hasLabel]="showLabel"
      [placeholderVisible]="_placeholderVisible"
      (placeholderClicked)="placeholderClick()"
      (linkClicked)="linkClick()">
    </ez-form-control-placeholder>
    <ng-content></ng-content>
  </div>

  <div class="invalid-feedback"
    [attr.data-cy]="cypressName + '-form-group-invalid'"
    [ngClass]="{'d-block mt-0':isInvalid && (_fieldMessage || _controlMessage || _groupMessage) && useTemplateValidationOutput}">
    {{ _fieldMessage }}{{ _controlMessage }}{{ _groupMessage }}
  </div>

  <div class="warning-feedback d-none"
    [attr.data-cy]="cypressName + '-form-group-warning'"
    [ngClass]="{'d-block': showWarningMessage && warningMessageText}">
    {{warningMessageText}}
  </div>
</div>
