import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SessionResponse } from '@app-autogen/ez.authentication/contracts/response/session-response';
import { SessionFacade } from '@src/app/state/session-state/session.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SharedStateFacade } from '@app-root/state/shared-state/shared-state.facade';
import { TitleCasePipe } from '@angular/common';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  userSession$: Observable<SessionResponse> = this.sessionFacade.userSession$;
  userSession: SessionResponse;
  titleCasePipe = new TitleCasePipe();

  constructor(
    private sessionFacade: SessionFacade,
    private sharedStateFacade: SharedStateFacade
  ) {
    this.userSession$.pipe(
      untilDestroyed(this)
    ).subscribe((userSession: SessionResponse) => this.userSession = userSession);
  }

  userHasPermission(permissionCode: string): boolean {
    return this.userSession.userPermissions && this.userSession.userPermissions.indexOf(permissionCode) >= 0;
  }

  getUserPreferenceStringValue(preferenceCode: string): string {
    if (this.userSession.userPreferences && this.userSession.userPreferences[preferenceCode]) {
      return this.userSession.userPreferences[preferenceCode];
    }
    return null;
  }

  getUserPreferenceBooleanValue(preferenceCode: string): boolean {
    const hasUserPreference: string = this.getUserPreferenceStringValue(preferenceCode);
    return hasUserPreference ? hasUserPreference.toLowerCase() === 'true' : false;
  }

  updateUserPreferenceStringValue(preferenceCode: string, preferenceValue: string) {
    this.sharedStateFacade.updateUserPreference(preferenceCode, preferenceValue);
  }

  updateUserPreferenceBooleanValue(preferenceCode: string, preferenceValue: boolean) {
    this.sharedStateFacade.updateUserPreference(preferenceCode, this.titleCasePipe.transform(preferenceValue.toString()));
  }
}
