import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ez-panel',
  templateUrl: './ez-panel.component.html',
  styleUrls: ['./ez-panel.component.scss']
})
export class EzPanelComponent implements OnInit {

  @Input() panelTitle: string = '';
  @Input() headerLink: string = '';
  @Input() headerLinkLabel: string = '';
  @Input() largePanelTitle: boolean = false;
  @Input() saveButtonText = 'Save';
  @Input() showButtons = true;
  @Input() enableButtons = false;
  @Input() enableCancelButton = false;
  @Input() showHeaderLink = false;
  @Input() showClose = false;
  @Input() showCancel = true;
  @Input() showFooter = true;
  @Input() showHeader = true;
  @Input() showDragHandle = false;
  @Input() padBottomContent = true;
  _collapsed = false;
  @Input() set open(open: boolean) {
    this._collapsed = !open;
  }
  @Input() collapsable = true;
  @Input() styleClass: string = '';

  @Output() saveClick: EventEmitter<any> = new EventEmitter();
  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  @Output() cancelClick: EventEmitter<any> = new EventEmitter();
  @Output() togglePanelClick: EventEmitter<boolean> = new EventEmitter();

  collapsed = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  togglePanel(event) {
    this._collapsed = !this._collapsed;
    this.togglePanelClick.emit(this._collapsed);
  }

  btnSaveClick() {
    this.saveClick.emit();
  }

  btnCancelClick() {
    this.cancelClick.emit();
  }

  onHeaderLinkClick() {
    this.router.navigateByUrl(this.headerLink);
  }

}
