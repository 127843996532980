import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { FileUploadRequest } from './models/file-upload-request';
import { FileUploadResponse } from './models/file-upload-response';
import { EZ_APP_NAME, EZ_JWT_KEY, EZ_USER_FILES_ENDPOINT } from '../../../ez-consts';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(@Inject(EZ_JWT_KEY) private jwtKey: string, @Inject(EZ_APP_NAME) private appName: string, @Inject(EZ_USER_FILES_ENDPOINT) private userFilesEndpoint: string, private http: HttpClient, private jwtHelper: JwtHelperService) {
    console.log('App Name: ' + this.appName);
  }

  postData(data: any, options?): Observable<any> {

    const formData = new FormData();
    formData.append('data', data);

    alert('Send this data to the server! {' + formData.get('data').toString() + '}');

    return this.http.post('', data, options).pipe(
      map((response) => response)
    );
  }
  uploadFile(fileUploadRequest: FileUploadRequest): Observable<FileUploadResponse> {

    const file = fileUploadRequest.file;

    const formData: FormData = new FormData();
    const userGuid = this.getUserGuid();
    formData.append('UserGuid', userGuid);
    formData.append('UploadSystemCode', this.appName);
    formData.append('AccessToken', userGuid);
    formData.append('UploadTypeCode', fileUploadRequest.uploadTypeCode);
    formData.append('File', file, file.name);

    // TODO: may be able to pull this from a config service as well
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
      })
    };

    return this.http
      .post(this.userFilesEndpoint, formData, httpOptions)
      .pipe(
        map((response: any) => {
          const responseUrl = response.url;
          const parts = responseUrl.split('/');
          return {
            url: responseUrl,
            // file name is everything after the last '/'
            ezUserFilesFileName: parts[parts.length - 1]
          };
        }),
        catchError((error) => this.handleError(error))
      );
  }

  handleError(error: HttpErrorResponse) {
    console.error('File upload error: ', error);
    return throwError(error.message || 'File upload server error.');
  }

  getUserGuid() {
    const jwt = localStorage.getItem(this.jwtKey);
    const jwtObj = this.jwtHelper.decodeToken(jwt);
    return jwtObj.UserGuid;
  }
}
