import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as momentTz from 'moment-timezone';
import { getDateTimeInUserTimeZone } from '../helpers';
import { DateTimeFieldToValidateEnum } from './date-time-field-to-validate-enum';

export class CustomValidators {

  public static isInteger(value: any): boolean {
    return ((parseFloat(value) === parseInt(value, 10)) && !isNaN(value));
  }

  public static notEmpty(value: any): boolean {
    return value !== null && value !== '';
  }

  public static numberValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (CustomValidators.notEmpty(c.value) && isNaN(c.value)) {
      return { numberValid: true };
    }
    return null;
  }

  public static integerValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (CustomValidators.notEmpty(c.value) && !CustomValidators.isInteger(c.value)) {
      return { integerValid: true };
    }

    return null;
  }

  public static specialCharsValidator(c: AbstractControl): { [key: string]: boolean } | null {
    const specialCharsRegex = /^[a-zA-Z0-9() _-]+$/;
    if (CustomValidators.notEmpty(c.value) && !specialCharsRegex.test(c.value.trim())) {
      return { specialCharsValid: true };
    }
    return null;
  }

  public static emailsListValidator(c: AbstractControl): { [key: string]: boolean } | null {
    const specialCharsRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let hasError: boolean = false;

    if (CustomValidators.notEmpty(c.value)) {
      const emailList: string[] = String(c.value).split(';');
      emailList.forEach((email: string) => {
        hasError = !!email && !specialCharsRegex.test(email.trim());
        if (hasError) {
          return;
        }
      });

      if (hasError) {
        return { email: hasError };
      }
    }
    return null;
  }

  public static emailsListMaxValidator(maxAllowedEmailsNum: number, separator: string = ';') {
    return (c: AbstractControl): ValidationErrors | null => {
      const specialCharsRegex: RegExp = /^([^\s@]+@[^\s@]+\.[a-zA-Z]{2,})([,;]\s*([^\s@]+@[^\s@]+\.[a-zA-Z]{2,})){0,}$/;

      if (CustomValidators.notEmpty(c.value) && !specialCharsRegex.test(c.value)) {
        return { email: true };
      }

      if (String(c.value).split(separator).length > maxAllowedEmailsNum) {
        return { emailsListExceedNorm: maxAllowedEmailsNum };
      }

      return null;
    };
  }

  // just a stub for now until localization works
  public static currencyValidator(c: AbstractControl): { [key: string]: boolean } | null {
    const currencyRegex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
    if (CustomValidators.notEmpty(c.value) && c.value !== '0' && c.value !== 0 && (isNaN(c.value) || !currencyRegex.test(c.value))) {
      return { currencyValid: true };
    }

    return {};
  }

  public static dateLessThan(from: string, to: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      if (this.valueLessThanDate(group, from, to)) {
        return {
          dateLessThan: true
        };
      }
      return {};
    };
  }

  public static dateOnlyLessThan(from: string, to: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      if (this.valueLessThanDateOnly(group, from, to)) {
        return {
          dateLessThan: true
        };
      }
      return {};
    };
  }

  public static numberLessThan(from: string, to: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      if (this.valueLessThan(group, from, to)) {
        return {
          numberLessThan: true
        };
      }
      return {};
    };
  }

  public static ageLessThen(from: string, to: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      if (this.valueLessThan(group, from, to)) {
        return {
          ageLessThen: true
        };
      }
      return {};
    };
  }

  // TODO: on applying style guide changes for validation
  public static minAge(minAge: number) {
    return (c: AbstractControl): ValidationErrors | null => {
      if (c.value < minAge) {
        return { minAge: minAge };
      } else {
        return null
      }
    };
  }
  // TODO: on applying style guide changes for validation
  public static maxAge(maxAge: number) {
    return (c: AbstractControl): ValidationErrors | null => {
      if (c.value > maxAge) {
        return { maxAge: maxAge };
      } else {
        return null
      }
    };
  }

  private static valueLessThan(group: UntypedFormGroup, from: string, to: string) {
    const f = group.controls[from];
    const t = group.controls[to];
    if (f.value > t.value && t.value != null && f.value != null) {
      return true;
    }
    return false;
  }

  private static valueLessThanDate(group: UntypedFormGroup, from: string, to: string) {
    const f = group.controls[from];
    const t = group.controls[to];
    if (t.value != null && f.value != null && new Date(f.value) > new Date(t.value)) {
      return true;
    }
    return false;
  }

  private static valueLessThanDateOnly(group: UntypedFormGroup, from: string, to: string) {
    const f = group.controls[from];
    const t = group.controls[to];
    if (f.value != null && t.value != null) {
      const fromDateObj = new Date(f.value);
      const toDateObj = new Date(t.value);

      // Remove the time component from the dates
      fromDateObj.setHours(0, 0, 0, 0);
      toDateObj.setHours(0, 0, 0, 0);

      if (fromDateObj > toDateObj) {
        return true;
      }
    }
    return false;
  }

  public static daysExceedNorm(minDate: Date, maxDays: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate: string = control.value;
      const minAvailableDate = minDate.toString();
      const days: number = (Date.parse(startDate) - Date.parse(minAvailableDate)) / (1000 * 60 * 60 * 24);
      if (days > maxDays) {
        return { daysExceedNorm: maxDays };
      }
      return null;
    }
  }

  public static requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: UntypedFormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireCheckboxesToBeChecked: true
        };
      }

      return null;
    };
  }

  public static requiredWithNoWhitespaceValidator(control: AbstractControl) {
    // do not use with 'required' validator
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  public static topazSignatureValidator(control: AbstractControl): { [key: string]: boolean } | null {

    if (!control || !control.value) {
      return null;
    }

    if (control.value <= 0) {
      return { signatureInvalid: true };
    }

    return null;
  }

  public static termLengthQuantityValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (CustomValidators.notEmpty(c.value)) {
      const isInteger: boolean = CustomValidators.isInteger(c.value);
      if (!isInteger || (isInteger && c.value < 1)) {
        return { termLengthQuantityValid: true };
      }
    }
    return null;
  }

  public static termQuantityValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (CustomValidators.notEmpty(c.value)) {
      const isInteger: boolean = CustomValidators.isInteger(c.value);
      if (!isInteger || (isInteger && c.value < 1)) {
        return { termQuantityValid: true };
      }
    }
    return null;
  }

  public static zeroFeeDoesNotAllowValidator(labelName: string): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (c.value === 0) {
        return { zeroFeeDoesNotAllow: [labelName] };
      } else {
        return CustomValidators.currencyValidator(c);
      }
    };
  }

  public static zeroAndNegativeNumberDoesNotAllowValidator(labelName: string): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (CustomValidators.notEmpty(c.value) && !isNaN(c.value) && c.value <= 0) {
        return { zeroAndNegativeNumberDoesNotAllow: [labelName] };
      }
      return null;
    };
  }

  public static atLeastOneResourceSelectedValidator(firstControlName: string, secondControlName?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstControlValue = control.get(firstControlName).value;
      const secondControlValue = secondControlName ? control.get(secondControlName).value : null;
      if ((!firstControlValue && !secondControlValue) || (
        Array.isArray(firstControlValue) && !firstControlValue.length &&
        Array.isArray(secondControlValue) && !secondControlValue.length
      )) {
        return { reservationNoResourcesSelected: true };
      }
      return null;
    };
  }

  public static atLeastOneFieldRequiredValidator(firstControlName: string, secondControlName?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstControlValue = control.get(firstControlName).value;
      const secondControlValue = secondControlName ? control.get(secondControlName).value : null;

      const isNotEmpty = (value) => typeof value !== 'undefined' && value !== null;

      return isNotEmpty(firstControlValue) || isNotEmpty(secondControlValue)
        ? null
        : { atLeastOneFieldRequired: true };
    };
  }

  public static atLeastOneCheckboxIsSelectedValidator(firstControlName: string, secondControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstControlValue = control.get(firstControlName).value;
      const secondControlValue = control.get(secondControlName).value;

      const isSelected = (value) => typeof value === 'boolean' && value === true;

      return isSelected(firstControlValue) || isSelected(secondControlValue)
        ? null
        : { oneOptionMustBeSelected: true };
    };
  }

  public static dateTimeToLessThanDateTimeFrom(dayFrom: string, dayTo: string, timeFrom: string, timeTo: string) {
    return (dateGroup: UntypedFormGroup) => {
      const dayFromControl: AbstractControl = dateGroup.get(dayFrom);
      const dayToControl: AbstractControl = dateGroup.get(dayTo);
      const timeFromControl: AbstractControl = dateGroup.get(timeFrom);
      const timeToControl: AbstractControl = dateGroup.get(timeTo);

      if (dayFromControl.value != null && dayToControl.value != null) {
        const dayFromControlValue: number = Date.parse(new Date(dayFromControl.value).toDateString());
        const dayToControlValue: number = Date.parse(new Date(dayToControl.value).toDateString());

        if (dayFromControlValue > dayToControlValue) {
          dayToControl.setErrors({
            dayLessThan: true
          });
        } else {
          dayToControl.setErrors(null);
          timeToControl.setErrors(null);
        }

        if (dayFromControlValue === dayToControlValue && timeToControl.value != null && timeFromControl.value != null) {

          const timeFromControlValue: Date = new Date(dayFromControl.value);
          timeFromControlValue.setHours(new Date(timeFromControl.value).getHours(), new Date(timeFromControl.value).getMinutes());

          const timeToControlValue: Date = new Date(dayToControl.value);
          timeToControlValue.setHours(new Date(timeToControl.value).getHours(), new Date(timeToControl.value).getMinutes());

          if (Date.parse(timeFromControlValue.toString()) > Date.parse(timeToControlValue.toString())) {
            timeToControl.setErrors({
              timeLessThan: true
            });
          } else {
            timeToControl.setErrors(null);
          }
        }
      } else {
        return null;
      }
    };
  }

  public static startEndTimeMatchesInterval(dayFrom: string, dayTo: string, timeFrom: string, timeTo: string, mainInterval: number, secondaryInterval: number) {
    return (dateGroup: UntypedFormGroup) => {
      const dayFromControl: AbstractControl = dateGroup.get(dayFrom);
      const dayToControl: AbstractControl = dateGroup.get(dayTo);
      const timeFromControl: AbstractControl = dateGroup.get(timeFrom);
      const timeToControl: AbstractControl = dateGroup.get(timeTo);

      const intervalInMinutes: number = mainInterval && mainInterval !== 0 ? mainInterval : secondaryInterval;

      if (dayFromControl.value && dayToControl.value && timeToControl.value && timeFromControl.value) {
        const timeFromControlValue: Date = new Date(dayFromControl.value);
        timeFromControlValue.setHours(new Date(timeFromControl.value).getHours(), new Date(timeFromControl.value).getMinutes());

        const timeToControlValue: Date = new Date(dayToControl.value);
        timeToControlValue.setHours(new Date(timeToControl.value).getHours(), new Date(timeToControl.value).getMinutes());

        const timeDifferenceInMs = Date.parse(timeToControlValue.toString()) - Date.parse(timeFromControlValue.toString());
        const timeDifferenceInMinutes = timeDifferenceInMs / (1000 * 60);

        if (timeDifferenceInMinutes !== intervalInMinutes) {
          timeToControl.setErrors({
            intervalMismatch: intervalInMinutes
          });
        } else {
          timeToControl.setErrors(null);
        }
      } else {
        return null;
      }
    };
  }

  // Use this validator when you need to show error messages on single control in form group
  public static dateTimeToLessThanDateTimeFromSingleControlError(dayFrom: string, dayTo: string, timeFrom: string, timeTo: string, dateTimeFieldToValidate: DateTimeFieldToValidateEnum): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {

      const dayFromControl: AbstractControl = c.parent?.get(dayFrom);
      const dayToControl: AbstractControl = c.parent?.get(dayTo);
      const timeFromControl: AbstractControl = c.parent?.get(timeFrom);
      const timeToControl: AbstractControl = c.parent?.get(timeTo);

      let dateTimeFrom: number;
      let dateTimeTo: number;

      if (CustomValidators.notEmpty(c.value)) {

        if ((dateTimeFieldToValidate === DateTimeFieldToValidateEnum.EndTime ||
          dateTimeFieldToValidate === DateTimeFieldToValidateEnum.StartTime) &&
          (timeFromControl?.value !== null && timeToControl?.value !== null)) {

          const dayFromControlValue: Date = dayFromControl?.value !== null ? new Date(dayFromControl.value) : new Date();
          const dayToControlValue: Date = dayToControl?.value !== null ? new Date(dayToControl.value) : new Date();

          dayFromControlValue.setHours(new Date(timeFromControl.value).getHours(), new Date(timeFromControl.value).getMinutes());
          dayToControlValue.setHours(new Date(timeToControl.value).getHours(), new Date(timeToControl.value).getMinutes());

          // Use the entire date and time for comparison ( toDateString() removes the time part )
          dateTimeFrom = Date.parse(dayFromControlValue.toISOString());
          dateTimeTo = Date.parse(dayToControlValue.toISOString());

          if (dateTimeFrom > dateTimeTo) {
            switch (dateTimeFieldToValidate) {
              case DateTimeFieldToValidateEnum.EndTime:
                return { invalidEndTime: true };
              case DateTimeFieldToValidateEnum.StartTime:
                return { invalidStartTime: true };
            };
          };
        };

        if ((dateTimeFieldToValidate === DateTimeFieldToValidateEnum.EndDate ||
          dateTimeFieldToValidate === DateTimeFieldToValidateEnum.StartDate) &&
          (dayFromControl?.value !== null && dayToControl?.value !== null)) {

          dateTimeFrom = Date.parse(new Date(dayFromControl.value).toDateString());
          dateTimeTo = Date.parse(new Date(dayToControl.value).toDateString());

          if (dateTimeFrom > dateTimeTo) {
            switch (dateTimeFieldToValidate) {
              case DateTimeFieldToValidateEnum.EndDate:
                return { invalidEndDate: true };
              case DateTimeFieldToValidateEnum.StartDate:
                return { invalidStartDate: true };
            };
          };
        };
      };

      return null;
    }
  }

  public static creditCardNumberValid(control: AbstractControl): { [key: string]: boolean } | null {

    const acceptedCreditCards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
      amex: /^3[47][0-9]{13}$/,
      discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/
    };

    if (control.value !== null) {
      // remove all non digit characters
      const value = control.value.replace(/\D/g, '');
      let sum = 0;
      let shouldDouble = false;

      // Luhns algorithm
      // loop through values starting at the rightmost side
      for (let i = value.length - 1; i >= 0; i--) {
        let digit = parseInt(value.charAt(i), 10);

        // From the rightmost digit, double every other digit
        if (shouldDouble) {
          // If the doubled digit is larger than 9, subtract 9 from the product
          if ((digit *= 2) > 9) {
            digit -= 9;
          }
        }

        // sum digits
        sum += digit;
        shouldDouble = !shouldDouble;
      }

      // If there is no remainder after dividing by 10, the card is valid
      const valid = (sum % 10) === 0;

      // check if the card is an accepted card type
      let accepted = false;
      // loop through the keys (visa, mastercard, amex, etc.)
      Object.keys(acceptedCreditCards).forEach((key) => {
        // get regex for given card type
        const regex = acceptedCreditCards[key];
        if (regex.test(value)) {
          accepted = true;
        }
      });

      if (!(valid && accepted)) {
        return {
          creditCardNumberValid: true
        };
      }

      return null;
    }
  }

  public static dateWithinRange(from: string, to: string, unit: string, value: number, message: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      const startDate = momentTz.utc(f.value);
      const endDate = momentTz.utc(t.value);
      const dayToControl: AbstractControl = group.get(to);

      switch (unit) {
        case 'days':
          const days = endDate.diff(startDate, unit);
          if ((days > value)) {
            return {
              dateWithinRange: {
                value: value,
                unit: unit,
                message: message,
              }
            };
          }
          break;

        case 'weeks':
          const weeks = endDate.diff(startDate, unit);
          if ((weeks > value)) {
            dayToControl.setErrors({
              dateWithinRange: {
                value: value,
                unit: unit,
                message: message,
              }
            });
          }
          break;

        case 'months':
          const months = endDate.diff(startDate, unit);
          if ((months > value)) {
            dayToControl.setErrors({
              dateWithinRange: {
                value: value,
                unit: unit,
                message: message,
              }
            });
          }
          break;

        case 'years':
          const years = endDate.diff(startDate, unit);
          if ((years > value)) {
            dayToControl.setErrors({
              dateWithinRange: {
                value: value,
                unit: unit,
                message: message,
              }
            });
          }
          break;

        default:
          break;
      }
      return {};
    };
  }

  public static startAndEndDateValidator(secondControlName: string, isStartDateFired: boolean = true): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const secondControlValue = c.parent?.get(secondControlName)?.value;

      if (CustomValidators.notEmpty(c.value) && secondControlValue && CustomValidators.notEmpty(secondControlValue)) {
        const firstDate = new Date(c.value);
        const secondDate = new Date(secondControlValue);
        firstDate.setHours(0, 0, 0, 0);
        secondDate.setHours(0, 0, 0, 0);
        if (firstDate > secondDate && isStartDateFired) {
          return { invalidStartDate: true };
        }
        if (firstDate < secondDate && !isStartDateFired) {
          return { invalidEndDate: true };
        }
      }
      return null;
    };
  }

  public static futureTimeValidator(day: string, time: string) {

    return (dateGroup: UntypedFormGroup) => {
      const dayControl: AbstractControl = dateGroup.get(day);
      const timeControl: AbstractControl = dateGroup.get(time);

      const localDateSelected = new Date(dayControl.value);
      const localTimeSelected = new Date(timeControl.value);
      const todayDateTimeInUserTimeZone = new Date(getDateTimeInUserTimeZone());

      const localTimeMsSelected = localTimeSelected.getMinutes() + localTimeSelected.getHours() * 60;
      const localTimeMsNow = todayDateTimeInUserTimeZone.getMinutes() + todayDateTimeInUserTimeZone.getHours() * 60;

      // Check if both date and time controls have values
      if (dayControl.value != null && timeControl.value != null) {
        // Compare local date and time with current date and time
        if (localDateSelected.toDateString() === todayDateTimeInUserTimeZone.toDateString() && localTimeMsSelected < localTimeMsNow) {
          return { invalidDateTime: true };
        }
      }
      return null;
    };
  }
}
