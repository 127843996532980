export function waitUntilElementsArrayExistAndAddClass(selectorName: string, className: string): void {
  const waitUntilElementExists = (selector: string, callback: Function) => {
    const els: NodeListOf<Element> = document.querySelectorAll(selector);
    if (els && els.length) {
      return callback(els);
    }
    setTimeout(() => waitUntilElementExists(selector, callback), 300);
  }
  waitUntilElementExists(selectorName, (els: NodeListOf<Element>) => {
    els.forEach(element => {
      element.classList.add(className);
    });
  });
}