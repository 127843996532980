import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LabelValueViewModel } from '@app-autogen/ez.common/contracts/response/label-value-view-model';
import { EzFormControl } from '@ez-packages';
import { Router } from '@angular/router';

import { SendFeedbackEmailRequest } from '@app-autogen/ez.email/contracts/request/send-feedback-email-request';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmailTemplateStateFacade } from "@app-shared/state/email-templates-state/email-template-state.facade";

@UntilDestroy()
@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackFormComponent implements OnInit, OnDestroy {

  @Input() showPopup: Observable<void>;

  displayBasic: boolean;
  feedbackForm: UntypedFormGroup;

  private eventsSubscription: Subscription;

  additionalCommentLabel: string = '';
  checkboxAgree: boolean = true;

  readonly ratingOptions: LabelValueViewModel[] = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 }
  ];

  readonly feedbackTypeOptions: LabelValueViewModel[] = [
    { label: 'General Feedback', value: 1 },
    { label: 'Report a Bug', value: 2 }
  ];

  readonly additionalCommentLabelOptions: LabelValueViewModel[] = [
    { label: 'Let us know about your experience.', value: 1 },
    { label: 'Tell us more about any problems you found.', value: 2 }
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private emailTemplateFacade: EmailTemplateStateFacade
  ) { }

  ngOnInit(): void {
    this.eventsSubscription = this.showPopup.pipe(untilDestroyed(this)).subscribe(() => {
      this.showFeedBackPopup();
    });

    this.feedbackForm = this.fb.group({
      ratingType: new EzFormControl(null, [Validators.required], null, 'ratingType', 'Please rate your overall experience of using new EZUI. (1 = Poor and 5 = Excellent)'),
      feedbackType: new EzFormControl(null, [Validators.required], null, 'feedbackType', 'EZUI Feedback'),
      additionalComment: new EzFormControl(null, null, null, 'additionalComment', ''),
      checkboxAgree: new EzFormControl(this.checkboxAgree, null, null, 'checkboxAgree', ''),
    });
  }

  ngOnDestroy() {
    if (this.eventsSubscription)
      this.eventsSubscription.unsubscribe();
  }

  showFeedBackPopup() {
    this.displayBasic = true;
  }

  hideFeedBackPopup() {
    this.feedbackForm.reset();
    this.displayBasic = false;
    this.additionalCommentLabel = '';
  }

  sendFeedBack() {
    if (this.feedbackForm.valid) {

      const form = this.feedbackForm.value;
      const request: SendFeedbackEmailRequest = {
        canContact: form?.checkboxAgree ?? false,
        feedbackComment: form.additionalComment,
        feedbackRating: form.ratingType,
        feedbackType: this.feedbackTypeOptions.find(i => i.value === form.feedbackType).label,
        pageUrl: this.router.url
      };
      this.emailTemplateFacade.sendFeedbackEmail(request);

      this.hideFeedBackPopup();
    }
  }

  onFeedbackTypeChange(feedbackType: number) {
    this.additionalCommentLabel = this.additionalCommentLabelOptions.find(option => option.value === feedbackType).label;
  }
}
