import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { TitleCapitalizePipe } from '../../pipes';

// PrimeNG Imports
import { MenuItem } from 'primeng/api';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ez-sub-header',
  templateUrl: './ez-sub-header.component.html',
  styleUrls: ['./ez-sub-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EzSubHeaderComponent implements OnInit {
  @ViewChild('wrapperLeft', { static: true }) wrapperLeft: ElementRef;
  @ViewChild('wrapperRight', { static: true }) wrapperRight: ElementRef;
  @Input() showBreadCrumbs: boolean = false;

  hasContent = false;
  viewName: string;
  crumbs: MenuItem[];
  home: MenuItem;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        map(() => this.buildBreadCrumb(this.activatedRoute.root))
      )
      .subscribe(crumbs => {
        this.crumbs = crumbs;
        this.viewName = this.crumbs.slice(-1)[0].label;
      });
  }

  ngOnInit() {
    this.home = { icon: 'pi pi-home' };
  }

  hasContentProjection() {
    let hasProjection = false;
    const leftWrapperProjection: NodeList = (this.wrapperLeft.nativeElement as HTMLElement).childNodes;
    for (let x = 0; x < leftWrapperProjection.length; x++) {
      // console.log(leftWrapperProjection.item(x).nodeType)
      if (leftWrapperProjection.item(x).nodeType !== 8) {
        hasProjection = true;
        break; // nodeType == 8 means is a comment
      }
    }

    const rightWrapperProjection: NodeList = (this.wrapperLeft.nativeElement as HTMLElement).childNodes;
    for (let x = 0; x < rightWrapperProjection.length; x++) {
      // console.log(rightWrapperProjection.item(x).nodeType);
      if (rightWrapperProjection.item(x).nodeType !== 8) {
        hasProjection = true;
        break; // nodeType == 8 means is a comment
      }
    }

    return hasProjection;
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<MenuItem> = []): Array<MenuItem> {
    const clientId = route.snapshot.params.id;

    const path = route.routeConfig ? route.routeConfig.path : '';
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = `${url}${path}/`.replace(':id', '');
    const breadcrumb = {
      label: this.getBreadCrumbLabel(route),
      routerLink: clientId ? [nextUrl, clientId] : [nextUrl]
    };
    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  private getBreadCrumbLabel(route: ActivatedRoute): string {
    // If no routeConfig is available we are on the root path
    let label = 'Home';
    if (route && route.routeConfig) {
      if (route.routeConfig.data && route.routeConfig.data.breadcrumb) {
        label = route.routeConfig.data.breadcrumb;
      } else if (route.routeConfig.path) {
        const titleCapitalizePipe: TitleCapitalizePipe = new TitleCapitalizePipe();
        label = titleCapitalizePipe.transform(route.routeConfig.path);
      }
    }
    return label;
  }
}
