<p-chips #chipbox
  [(ngModel)]="chips"
  [showClear]='showClear'
  (keydown)="onChange($event)"
  (onRemove)="onRemove($event.value)"
  (onFocus)="onFocus($event)"
  styleClass="p-chips">
  <ng-template let-item
    pTemplate="item">
    <div>
      {{item.label}}
    </div>
  </ng-template>
</p-chips>
