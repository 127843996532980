import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '@app-root/state/app.state';
import { AppDetailsState } from './app-details-state';

export interface State extends fromRoot.State {
  appDetails: AppDetailsState;
}

// Selector functions
const getAppDetailsState = createFeatureSelector<AppDetailsState>('appDetails');

export const getCurrentState = createSelector(
  getAppDetailsState,
  state => state
);

export const getHubConnected = createSelector(
  getAppDetailsState,
  state => state.hubConnected
);

export const getHubConnectionActivity = createSelector(
  getAppDetailsState,
  state => state.hubConnectionActivity
);

export const getApplicationInitialized = createSelector(
  getAppDetailsState,
  state => state.applicationInitialized
);

export const getBrowserDetails = createSelector(
  getAppDetailsState,
  state => state.browserDetails
);

export const getBrowserLocationDetails = createSelector(
  getAppDetailsState,
  state => state.browserLocationDetails
);
