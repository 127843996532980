<div [formGroup]='form'>
  <div class="row">
    <div class="col-lg-12">
      <ez-form-control-template [control]="form.get('recurrenceType')">
        <p-dropdown formControlName="recurrenceType"
          name="recurrenceType"
          styleClass="w-100"
          optionLabel="label"
          optionValue="value"
          [attr.data-cy]="cypressName + 'recurrenceType-selector'"
          [showClear]="false"
          [options]="recurrenceOptions"
          [overlayOptions]="{appendTo: 'body', styleClass: 'ez-select-overlay-panel'}"
          detectDropdownPanelShowDirection>
        </p-dropdown>
      </ez-form-control-template>
    </div>
  </div>

  <ng-container *ngIf="isRecurrenceCustom">
    <div class="row">
      <div class="col-lg-4">
        <ez-form-control-input [control]='form.controls["customRepeatEveryQuantity"]'
          [isNumeric]='true'
          formControlName='customRepeatEveryQuantity'>
        </ez-form-control-input>
      </div>
      <div class="col-lg-8">
        <ez-form-control-template [control]="form.get('customRepeatEveryRecurrenceType')">
          <p-dropdown formControlName="customRepeatEveryRecurrenceType"
            name="customRepeatEveryRecurrenceType"
            styleClass="w-100"
            optionLabel="label"
            optionValue="value"
            [attr.data-cy]="cypressName + '-customRepeatEveryRecurrenceType-selector'"
            [showClear]="false"
            [options]="customRepeatEveryRecurrenceOptions"
            [overlayOptions]="{appendTo: 'body', styleClass: 'ez-select-overlay-panel'}"
            detectDropdownPanelShowDirection>
          </p-dropdown>
        </ez-form-control-template>
      </div>
    </div>
    <ng-container *ngIf="form.get('customRepeatEveryRecurrenceType').value == recurrencePatternUnit.Weekly">
      <div class="row">
        <div class="col-lg-12">
          <ez-days-of-week-checkbox formControlName="daysOfWeek"
            [isRequired]='true'
            [selectedDaysOfWeek]='[dayOfWeek]'>
          </ez-days-of-week-checkbox>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="form.get('customRepeatEveryRecurrenceType').value == recurrencePatternUnit.Monthly">
      <div class="row">
        <div class="col-lg-12">
          <ez-form-control-template [control]="form.get('recurrenceCustomMonthlyOption')">
            <div class="radio_group radio-group-inline mt-0">
              <div class='radio-group-item'>
                <p-radioButton formControlName="recurrenceCustomMonthlyOption"
                  [value]="recurrenceCustomMonthlyOptions.DayOfMonth"
                  [attr.data-cy]="cypressName + '_dayOfMonth-radio-btn'"
                  [label]="('Day' | ezLocale | titlecase) + ' ' + (_startDate | ezDate: EzDatePipeFormat.dayOfMonth)">
                </p-radioButton>
              </div>
              <div class='radio-group-item'>
                <p-radioButton formControlName="recurrenceCustomMonthlyOption"
                  [value]="recurrenceCustomMonthlyOptions.EveryNthDayOfWeek"
                  [attr.data-cy]="cypressName + '_everyNthDayOfWeek-radio-btn'"
                  [label]="('Every' | ezLocale) + ' ' + (getWeekNumber()) + ' ' + (_startDate | ezDate: EzDatePipeFormat.wideDayOfWeek)">
                </p-radioButton>
              </div>
            </div>
          </ez-form-control-template>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
