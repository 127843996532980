import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appDynamicMenuComponent]'
})
export class DynamicMenuDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
