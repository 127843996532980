import {
  Component,
  OnInit,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { LabelValueViewModel } from '@ezuiaws/ez.ui.models';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { EzFormControlSplitInputDropdownFormValue } from './ez-form-control-split-input-dropdown-form-value';
import { EzControlValueAccessor, EzFormControl } from '../../ez-form-controls';
import { getCypressFieldName } from '../../helpers';
import { CustomValidators } from '../../validators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ez-form-control-split-input-dropdown',
  templateUrl: './ez-form-control-split-input-dropdown.component.html',
  styleUrls: ['./ez-form-control-split-input-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzFormControlSplitInputDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EzFormControlSplitInputDropdownComponent),
      multi: true
    }
  ]
})

export class EzFormControlSplitInputDropdownComponent extends EzControlValueAccessor<UntypedFormGroup> implements OnInit, AfterViewInit, OnDestroy {

  @Input() control: AbstractControl;

  _options: LabelValueViewModel[] = [];
  @Input() set options(opts: LabelValueViewModel[]) {
    this._options = opts;
  }

  @Input() showLabel: boolean = false;
  @Input() priceLabel: string;
  @Input() priceValidationLabel: string;
  @Input() inputPlaceholder: string;
  @Input() selectedPerOption: number;
  @Input() allowZeroFeeAmount: boolean = true;

  @Output() valueChangedEvent: EventEmitter<EzFormControlSplitInputDropdownFormValue> = new EventEmitter();

  form: UntypedFormGroup;
  cypressName = '';

  isRequired: boolean = true;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.cypressName = getCypressFieldName(this.control);
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  ngAfterViewInit(): void {
    // used forceChange to initialize parent form using EzControlValueAccessor
    this.forceChange();
  }

  formControlChange() {
    this.forceChange();
    this.forceTouched();
  }

  private buildForm() {
    this.form = this.fb.group({
      price: new EzFormControl(null, [Validators.required, CustomValidators.currencyValidator], null, 'price', this.priceLabel),
      perOption: new EzFormControl(this.selectedPerOption, [Validators.required], null)
    });

    if (!this.allowZeroFeeAmount) {
      this.form.controls['price'].setValidators([
        Validators.required,
        Validators.max(922337203685477.58),
        CustomValidators.zeroFeeDoesNotAllowValidator(this.priceValidationLabel)
      ]);
    }

    this.form.valueChanges.pipe(
      debounceTime(100),
      untilDestroyed(this)
    ).subscribe((_: EzFormControlSplitInputDropdownFormValue) => {
      this.formControlChange();
      this.valueChangedEvent.emit(this.form.value);
    });
  }
}
