import { GetNewAdminApprovalIsSetupResponse } from '@app-autogen/ez.common/contracts/response/get-new-admin-approval-is-setup-response';
import { ThemeState } from './theme-state';
import * as ThemeActions from './theme.actions';
import { ActionWithPayload } from '@ezuiaws/ez-packages/ez-socket';
import { createReducer, on } from '@ngrx/store';

const initialState: ThemeState = {
  clientNavigation: 'V1',
  accessToCsiBatchSettlementReport: undefined,
  accessToPyxisDisbursementsReport: undefined,
  accessToVitalTsysBatchSettlementReport: undefined,
  accessToDirectDebitFailuresReport: undefined,
  hasStreamingDirectDebitMerchant: undefined,
  cloverMerchant: undefined,
  approvalAdministrator: undefined
};

const reducer = createReducer<ThemeState>(
  initialState,
  on(ThemeActions.switchClientNavigationOnClick, (state, { payload }) => {
    return {
      ...state,
      clientNavigation: payload
    };
  }),

  on(ThemeActions.getAccessToCsiBatchSettlementReportSuccess, (state, { payload }) => {
    return {
      ...state,
      accessToCsiBatchSettlementReport: payload.allowAccess
    };
  }),

  on(ThemeActions.getAccessToPyxisDisbursementsReportSuccess, (state, { payload }) => {
    return {
      ...state,
      accessToPyxisDisbursementsReport: payload.allowAccess
    };
  }),

  on(ThemeActions.getAccessToVitalTsysBatchSettlementReportSuccess, (state, { payload }) => {
    return {
      ...state,
      accessToVitalTsysBatchSettlementReport: payload.allowAccess
    };
  }),

  on(ThemeActions.getAccessToDirectDebitFailuresReportSuccess, (state, { payload }) => {
    return {
      ...state,
      accessToDirectDebitFailuresReport: payload.allowAccess
    };
  }),

  on(ThemeActions.getDirectDebitMerchantIsStreamingSuccess, (state, { payload }) => {
    return {
      ...state,
      hasStreamingDirectDebitMerchant: payload.hasStreamingDirectDebitMerchant
    };
  }),

  on(ThemeActions.getCloverMerchantIsSetUpSuccess, (state, { payload }) => {
    return {
      ...state,
      cloverMerchant: payload
    };
  }),

  on(ThemeActions.getNewAdminApprovalSetupSuccess, (state, { payload }) => {
    return {
      ...state,
      approvalAdministrator: payload
    };
  })
);

export function themeReducer(state: ThemeState | undefined, action: ActionWithPayload) {
  return reducer(state, action);
}
