import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ez-form-control-input-popup',
  templateUrl: './ez-form-control-input-popup.component.html',
  styleUrls: ['./ez-form-control-input-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzFormControlInputPopupComponent),
      multi: true
    }
  ]
})
export class EzFormControlInputPopupComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  @ViewChild('opPopup') opPopup: OverlayPanel;

  @Input() showedValue: string = '';
  @Input() placeholder = 'Select';
  @Input() maxHeightPopup: number = 200;
  @Input() disabled: boolean = false;
  @Input() closePopupEvents: Observable<void>;

  closePopupEventsSubscription: Subscription;

  propagateChange: any = () => { };
  propagateTouched: any = () => { };

  constructor() { }

  ngOnInit() {
    this.closePopupEventsSubscription = this.closePopupEvents.subscribe(() => this.hidePopup());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showedValue && changes.showedValue.currentValue) {
      this.selectItem(changes.showedValue.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.closePopupEventsSubscription.unsubscribe();
  }

  selectItem(selectedItem) {
    this.writeValue(selectedItem);
    this.propagateChange(selectedItem);
    this.hidePopup();
  }

  writeValue(item): void {
    this.showedValue = item;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  hidePopup() {
    if (this.opPopup) {
      this.opPopup.hide();
    }
  }
}
