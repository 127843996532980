<p-dialog
  header="Give Feedback"
  draggable='false'
  modal='true'
  blockScroll='true'
  [(visible)]="displayBasic"
  [style]="{width: '30vw'}"
  [baseZIndex]="10000"
  (onHide)='hideFeedBackPopup()'>

  <form [formGroup]='feedbackForm'>
    <div class="row">
      <div class="col-lg-12">
        <ez-form-control-template [control]="feedbackForm.get('ratingType')">
          <div class="radio_group radio-group-inline">
            <ng-container *ngFor='let i of ratingOptions'>
              <div class='radio-group-item'>
                <p-radioButton name="ratingType"
                  formControlName="ratingType"
                  [value]='i.value'
                  [label]='i.label'>
                </p-radioButton>
              </div>
            </ng-container>
          </div>
        </ez-form-control-template>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <ez-form-control-template [control]="feedbackForm.get('feedbackType')">
          <p-dropdown name="feedbackType"
            formControlName="feedbackType"
            styleClass="w-100"
            [placeholder]='"Select an Option"'
            [options]="feedbackTypeOptions"
            (onChange)='onFeedbackTypeChange($event.value)'
            [overlayOptions]="{appendTo: 'body', styleClass: 'ez-select-overlay-panel'}"
            detectDropdownPanelShowDirection>
          </p-dropdown>
        </ez-form-control-template>
      </div>
    </div>

    <div *ngIf='additionalCommentLabel' class="row">
      <div class="col-lg-12">
        <label class="control-label">{{ additionalCommentLabel }}</label>
        <ez-form-control-input formControlName="additionalComment"
          [showLabel]='false'
          [control]="feedbackForm.get('additionalComment')"
          [isTextArea]="true"
          [rows]="5">
        </ez-form-control-input>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-lg-12">
        <ez-form-control-template [control]='feedbackForm.get("checkboxAgree")'
          [showLabel]='false'>
          <ez-checkbox binary="true"
            formControlName="checkboxAgree"
            (valueChanged)='checkboxAgree = $event'
            [attr.data-cy]="'checkbox-reservation-delete-notify-client'"
            [label]="'EZFacility can contact me about my feedback.'">
          </ez-checkbox>
        </ez-form-control-template>
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <div class="align-items-right">
      <ez-secondary-button (click)="hideFeedBackPopup()"></ez-secondary-button>
      <ez-primary-button [label]="'Submit' | ezLocale"
        [disabled]='!feedbackForm.valid'
        (click)="sendFeedBack()">
      </ez-primary-button>
    </div>
  </ng-template>
</p-dialog>
