<!-- Wrapper-->
<div id="wrapper">


  <!-- Main page wrapper -->
  <div class="gray-bg">


    <!-- Main view/routes wrapper-->
    <router-outlet></router-outlet>


    <!-- Footer -->
    <!-- <app-footer></app-footer> -->

  </div>
  <!-- End page wrapper-->

</div>
<!-- End wrapper-->
