import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService {

  constructor(
    public authService: AuthService,
    public router: Router
  ) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return of(route.queryParams?.jwt).pipe(
      mergeMap(jwt => {
        if (jwt) {
          this.authService.clearLocalStorage();
          const claims = this.authService.decodeJwt(jwt);

          if (claims && claims.UserId && claims.LocationId) {
            this.authService.loginSingleSignOn(claims.UserId, claims.LocationId, jwt);

            //set a timeout to reload the page in case it gets stuck from the redirect and is still on the login page
            setTimeout(() => {
              if (this.router.url.indexOf('login') > 0) {
                window.location.reload();
              }
            }, 10000);
          }
          return of(false);
        }

        return of(this.authService.getIsUserAuthorized());
      }),

      map((isUserAuth) => {
        if (isUserAuth) {

          this.router.navigate(['/']);
          return false;
        }
        this.authService.setApplicationIsInitialized(true);
        return true;
      })
    );
  }
}
