import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SessionFacade } from '@src/app/state/session-state/session.facade';
import { UserSettingsService } from '@app-core/auth/user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private authService: AuthService,
    private sessionFacade: SessionFacade,
    private userSettingsService: UserSettingsService
  ) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    if (!this.authService.getIsUserAuthorized()) {
      this.authService.logout();
      return false;
    }

    if (this.userSettingsService.userSession.userId === 0) {
      this.authService.loadUserSessionData();
    }

    /*
     this.authService.getApplicationIsInitialized() - is a main flag that tells that all application data and settings loaded saved and updated.
     Such as this.userSession$ data, JWT etc.
    */
    return this.authService.getApplicationIsInitialized().pipe(filter(isInitialized => isInitialized));
  }
}
