import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { SocketHelperService } from './socket-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SocketProvider {

  constructor(private socketHelperService: SocketHelperService) { }

  initHubConnection() {
    this.socketHelperService.initSocket();

    return new Promise((resolve) => {
      this.socketHelperService.hubConnected$.pipe(
        filter(connected => connected),
        take(1)
      ).subscribe((connected: boolean) => {
        return resolve(connected);
      });
    });
  }
}
