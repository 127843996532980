import { AsyncValidatorFn } from '@angular/forms';

export class EzAsyncValidator {
  showErrorAsNotification: boolean = false;
  asyncValidatorFn: AsyncValidatorFn;
  errorType: string;
  errorMessage: string;

  constructor(
    showErrorAsNotification: boolean,
    asyncValidatorFn: AsyncValidatorFn,
    errorType: string,
    errorMessage: string) {
    this.showErrorAsNotification = showErrorAsNotification;
    this.asyncValidatorFn = asyncValidatorFn;
    this.errorType = errorType;
    this.errorMessage = errorMessage;
  }
}
