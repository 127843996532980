import { EzStoreService } from '@ezuiaws/ez-packages/ez-store';
import { Component, OnInit } from '@angular/core';
import * as appRouter from '@app-root/state/router-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-access-denied-iframe',
  templateUrl: './access-denied-iframe.component.html',
  styleUrls: ['./access-denied-iframe.component.scss']
})
export class AccessDeniedIframeComponent implements OnInit {
  route = '';
  constructor(private storeHelper: EzStoreService<appRouter.State>) { }

  ngOnInit() {
    this.storeHelper.stateStreamSelector(appRouter.getRouterFeatureState).pipe(
      untilDestroyed(this)
    ).subscribe(route => {
      if (route.state.queryParams?.permissionCode) {
        const permissionCode = route.state.queryParams?.permissionCode;
        this.route = 'access_denied.aspx?PermissionCode=' + permissionCode;
      } else {
        this.route = 'access_denied.aspx';
      }
    });
  }
}
