import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionResponse } from '@app-autogen/ez.authentication/contracts/response/session-response';
import { LocationSettingsService } from '@app-core/auth/location-settings.service';
import { UserSettingsService } from '@app-core/auth/user-settings.service';
import { ConfirmationModalSettings, EZLayoutService } from '@ezuiaws/ez-packages/ez-layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@src/environments/environment.defaults';

@UntilDestroy()
@Component({
  selector: 'app-important-message',
  templateUrl: './important-message.component.html',
  styleUrls: ['./important-message.component.scss']
})
export class ImportantMessageComponent implements OnInit {

  showImportantMessage: boolean = false;

  importantMessageConfirmationSettings: ConfirmationModalSettings = {
    confirmationId: 'important-message-confirmation',
    title: '',
    message: '',
    showCancel: true
  };

  constructor(
    private router: Router,
    private layoutService: EZLayoutService,
    private userSettingsService: UserSettingsService,
    private locationSettingsService: LocationSettingsService
  ) { }

  ngOnInit(): void {

    this.userSettingsService.userSession$.pipe(
      untilDestroyed(this)
    ).subscribe((userSession: SessionResponse) => {
      if (userSession 
          && !this.router.url.includes('/admin/approval-new-administrator')
          && this.userSettingsService.getUserPreferenceBooleanValue('ShowSurveyModal')
          && this.router.url.includes('/schedule')) {
        // sometimes an important message may be dismissible which will use a user preference 
        // to save that is has been dismissed - This will check it
        // && this.userSettingsService.getUserPreferenceBooleanValue('ShowAWSMaintenanceAlert')
        this.layoutService.confirmationConfirmed();
        this.showImportantMessageDialog();
      }
    });
  }

  confirmationClosed(): void {
    this.showImportantMessage = false;
  }

  closeModalDialog(): void {
    // sometimes an important message may be dismissible which will use a user preference 
    // to save that is has been dismissed - This update that is has been dismissed and not show again
    // if (this.showImportantMessage) {
    //   this.userSettingsService.updateUserPreferenceBooleanValue('ShowAWSMaintenanceAlert', false);
    // }

    this.userSettingsService.updateUserPreferenceBooleanValue('ShowSurveyModal', false);
    this.layoutService.confirmationConfirmed();
    this.showImportantMessage = false;
  }

  onTakeClick(): void {
    this.userSettingsService.updateUserPreferenceBooleanValue('ShowSurveyModal', false);
    this.layoutService.confirmationConfirmed();
    window.open(environment.survey_link, '_blank');
  }

  showImportantMessageDialog(): void {
    this.showImportantMessage = true;
    this.layoutService.showConfirmationWithSettings(this.importantMessageConfirmationSettings);
  }
}
