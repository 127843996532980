import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@app-core/auth/auth.service';
import { tap } from 'rxjs/operators';
import { NotificationType } from '@app-autogen/ez.common/enums/notification-type';
import { LocalizationService } from '@app-core/localization/localization.service';
import { LoginResultStatuses } from '@app-autogen/ez.authentication/contracts/response/login-result-statuses';
import { EzNotificationService } from '@ezuiaws/ez-packages/ez-notification';
import * as fromSessionActions from './session.actions';
import { SharedStateFacade } from '../shared-state/shared-state.facade';


@Injectable()
export class SessionEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private notificationService: EzNotificationService,
    private sharedStateFacade: SharedStateFacade
  ) { }

  onGetUserSessionSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSessionActions.getUserSessionSuccess),
      tap(({ payload }) => {
        this.authService.configureAppSettingsWithUserSessionData(payload);
        this.sharedStateFacade.fetchEZLeaguesUser(payload.userId);
      })
    ), { dispatch: false }
  );

  onUserLoginSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSessionActions.userLoginSuccess, fromSessionActions.userSingleSignOnSuccess),
      tap(({ payload }) => {
        this.authService.setJwt(payload);
        void this.authService.signOnSignUpSuccess();
      })
    ), { dispatch: false }
  );

  onUserLoginFail = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSessionActions.userLoginFail),
      tap(() => this.authService.setIsLogging(false)),
      tap(({ payload }) => {

        if (payload == LoginResultStatuses.IpAddressNotAllowed) {
          this.notificationService.sendFailNotification({
            type: NotificationType.error,
            title: LocalizationService.rks.Failed,
            message: LocalizationService.rks.LoginNotPermitted,
          });
        } else {

          this.notificationService.sendFailNotification({
            type: NotificationType.error,
            title: LocalizationService.rks.Failed,
            message: LocalizationService.rks.UserLoginFail,
          });
        }
      }),
    ), { dispatch: false }
  );

  onUserLogout = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSessionActions.userLogout),
      tap(() => this.authService.logout())
    ), { dispatch: false }
  );

  onUserSessionOut = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSessionActions.userSessionOut),
      tap(() => this.authService.logout()),
      tap(() => {
        this.notificationService.sendFailNotification({
          type: NotificationType.warning,
          title: LocalizationService.rks.Failed,
          message: LocalizationService.rks.UserSessionOut,
        });
      }),
    ), { dispatch: false }
  );
}

