import { Component, OnInit, ViewChild, Input, Output, EventEmitter, forwardRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { getCypressFieldName } from '../../helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LabelValueWithInfo } from './label-value-with-info-hover.model';

import { ResourcesProvider } from '@ezuiaws/ez-packages/ez-localization';

@UntilDestroy()
@Component({
  selector: 'ez-form-control-dropdown',
  templateUrl: './ez-form-control-dropdown.component.html',
  styleUrls: ['./ez-form-control-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzFormControlDropdownComponent),
      multi: true
    }
  ]
})
export class EzFormControlDropdownComponent implements ControlValueAccessor, OnInit, AfterViewInit {

  @ViewChild('dropdown', { static: false }) dropdown: Dropdown;
  @Input() control: AbstractControl;
  @Input() isDisabled: boolean = false;
  @Input() keyField: string;
  @Input() simplePlaceHolder: string = ResourcesProvider.rks.DropdownSelectPlaceholder();
  @Input() showClear = true;

  _showDropDown = true;
  @Input() set showDropDown(show: boolean) {
    this._showDropDown = show;
    this.showDropDownChange.emit(show);
  }
  @Output() showDropDownChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();

  _dropDownPlaceholderText = '';
  @Input() set dropDownPlaceholderText(text: string) {
    this._dropDownPlaceholderText = text;
  }

  _dropDownLinkText = '';
  @Input() set dropDownLinkText(text: string) {
    this._dropDownLinkText = text;
  }

  _options: LabelValueWithInfo[] = [];
  @Input() set options(opts: LabelValueWithInfo[]) {
    this._options = opts;
  }

  _inputPlaceholderText = '';
  @Input() set inputPlaceholderText(text: string) {
    this._inputPlaceholderText = text;
  }

  _inputLinkText = '';
  @Input() set inputLinkText(text: string) {
    this._inputLinkText = text;
  }

  selectedValue;

  setOverlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _placeholderVisible = true;
  cypressName: string = '';

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cypressName = getCypressFieldName(this.control);
  }

  ngAfterViewInit(): void {
    this.setOverlay$.pipe(untilDestroyed(this)).subscribe(value => {
      if (value === null && this.dropdown.overlayVisible) {
        this.dropdown.overlayVisible = false;
      }
    });
  }

  writeValue(value: any): void {
    this.setValue(value);
    this.showAdvancedPlaceholder(!this.selectedValue);
    this.setOverlay$.next(!!this.selectedValue);

    this.onChange(this.selectedValue);
    this.onTouched();
    this.cdr.markForCheck();
  }

  dropdownChange(value) {
    this.writeValue(value);
    this.optionSelected.emit(value);
  }

  onHide() {
    this.showAdvancedPlaceholder(!this.selectedValue);
  }

  linkClicked() {
    const show = !this._showDropDown;
    this._showDropDown = show;
    this.showDropDownChange.emit(show);
  }

  setFocus() {
    this.showAdvancedPlaceholder(false);
    this.dropdown.focus();
    this.dropdown.overlayVisible = true;
  }

  showAdvancedPlaceholder(value: boolean) {
    this._placeholderVisible = value;
  }

  // user did not click on placeholder but in the dropdown
  dropdownFocus() {
    this.showAdvancedPlaceholder(false);
  }

  setValue(value: any) {
    // scenerio 1: _options = itemvalueVM is primary,primary ---- value is primary  => selectvalue = value
    // scenerio 2: _options= itemvalueVM is primary,object ---- value is primary  => selectvalue = found by keyfield though options
    // scenerio 3: _options= itemvalueVM is primary,object ---- value is object  => selectvalue = value
    // scenerio 4: _options= itemvalueVM is primary,object ---- value is partial object  => selectvalue = found by keyfield though options

    if (this._options.length > 0) {
      if (this.keyField && value && typeof value === 'object') {
        this.selectedValue = this._options.find(lv => lv.value[this.keyField] === value[this.keyField])?.value;
      } else if (this.keyField && value) {
        this.selectedValue = this._options.find(lv => lv.value === value)?.value;
      } else {
        this.selectedValue = value;
      }
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
