import { ValidatorFn, AbstractControlOptions, AsyncValidatorFn, FormControl, FormControlOptions, FormControlState } from '@angular/forms';
import { EzAsyncValidator } from '../validators/ez-async-validator';

export class EzFormControl<T = any> extends FormControl<T> {
  public controlLabel: string;
  public fieldName: string;
  public visible: boolean;
  public validatorList: ValidatorFn | AbstractControlOptions | ValidatorFn[];
  public asyncValidators: AsyncValidatorFn | AsyncValidatorFn[] | EzAsyncValidator | EzAsyncValidator[];
  initialFormState: any;

  constructor(
    formState: FormControlState<T> | T = null,
    validators?: ValidatorFn | ValidatorFn[],
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | EzAsyncValidator | EzAsyncValidator[],
    fieldName: string = '',
    controlLabel: string = '',
    visible: boolean = true,
    nonNullable: boolean = true) {

    let asyncValidators: AsyncValidatorFn | AsyncValidatorFn[];
    let usingEzAsyncValidator: boolean = false;
    //"bug" in javascript where passing the asyncValidator parameter as EzAsyncValidator
    // will not return true when checking (asyncValidator instanceof EzAsyncValidator)
    if ((asyncValidator as EzAsyncValidator)?.asyncValidatorFn) {
      usingEzAsyncValidator = true;
      asyncValidators = (asyncValidator as EzAsyncValidator).asyncValidatorFn;
    } else if (asyncValidator instanceof Array) {
      let asyncValidatorFns: AsyncValidatorFn[] = [];
      asyncValidator.forEach(element => {
        if (element instanceof EzAsyncValidator) {
          asyncValidatorFns.push(element.asyncValidatorFn);
        } else {
          asyncValidatorFns.push(element);
        }
      });
      asyncValidators = asyncValidatorFns;
    } else if (!(asyncValidator instanceof EzAsyncValidator)) {
      asyncValidators = asyncValidator;
    }

    const formControlOptions: FormControlOptions = {
      validators: validators,
      nonNullable: nonNullable
    };

    super(formState, formControlOptions, asyncValidators);

    this.controlLabel = controlLabel;
    this.fieldName = fieldName;
    this.visible = visible;
    this.validatorList = validators as ValidatorFn | ValidatorFn[];
    if (!usingEzAsyncValidator) {
      this.asyncValidators = asyncValidator;
    } else {
      if (asyncValidator) {
        //using the EzAsyncValidator constructor is required, so checking instanceof EzAsyncValidator will work later.
        let ezAsyncValidator = (asyncValidator as EzAsyncValidator)
        this.asyncValidators = new EzAsyncValidator(
          ezAsyncValidator.showErrorAsNotification,
          ezAsyncValidator.asyncValidatorFn,
          ezAsyncValidator.errorType,
          ezAsyncValidator.errorMessage);
      }
    }
    this.initialFormState = formState;
  }

  setInitialFormState(formState: any) {
    this.initialFormState = formState;
    this.reset();
  }

  setValidators(newValidator: ValidatorFn | ValidatorFn[] | null): void {
    super.setValidators(newValidator);
    this.validatorList = newValidator;
  }

  reset() {
    super.reset(this.initialFormState);
    super.markAsUntouched();
    super.markAsPristine();
  }
}
