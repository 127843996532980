import { Pipe, PipeTransform } from '@angular/core';
import { SessionLoggingLevel } from '../../autogen/ez.core/models/enums/session-logging-level';

@Pipe({
  name: 'sessionLoginLevel'
})
export class SessionLoginLevelPipe implements PipeTransform {

  transform(value: SessionLoggingLevel): string {
    return SessionLoggingLevel[value];
  }

}
