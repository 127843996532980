import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uncamelcase'
})
export class UncamelcasePipe implements PipeTransform {

  transform(text: string) {
    let output = text.substring(0, 1).toUpperCase() + text.substring(1, text.length);
    output = output.replace(/([A-Z])/g, ' $1').trim();
    return output;
  }

}
