import { ThemeActionTypes } from '@app-autogen/ez.common/contracts/action-types/theme-action-types';
import { GetAccessToBatchSettlementReportResponse } from '@app-autogen/ez.common/contracts/response/get-access-to-batch-settlement-report-response';
import { GetAccessToDirectDebitFailuresReportResponse } from '@app-autogen/ez.common/contracts/response/get-access-to-direct-debit-failures-report-response';
import { GetDirectDebitMerchantIsStreamingResponse } from '@app-autogen/ez.common/contracts/response/get-direct-debit-merchant-is-streaming-response';
import { createActionHelper } from '@ezuiaws/ez-packages/ez-store';
import { GetCloverMerchantIsSetUpResponse } from '@app-autogen/ez.common/contracts/response/get-clover-merchant-is-set-up-response';
import { GetNewAdminApprovalIsSetupResponse } from '@app-autogen/ez.common/contracts/response/get-new-admin-approval-is-setup-response';

export const switchClientNavigationOnClick = createActionHelper<string>(ThemeActionTypes.SwitchClientNavigationOnClick);

export const getAccessToCsiBatchSettlementReport = createActionHelper<null>(ThemeActionTypes.GetAccessToCsiBatchSettlementReport);
export const getAccessToCsiBatchSettlementReportSuccess = createActionHelper<GetAccessToBatchSettlementReportResponse>(ThemeActionTypes.GetAccessToCsiBatchSettlementReportSuccess);
export const getAccessToCsiBatchSettlementReportFail = createActionHelper<null>(ThemeActionTypes.GetAccessToCsiBatchSettlementReportFail);

export const getAccessToPyxisDisbursementsReport = createActionHelper<null>(ThemeActionTypes.GetAccessToPyxisDisbursementsReport);
export const getAccessToPyxisDisbursementsReportSuccess = createActionHelper<GetAccessToBatchSettlementReportResponse>(ThemeActionTypes.GetAccessToPyxisDisbursementsReportSuccess);
export const getAccessToPyxisDisbursementsReportFail = createActionHelper<null>(ThemeActionTypes.GetAccessToPyxisDisbursementsReportFail);

export const getAccessToVitalTsysBatchSettlementReport = createActionHelper<null>(ThemeActionTypes.GetAccessToVitalTsysBatchSettlementReport);
export const getAccessToVitalTsysBatchSettlementReportSuccess = createActionHelper<GetAccessToBatchSettlementReportResponse>(ThemeActionTypes.GetAccessToVitalTsysBatchSettlementReportSuccess);
export const getAccessToVitalTsysBatchSettlementReportFail = createActionHelper<null>(ThemeActionTypes.GetAccessToVitalTsysBatchSettlementReportFail);

export const getAccessToDirectDebitFailuresReport = createActionHelper<null>(ThemeActionTypes.GetAccessToDirectDebitFailuresReport);
export const getAccessToDirectDebitFailuresReportSuccess = createActionHelper<GetAccessToDirectDebitFailuresReportResponse>(ThemeActionTypes.GetAccessToDirectDebitFailuresReportSuccess);
export const getAccessToDirectDebitFailuresReportFail = createActionHelper<null>(ThemeActionTypes.GetAccessToDirectDebitFailuresReportFail);

export const getDirectDebitMerchantIsStreaming = createActionHelper<null>(ThemeActionTypes.GetDirectDebitMerchantIsStreaming);
export const getDirectDebitMerchantIsStreamingSuccess = createActionHelper<GetDirectDebitMerchantIsStreamingResponse>(ThemeActionTypes.GetDirectDebitMerchantIsStreamingSuccess);
export const getDirectDebitMerchantIsStreamingFail = createActionHelper<null>(ThemeActionTypes.GetDirectDebitMerchantIsStreamingFail);

export const getCloverMerchantIsSetUp = createActionHelper<null>(ThemeActionTypes.GetCloverMerchantIsSetUp);
export const getCloverMerchantIsSetUpSuccess = createActionHelper<GetCloverMerchantIsSetUpResponse>(ThemeActionTypes.GetCloverMerchantIsSetUpSuccess);
export const getCloverMerchantIsSetUpFail = createActionHelper<null>(ThemeActionTypes.GetCloverMerchantIsSetUpFail);

export const getNewAdminApprovalIsSetup = createActionHelper<number>(ThemeActionTypes.GetNewAdminApprovalIsSetup);
export const getNewAdminApprovalSetupSuccess = createActionHelper<GetNewAdminApprovalIsSetupResponse>(ThemeActionTypes.GetNewAdminApprovalIsSetupSuccess);
export const getNewAdminApprovalSetupFail = createActionHelper<null>(ThemeActionTypes.GetNewAdminApprovalIsSetupFail);