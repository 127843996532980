import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NotificationType } from '../../../autogen/ez.common/enums/notification-type';
import { NotificationResponse } from '../../../autogen/ez.common/contracts/response/notification-response';
import { toCypressName } from '../../helpers/to-cypress-name';


@Component({
  selector: 'ez-edit-panel',
  templateUrl: './ez-edit-panel.component.html',
  styleUrls: ['./ez-edit-panel.component.scss']
})
export class EzEditPanelComponent implements OnInit {

  @Input() title: string = '';

  @Input() panelNotification: NotificationResponse = {
    message: '',
    title: '',
    type: NotificationType.info
  };

  @Input() showEdit = true;
  @Input() editDisabled = false;
  @Input() showEditFormOnEditClick = true;
  @Input() isEditMode: boolean = false;
  @Output() editChange = new EventEmitter<boolean>();
  @Output() editLinkClick = new EventEmitter();

  NotificationType = NotificationType;

  constructor() { }

  ngOnInit(): void {
  }

  showEditForm() {
    this.isEditMode = true;
    this.editChange.emit(true);
  }

  onEditLinkClick() {
    this.editLinkClick.emit();
  }

  cypressName(value: string) {
    return '-' + toCypressName(value);
  }

}
