import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EzFormControl } from '../../ez-form-controls';
import { getCypressFieldName } from '../../helpers';

@Component({
  selector: 'ez-back-link',
  templateUrl: './ez-back-link.component.html',
  styleUrls: ['./ez-back-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EzBackLinkComponent implements OnInit {

  constructor() { }

  @Input() control: EzFormControl;
  @Input() linkLabel: string;
  @Output() clickedEvent: EventEmitter<void> = new EventEmitter<void>();

  cypressName: string = '';

  ngOnInit(): void {
    this.cypressName = this.control ? 'link-back-' + getCypressFieldName(this.control) : 'link-back';
  }

  onClick() {
    this.clickedEvent.emit();
  }

}
